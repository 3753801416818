import { has, pick } from "lodash";
import { getIsMaybeRelativeSize } from "../../../utils";

export const FONT_SIZES = {
  // deprecated scale
  1: "0.75rem",
  2: "0.875rem",
  3: "1rem",
  4: "1.25rem",
  5: "1.5rem",
  6: "2.25rem",
  7: "3rem",
  8: "4rem",
  9: "5rem",
  10: "6rem",

  // new scale (maybe turn these into rem??)
  xs: "0.75rem",
  sm: "0.875rem",
  md: "1rem",
  lg: "1.125rem",
  xl: "1.25rem",
};

const isScaleValue = (value) => has(FONT_SIZES, value);

function transformFontSizeValue(value) {
  let v = Math.abs(value);
  if (isNaN(v)) {
    v = String(value);
    if (v.endsWith(":em")) {
      const [size] = v.split(":");
      // we assume the value is always a scale value, e.g. "sm:em" =>
      //    you are referring to the xs font but want it relative to parent (em) instead of root (rem)
      v = FONT_SIZES[size];
      // for now we assume every value in the scale is a rem unit and we want replace it with em
      // e.g. change unit
      return v.replace(/rem/, "em");
    } else {
      return getIsMaybeRelativeSize(v);
    }
  } else {
    return `${v}px`;
  }
}

export function mapPropsToFontSize(props) {
  const properties = pick(props, ["fs", "font-size"]);

  return Object.values(properties).reduce(
    (styles, value) => ({
      ...styles,
      "font-size": getFontSize(value),
    }),
    {},
  );
}
export function getFontSize(fs) {
  if (isScaleValue(fs)) {
    return FONT_SIZES[fs];
  } else {
    return transformFontSizeValue(fs);
  }
}
