import { has, pick } from "lodash";

export const MAX_HEIGHTS = {
  full: "100%",
  screen: "100vh",
};

// TODO: this should be extracted to a common function among the sizing module utils (they should support the same?)
function transformSizeValue(value) {
  // TEMP
  return value;
}

export function mapPropsToMaxHeight(props) {
  const properties = pick(props, ["max-height", "max-h"]);

  return Object.values(properties).reduce((styles, value) => {
    return {
      ...styles,
      ["max-height"]: getMaxHeight(value),
    };
  }, {});
}

const isScaleValue = (value) => has(MAX_HEIGHTS, value);

export function getMaxHeight(height) {
  if (isScaleValue(height)) {
    return MAX_HEIGHTS[height];
  } else {
    return transformSizeValue(height);
  }
}
