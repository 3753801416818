import React from "react";
import { Box } from "../Box";
import { warning } from "@gfw/core";

const Stack = React.forwardRef(function Stack(
  { children, spacing, ...props },
  forwardedRef,
) {
  warning(
    "layout" in props,
    `[@gfw:orion] Stack no longer has support for layout="${props.layout}". Consider using the Inline or FlexBox instead.`,
  );

  // TODO: Grid currently isn't a Box so that why we use Box directly but really, it doesn't matter
  return (
    <Box ref={forwardedRef} display="grid" row-gap={spacing} {...props}>
      {children}
    </Box>
  );
});

Stack.defaultProps = {
  spacing: "sm",
};

export { Stack };
