import React from "react";
import ReactJson from "react-json-view";

function ProfileStats({ profile }) {
  return (
    <ReactJson
      collapsed={1}
      displayDataTypes={false}
      name="Stats"
      src={profile.stats}
    />
  );
}

export default ProfileStats;
