import { include, mixin } from "../../style";
import React from "react";
import styled, { css } from "styled-components";
import warning from "tiny-warning";

// remix-fill
import { FileExcel2 as ExcelIcon } from "@styled-icons/remix-fill/FileExcel2";

// remix-line
import { Questionnaire as RequestsIcon } from "@styled-icons/remix-line/Questionnaire";

// fa-solid
import { Check as ConfirmIcon } from "@styled-icons/fa-solid/Check";
import { Sitemap as CounterPartiesIcon } from "@styled-icons/fa-solid/Sitemap";
import { FileArrowDown as DownloadFileIcon } from "@styled-icons/fa-solid/FileArrowDown";
import { LayerGroup as ProfilesIcon } from "@styled-icons/fa-solid/LayerGroup";

// icomoon
import { User as UserIcon } from "@styled-icons/icomoon/User";
import { Users as UsersIcon } from "@styled-icons/icomoon/Users";
import { Warning as WarningIcon } from "@styled-icons/icomoon/Warning";
import { FileZip as ZipIcon } from "@styled-icons/icomoon/FileZip";

// octicons
import { Checklist as RequirementsIcon } from "@styled-icons/octicons/Checklist";

// boxicons-regular
import { Bookmark as BannerIcon } from "@styled-icons/boxicons-regular/Bookmark";
import { CalendarCheck as CalendarCheckIcon } from "@styled-icons/boxicons-regular/CalendarCheck";
import { Calendar as CalendarIcon } from "@styled-icons/boxicons-regular/Calendar";
import { ListCheck as CampaignsIcon } from "@styled-icons/boxicons-regular/ListCheck";
import { Cog as CogIcon } from "@styled-icons/boxicons-regular/Cog";
import { CommentDetail as CommentIcon } from "@styled-icons/boxicons-regular/CommentDetail";
import { Envelope as EmailIcon } from "@styled-icons/boxicons-regular/Envelope";
import { NoEntry as FallbackIcon } from "@styled-icons/boxicons-regular/NoEntry";
import { ArrowToBottom as IncomingIcon } from "@styled-icons/boxicons-regular/ArrowToBottom";
import { InfoCircle as InfoIcon } from "@styled-icons/boxicons-regular/InfoCircle";
import { MailSend as MailSendIcon } from "@styled-icons/boxicons-regular/MailSend";
import { ArrowFromBottom as OutgoingIcon } from "@styled-icons/boxicons-regular/ArrowFromBottom";
import { Lock as PasswordIcon } from "@styled-icons/boxicons-regular/Lock";
import { Layer as ProfileIcon } from "@styled-icons/boxicons-regular/Layer";
import { File as ReportIcon } from "@styled-icons/boxicons-regular/File";
import { Time as TimeIcon } from "@styled-icons/boxicons-regular/Time";

// boxicons-solid
import { CommentAdd as CommentAddIcon } from "@styled-icons/boxicons-solid/CommentAdd";
import { Error as ErrorIcon } from "@styled-icons/boxicons-solid/Error";
import { News as NewsIcon } from "@styled-icons/boxicons-solid/News";
import { RightArrow as RightArrowIcon } from "@styled-icons/boxicons-solid/RightArrow";

// material
import { KeyboardArrowDown as CaretDown } from "@styled-icons/material/KeyboardArrowDown";
import { KeyboardArrowLeft as CaretLeft } from "@styled-icons/material/KeyboardArrowLeft";
import { KeyboardArrowRight as CaretRight } from "@styled-icons/material/KeyboardArrowRight";
import { KeyboardArrowUp as CaretUp } from "@styled-icons/material/KeyboardArrowUp";
import { Close as CloseIcon } from "@styled-icons/material/Close";
import { Dashboard as DashboardIcon } from "@styled-icons/material/Dashboard";
import { DateRange as DateIcon } from "@styled-icons/material/DateRange";
import { NewReleases as NewReleasesIcon } from "@styled-icons/material/NewReleases";
import { QueryStats as QueryStatsIcon } from "@styled-icons/material/QueryStats";
import { ManageSearch as ReportsIcon } from "@styled-icons/material/ManageSearch";
import { Save as SaveIcon } from "@styled-icons/material/Save";
import { Search as SearchIcon } from "@styled-icons/material/Search";
import { UnfoldMore as SortIcon } from "@styled-icons/material/UnfoldMore";
import { Timeline as TimelineIcon } from "@styled-icons/material/Timeline";
import { VisibilityOff } from "@styled-icons/material/VisibilityOff";

// material sharp
import { Business as BusinessIcon } from "@styled-icons/material-sharp/Business";

import { Delete as DeleteIcon } from "@styled-icons/material-outlined/Delete";
import { Home as HomeIcon } from "@styled-icons/material-outlined/Home";

//entypo
import { Documents as DueDiligenceIcon } from "@styled-icons/entypo/Documents";
import { Leaf as LeafIcon } from "@styled-icons/entypo/Leaf";
import { LogOut as LogOutIcon } from "@styled-icons/entypo/LogOut";
import { TextDocument as QuestionnaireIcon } from "@styled-icons/entypo/TextDocument";

// typicons
import { Document as DocumentIcon } from "@styled-icons/typicons/Document";
import { Messages as MessageIcon } from "@styled-icons/typicons/Messages";

// these are some of the common icons we supply/enforce
export const icons = {
  "caret-down": CaretDown,
  "caret-left": CaretLeft,
  "caret-right": CaretRight,
  "caret-up": CaretUp,
  "file-download": DownloadFileIcon,
  banner: BannerIcon, //navbar
  business: BusinessIcon, //navbar
  calendar: CalendarIcon, //DateInput
  calendarCheck: CalendarCheckIcon, //dateinput
  campaigns: CampaignsIcon, //navbar
  close: CloseIcon,
  message: MessageIcon,
  cog: CogIcon, //navbar
  comment: CommentIcon, //navbar
  commentAdd: CommentAddIcon, //navbar
  confirm: ConfirmIcon, //Progress
  counterparties: CounterPartiesIcon, //navbar
  dashboard: DashboardIcon, //navbar
  date: DateIcon,
  dd: DueDiligenceIcon, //navbar
  delete: DeleteIcon,
  document: DocumentIcon,
  email: EmailIcon,
  error: ErrorIcon,
  esg: LeafIcon,
  excel: ExcelIcon,
  home: HomeIcon, //navbar
  incoming: IncomingIcon,
  info: InfoIcon, //navbar
  logout: LogOutIcon, //navbar
  mailSend: MailSendIcon,
  new: NewReleasesIcon,
  news: NewsIcon, //navbar
  outgoing: OutgoingIcon,
  password: PasswordIcon,
  profile: ProfileIcon,
  profiles: ProfilesIcon, //navbar
  queryStats: QueryStatsIcon, //navbar
  questionnaire: QuestionnaireIcon,
  report: ReportIcon,
  reports: ReportsIcon, //navbar
  requests: RequestsIcon, //navbar
  requirement: RequirementsIcon,
  save: SaveIcon,
  search: SearchIcon,
  solidRightArrow: RightArrowIcon,
  sort: SortIcon,
  time: TimeIcon,
  timeline: TimelineIcon, //navbar
  user: UserIcon, //Avatar
  users: UsersIcon, //navbar
  visibilityOff: VisibilityOff,
  warning: WarningIcon,
  zip: ZipIcon,
};

function mapHeightToEm(value) {
  return css`
    height: ${value}em;
  `;
}

// TEMP before we have Icon being a Box which has this OOB
function mapToOpacity(value) {
  return css`
    opacity: ${value};
  `;
}

const Icon = styled(function Icon({ type: icon, children, ...props }) {
  let IconComponent = icons[icon || children];

  warning(
    !icon,
    `[DEPRECATION]: use <Icon>${icon}</Icon> instead of <Icon type="${icon}" />`,
  );

  if (!IconComponent) {
    warning(
      false,
      `You are trying to use '${
        icon || children
      }' as an icon but there's no icon that corresponds to that. Check Icon.js in @gfw/orion.`,
    );
    IconComponent = FallbackIcon;
  }

  return <IconComponent {...props} />;
})`
  width: 1em;
  height: 1em;
  font-size: 1em;
  pointer-events: none;
  flex-shrink: 0;
  ${include("space")};
  ${include("font-size")};
  ${mixin("opacity", mapToOpacity)};
  ${include("color")};
  ${include("flex-item")};
  ${mixin("height", mapHeightToEm)};
  ${mixin("visibility")};
  ${mixin("justify-self")};
  ${mixin("cursor")};
`;

export { Icon };
