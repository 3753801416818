import { get } from "lodash";
import { css } from "styled-components";
import { SIZES } from "./sizes";

const MAX_WIDTHS = {
  ...SIZES,
};
export function mapPropsToMaxWidth(props) {
  const value = get(props, "max-width");

  if (value) {
    return css`
      max-width: ${get(MAX_WIDTHS, value, value)};
    `;
  }
}
