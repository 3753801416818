import { isFunction } from "lodash";

function composeRefs(...args) {
  const refs = args.filter(Boolean);
  if (refs.length && refs.length === 1) {
    return refs[0];
  }

  return (node) => {
    for (const ref of refs) {
      if (isFunction(ref)) {
        ref(node);
      } else if (ref) {
        try {
          ref.current = node;
        } catch (error) {
          throw new Error(`Cannot assign value "${node}" to ref "${ref}"`);
        }
      }
    }
  };
}

function assignRef(ref, value) {
  if (ref == null) return;

  if (isFunction(ref)) {
    ref(value);
    return;
  }

  try {
    ref.current = value;
  } catch (error) {
    throw new Error(`Cannot assign value '${value}' to ref '${ref}'`);
  }
}

function mergeRefs(...refs) {
  return (value) => {
    refs.forEach((ref) => assignRef(ref, value));
  };
}

export { composeRefs, mergeRefs };
