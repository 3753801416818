import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import React from "react";

import { hasPermissions, noop, PERMISSIONS } from "@gfw/core";
import { selectorUsersMeCurrentPermissions } from "@gfw/backend-connector";

const QuestionnaireContext = React.createContext();

function useQuestionnaireContext() {
  return React.useContext(QuestionnaireContext);
}

function useQuestionnaireQuestion(question) {
  const questionnaire = useQuestionnaireContext();

  let [answer, updateAnswer] = React.useState(question.answer);

  // there is a difference here between isReadOnly and isEditable:
  // A Questionnaire can be editable if the user is authorized to perform edits (e.g. canEdit === true).
  // BUT at the same time the Questionnaire can be set to display in read-only mode thus overriding isEditable
  const status = React.useMemo(() => {
    if (questionnaire.isReadOnly) {
      return "READ_ONLY";
    } else if (questionnaire.isEditable) {
      return "EDITABLE";
    } else {
      return "READ_ONLY";
    }
  }, [questionnaire.isEditable, questionnaire.isReadOnly]);

  const onAnswerUpdated = (newAnswer) => {
    if (questionnaire.isEditable) {
      updateAnswer(newAnswer);
      questionnaire.onQuestionUpdated({ ...question, answer: newAnswer });
    }
  };

  return {
    answer,
    id: question.id,
    onAnswerUpdated,
    status,
    isReadOnly: status === "READ_ONLY",
    isEditable: status === "EDITABLE",
    ProfilePeopleList: questionnaire.ProfilePeopleList,
  };
}

function QuestionnaireProvider({
  isReadOnly = false,
  onQuestionUpdated = noop,
  ProfilePeopleList,
  children,
  forceEditMode = false,
}) {
  const permissions = useSelector(selectorUsersMeCurrentPermissions);

  const context = {
    isReadOnly,
    // could be useful/extracted into something else we could use ?? (a hook perhaps)
    isEditable:
      forceEditMode || hasPermissions(permissions, PERMISSIONS.PROFILE.UPDATE),
    // PROFILE UPDATE is set to Regular or FUll user,
    // this component could be use in edit mode for profile info or DD Request
    onQuestionUpdated,
    ProfilePeopleList,
  };

  return (
    <QuestionnaireContext.Provider value={context}>
      {children}
    </QuestionnaireContext.Provider>
  );
}
QuestionnaireProvider.propTypes = {
  isReadOnly: PropTypes.bool,
  onQuestionUpdated: PropTypes.func,
  children: PropTypes.node,
  ProfilePeopleList: PropTypes.func,
  forceEditMode: PropTypes.bool,
};

export {
  QuestionnaireProvider,
  useQuestionnaireContext,
  useQuestionnaireQuestion,
};
