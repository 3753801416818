import { get, has } from "lodash";
export const OPACITIES = {
  none: 1,
  50: 0.5,
  hidden: 0,
  "de-emphasize": 0.8,
};

const isScaleValue = (value) => has(OPACITIES, value);
export function mapPropsToOpacity(props) {
  const value = get(props, "opacity");
  return (
    value && {
      opacity: getOpacity(value),
    }
  );
}

export function getOpacity(value) {
  if (isScaleValue(value)) {
    return OPACITIES[value];
  } else {
    return value;
  }
}
