import { getOwnerDocument } from "./getOwnerDocument";
import { isFunction } from "lodash";
export function getComputedStyle(element, styleProperty) {
  let value = null;
  let doc = getOwnerDocument(element);

  if (element.currentStyle) {
    value = element.currentStyle[styleProperty];
  } else if (
    doc &&
    doc.defaultView &&
    isFunction(doc.defaultView.getComputedStyle)
  ) {
    value = doc.defaultView
      .getComputedStyle(element, null)
      .getPropertyValue(styleProperty);
  }

  return value;
}
