import { Flex, Input } from "antd";
import { observer } from "mobx-react";
import React from "react";

import { Grid } from "@gfw/corvus";
import {
  StyledInput,
  StyledLabel,
  StyledLabelSmall,
} from "./NewsProviderForm.styles";

const NewsProviderForm = observer(function NewsProviderForm({ provider }) {
  return (
    <Flex gap="middle" vertical>
      <div>
        <StyledLabel>Name</StyledLabel>
        <Input
          onChange={(e) => {
            provider.update("name", e.target.value);
          }}
          value={provider.name}
        />
      </div>
      <div>
        <StyledLabel>Home Page</StyledLabel>
        <Input
          onChange={(e) => {
            provider.update("homepage", e.target.value);
          }}
          value={provider.homepage}
        />
      </div>
      <div>
        <StyledLabel>Image</StyledLabel>
        <Input
          onChange={(e) => {
            provider.update("image", e.target.value);
          }}
          value={provider.image}
        />
      </div>
      <div>
        <StyledLabel>Country</StyledLabel>
        <Grid.Row>
          <Grid.Col span={2}>
            <StyledInput
              onChange={(e) => {
                provider.update("iso2", e.target.value);
              }}
              value={provider.iso2}
            />
          </Grid.Col>
        </Grid.Row>
        <StyledLabelSmall>
          ISO2 name (fr for france as an example)
        </StyledLabelSmall>
      </div>
    </Flex>
  );
});

export { NewsProviderForm };
