import React from "react";
import { Box } from "../Box";

const FlexBox = React.forwardRef(function FlexBox(
  { align, justify, direction, wrap, variant, ...props },
  ref,
) {
  return (
    <Box
      ref={ref}
      display={variant === "inline" ? "inline-flex" : "flex"}
      align-items={align}
      justify-content={justify}
      flex-wrap={wrap}
      flex-direction={direction}
      {...props}
    />
  );
});

FlexBox.Item = Box;

export { FlexBox };
