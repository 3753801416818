import React from "react";

function useDisclosure(defaultIsOpen) {
  const [isOpen, setIsOpen] = React.useState(Boolean(defaultIsOpen));
  const onClose = React.useCallback(() => setIsOpen(false), []);
  const onOpen = React.useCallback(() => setIsOpen(true), []);
  const onToggle = React.useCallback(
    () => setIsOpen((prevIsOpen) => !prevIsOpen),
    [],
  );

  return {
    isOpen,
    isClosed: !isOpen,
    onOpen,
    onClose,
    onToggle,
  };
}

export { useDisclosure };
