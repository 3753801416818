import { Question } from "./components";
import {
  QuestionnaireProvider,
  useQuestionnaireQuestion,
} from "./components/Questionnaire";
import { useQuestionnaire } from "./useQuestionnaire";

QuestionnaireProvider.Question = Question;

export { QuestionnaireProvider, useQuestionnaire, useQuestionnaireQuestion };
