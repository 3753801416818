import { Table } from "antd";
import { useQuery } from "react-query";
import gql from "graphql-tag";
import React from "react";

import { apiClient } from "@gfw/backend-connector";
import { Card, Loading, NavigateLink } from "@gfw/corvus";
import { datetime } from "@gfw/core";
import { Text } from "@gfw/orion";

const NEWS_ARTICLES = gql`
  query NewsArticles {
    NewsArticles {
      id
      title
      subtitle
      hidden
      provider {
        name
      }
      createdAt
      publishedAt
    }
  }
`;

function NewsArticleList() {
  const { data: articles = [], isLoading } = useQuery(
    ["newsArticles"],
    async () => {
      const response = await apiClient.graphql(NEWS_ARTICLES);

      if (response?.NewsArticles) {
        return response.NewsArticles;
      } else {
        return { error: "error" };
      }
    },
    { refetchOnWindowFocus: false },
  );

  if (isLoading) {
    return <Loading />;
  }

  const columns = [
    {
      width: "25%",
      title: "Title",
      dataIndex: ["title"],
      key: "title",
      render: (title, article) => {
        return (
          <NavigateLink noPadding to={`/news/articles/${article.id}/edit}`}>
            <Text
              css={`
                text-align: initial;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              `}
            >
              {title}
            </Text>
          </NavigateLink>
        );
      },
      ellipsis: true,
    },
    {
      width: "25%",
      title: "Subtitle",
      dataIndex: ["subtitle"],
      key: "subtitle",
      render: (subtitle) => {
        return subtitle;
      },
      ellipsis: true,
    },
    {
      title: "Provider",
      dataIndex: ["provider", "name"],
      key: "provider",
      render: (name) => {
        return name;
      },
    },
    {
      title: "Hidden",
      dataIndex: ["hidden"],
      key: "hidden",
      render: (hidden) => {
        return hidden ? "Yes" : "No";
      },
    },
    {
      title: "CreatedAt",
      dataIndex: ["createdAt"],
      key: "createdat",
      render: (date) => {
        return datetime.iso8601Date(date);
      },
    },
    {
      title: "PublishedAt",
      dataIndex: ["publishedAt"],
      key: "PublishedAt",
    },
  ];

  return (
    <Card
      extra={
        <NavigateLink icon="add" to="/news/articles/new">
          Add News Article
        </NavigateLink>
      }
      title={<Text typography="h6">News Articles</Text>}
    >
      {articles && (
        <Table
          columns={columns}
          dataSource={articles}
          loading={isLoading}
          pagination={false}
          rowKey={(item) => item.id}
        />
      )}
    </Card>
  );
}

export { NewsArticleList };
