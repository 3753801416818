import React from "react";
import {
  useComboBox,
  useOnBlurEventHandler,
  useOnKeyDownEventHandler,
  event,
} from "./ComboBox";
import { Input } from "../Input";
import { composeEventHandlers, useComposedRef } from "../../core";
import { toHashCode } from "@gfw/core";

const ComboBoxInput = React.forwardRef(function ComboBoxInput(
  { onFocus, onBlur, onKeyDown, onChange, ...props },
  forwardedRef,
) {
  const {
    dispatch,
    inputRef,
    isVisible,
    listboxId,
    selectedValue,
  } = useComboBox();
  const ref = useComposedRef(inputRef, forwardedRef);
  const onBlurHandler = useOnBlurEventHandler();
  const onKeyDownHandler = useOnKeyDownEventHandler();

  const onFocusHandler = () => {
    dispatch(event.FOCUS);
  };

  const onChangeHandler = () => {
    dispatch(event.CHANGE);
  };

  return (
    <Input
      aria-autocomplete="both"
      aria-controls={listboxId}
      aria-expanded={isVisible}
      aria-haspopup="listbox"
      aria-activedescendant={
        selectedValue ? String(toHashCode(selectedValue)) : undefined
      }
      role="combobox"
      onFocus={composeEventHandlers(onFocus, onFocusHandler)}
      onBlur={composeEventHandlers(onBlur, onBlurHandler)}
      onChange={composeEventHandlers(onChange, onChangeHandler)}
      onKeyDown={composeEventHandlers(onKeyDown, onKeyDownHandler)}
      ref={ref}
      {...props}
    />
  );
});

ComboBoxInput.OrionComponent = "Input";

export { ComboBoxInput };
