import { AutoComplete, Flex, Tag } from "antd";
import styled from "styled-components";

import { colors } from "@gfw/corvus";

export const StyledFlex = styled(Flex)`
  border-bottom: ${(props) =>
    props.$isLastItem === true ? undefined : "1px solid rgba(0, 0, 0, 0.1)"};
`;

export const StyledAutoComplete = styled(AutoComplete)`
  width: 100%;
`;

export const StyledTag = styled(Tag)`
  padding-left: 4px;
  padding-right: 4px;
`;

export const StyledSpan = styled.span`
  color: ${colors.gray[800]};
`;
