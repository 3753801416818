import { Box } from "../Box";
import { FlexBox } from "../FlexBox";
import { Icon } from "../Icon";
import { RadioGroup } from "../RadioGroup";
import { VisuallyHidden } from "../VisuallyHidden";
import { warning } from "@gfw/core";
import React from "react";

import { RadioCircle as RadioButtonOff } from "@styled-icons/boxicons-regular/RadioCircle";
import { RadioCircleMarked as RadioButtonOn } from "@styled-icons/boxicons-regular/RadioCircleMarked";

function useRadioStyle({ isChecked, isDisabled }) {
  return React.useMemo(
    () => ({
      getLabelStyle: () => ({
        opacity: isDisabled ? (isChecked ? 1 : 0.5) : 1,
        cursor: isDisabled ? "default" : "pointer",
      }),
      getIconStyle: () => ({
        color: isChecked
          ? isDisabled
            ? "currentColor"
            : "gfw.600"
          : "currentColor",
      }),
    }),
    [isChecked, isDisabled],
  );
}

const Radio = React.forwardRef(function Radio(
  {
    children,
    isReadOnly,
    isDisabled,
    isChecked,
    checked: _checked,
    value,
    onBlur,
    onChange,
    name,
    ...props
  },
  forwardedRef,
) {
  const { getIconStyle, getLabelStyle } = useRadioStyle({
    isChecked,
    isDisabled: isDisabled || isReadOnly,
  });

  if (_checked !== undefined) {
    warning(
      true,
      `[@gfw:orion] Radio has deprecated support for 'checked'. Please use 'isChecked' instead.`,
    );
    isChecked = _checked;
  }

  /*   
    A RadioButton consist of:
      1. A Label
      2. A Hidden Input element (makes it work like a "real" <input type="radio" />)
      3. A Visual Icon that acts as the radio for the UI
  */

  return (
    <FlexBox
      align="center"
      as="label"
      variant="inline"
      {...getLabelStyle()}
      {...props}
    >
      <VisuallyHidden
        as="input"
        checked={isChecked}
        disabled={isDisabled || isReadOnly}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        ref={forwardedRef}
        type="radio"
        value={value}
      />

      <FlexBox
        align="center"
        aria-hidden
        justify="center"
        variant="inline"
        {...getIconStyle()}
      >
        <Icon as={isChecked ? RadioButtonOn : RadioButtonOff} fs="2em" />
      </FlexBox>
      {children && <Box ml="4px">{children}</Box>}
    </FlexBox>
  );
});

// make it possible to do <Radio.Group> <Radio /> </Radio.Group>
Radio.Group = RadioGroup;

export { Radio };
