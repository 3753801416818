import styled, { css } from "styled-components";
import {
  getFontWeight,
  declareVariant,
  typographies,
  getColor,
} from "../../style";
import { Box } from "../Box";

const overflow = declareVariant("overflow", {
  hidden: css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
});

const typography = declareVariant("typography", {
  ...typographies,
  // variations of the above to combine like { typography="h5 upper" }
  // for example which would take the "base typography" of h5 but combine it (alter it) with upper
  upper: css`
    text-transform: uppercase;
  `,
  caps: css`
    text-transform: capitalize;
  `,
  centered: css`
    text-align: center;
  `,
  "font-bolder": css`
    font-weight: ${getFontWeight("bolder")};
  `,
  "font-bold": css`
    font-weight: ${getFontWeight("bold")};
  `,
  "font-regular": css`
    font-weight: ${getFontWeight("regular")};
  `,
  "font-medium": css`
    font-weight: ${getFontWeight("medium")};
  `,
  "de-emphasize": css`
    opacity: 0.8;
  `,
  // remove
  inherit: css`
    font-size: inherit;
    line-height: inherit;
    letter-spacing: normal;
    font-weight: inherit;
  `,
  placeholder: css`
    opacity: 0.5;
  `,
  disabled: css`
    color: ${getColor("text.disabled")};
    opacity: 0.5;
    cursor: default;
    user-select: none;
  `,
  condensed: css`
    line-height: 1;
  `,
  icon: css`
    display: flex;
    line-height: 1;
    align-items: center;
  `,

  "list--column-heading": css`
    font-size: 0.75rem;
    font-weight: ${getFontWeight("bold")};
    letter-spacing: 0.08333em;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.38);
    line-height: 1;
  `,
});

const variants = declareVariant({
  header: css`
    display: flex;
    flex-direction: column;
  `,
});

const Text = styled(Box)`
  white-space: ${(props) => props.whiteSpace || "inherit"};
  ${typography};
  ${overflow};
  ${variants};
`;

Text.defaultProps = {
  as: "p",
};

export { Text };
