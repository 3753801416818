const newsArticleModel = {
  title: "",
  subtitle: "",
  provider: null,
  type: "",
  publishedAt: "",

  update(property, value) {
    this[property] = value;
  },

  updateAll(value) {
    Object.assign(this, value);
  },

  getSnapshot() {
    return {
      ...JSON.parse(JSON.stringify(this)),
      provider: this.provider.id,
    };
  },

  get valid() {
    return (
      this.title.length >= 2 &&
      this.subtitle.length >= 2 &&
      this.link &&
      this.provider &&
      this.publishedAt &&
      this.type
    );
  },
  get invalid() {
    return !this.valid;
  },
};

const newsProviderModel = {
  name: "",
  homepage: "",
  image: "",
  iso2: "",

  update(property, value) {
    this[property] = value;
  },

  updateAll(value) {
    Object.assign(this, value);
  },

  getSnapshot() {
    return {
      ...JSON.parse(JSON.stringify(this)),
    };
  },

  get valid() {
    return (
      this.name.length >= 2 &&
      this.homepage.length >= 2 &&
      this.iso2.length === 2
    );
  },
  get invalid() {
    return !this.valid;
  },
};

export { newsArticleModel, newsProviderModel };
