import warning from "tiny-warning";
import { has, pick } from "lodash";
export const FONT_WEIGHTS = {
  //thin: 100,
  light: 300,
  regular: 400,
  medium: 500,
  bold: 700,
  bolder: 900,
  inherit: "inherit",
};

export function mapPropsToFontWeight(props) {
  const properties = pick(props, ["font-weight", "fw"]);

  return Object.values(properties).reduce((styles, value) => {
    return {
      ...styles,
      fontWeight: getFontWeight(value),
    };
  }, {});
}

export function getFontWeight(weight) {
  warning(
    has(FONT_WEIGHTS, weight),
    `You are trying to use font weight '${weight}' but we don't currently load that from the google font api.`,
  );

  return FONT_WEIGHTS[weight];
}
