import { mapPropsToPosition } from "./position";
import { mapPropsToZIndex } from "./z-index";
import { mapPropsToOverflow } from "./overflow";
import { css } from "styled-components";
import { mixin } from "../../utils";

// $visibility

// merge multiple style functions into 1 (the layout module)
const layout = css`
  ${mapPropsToPosition};
  ${mapPropsToZIndex};
  ${mapPropsToOverflow};
  ${mixin("visibility")};
`;

export { mapPropsToPosition, mapPropsToZIndex };

export default layout;
