import { pick, merge, isNil, flattenDeep } from "lodash";
import { mapPropsToBorder, mapPropsToBorderRadius } from "../border";
import { mapPropsToBgColor, mapPropsToColor } from "../color";
import { mapPropsToBoxShadow, mapPropsToOpacity } from "../modules/effects";
import { mapPropsToZIndex } from "../modules/layout";
import { mixins as interactivity } from "../modules/interactivity";
import { typography } from "../modules/typography";
import { mapPropsToSpace } from "../space";

// supported selectors
const PSEUDO_SELECTORS = {
  "first-child": "&:first-of-type",
  "last-child": "&:last-of-type",
  "even-child": "&:nth-of-type(even)",
  "odd-child": "&:nth-of-type(odd)",
  hover: "&:hover",
  focus: "&:focus",
  active: "&:active",
  "read-only":
    "&[aria-readonly=true], &[aria-readonly=true]:focus, &[aria-readonly=true]:hover",
  "focus-within": "&:focus-within",
  selected: "&[aria-selected=true]",
  invalid: "&[aria-invalid=true]",
  expanded: "&[aria-expanded=true]",
  disabled:
    "&:disabled, &:disabled:focus, &:disabled:hover, &[aria-disabled=true], &[aria-disabled=true]:focus, &[aria-disabled=true]:hover",
  // groups are a bit special, the element needs to be within something with role="group"
  "group:focus-within": "[role=group]:focus-within &",
};

// Experimental new version
const SELECTORS = {
  $isadjacentsibling: "& + &",
  $isfirstchild: "&:first-of-type",
  $islastchild: "&:last-of-type",
  $isonlychild: "&:only-of-type",
  $isevenchild: "&:nth-of-type(even)",
  $isfocused: "&:focus",
  $isselected: "&[aria-selected=true]",
  $ischecked:
    "&[aria-checked=true], &[data-checked=true], &[data-state=checked]",
  $ishovered: "&:hover",
  $isexpanded:
    "&[aria-expanded=true], &[data-expanded], &[data-state=expanded]",
  $isreadonly:
    "&[aria-readonly=true], &[aria-readonly=true]:focus, &[aria-readonly=true]:hover",
  $isdisabled:
    "&:disabled, &:disabled:focus, &:disabled:hover, &[aria-disabled=true], &[aria-disabled=true]:focus, &[aria-disabled=true]:hover",
  $ishighlighted: "&[data-highlighted=true]",
  $ispressed: "&[aria-pressed=true]",
};

// supported functions (add new ones as needed)
const PSEUDO_STYLE_FUNCTIONS = [
  mapPropsToSpace,
  mapPropsToBorder,
  mapPropsToBgColor,
  mapPropsToColor,
  mapPropsToBorderRadius,
  mapPropsToBoxShadow,
  mapPropsToZIndex,

  mapPropsToOpacity,
  ...interactivity,
  ...typography,
];

export function getPseudoStyles(value) {
  return PSEUDO_STYLE_FUNCTIONS.reduce((styles, fn) => {
    let style = fn(value);
    if (isNil(style)) {
      return styles;
    } else if (Array.isArray(style)) {
      // e.g. [ { 'padding-top': 0 } ];
      style = flattenDeep(style);
      // turn it into an object instead
      style = Object.assign({}, ...style.map((x) => x));
    }

    return {
      ...styles,
      ...style,
    };
  }, {});
}

export function mapPropsToPseudoSelector({ pseudo, ...props }) {
  let selectors = pick(pseudo || {}, Object.keys(PSEUDO_SELECTORS));

  // merge in the "new" experimental selectors too
  merge(selectors, pick(props, Object.keys(SELECTORS)));
  return Object.keys(selectors).reduce(
    (styles, selector) => ({
      ...styles,
      [PSEUDO_SELECTORS[selector]]: getPseudoStyles(selectors[selector]),
      [SELECTORS[selector.toLowerCase()]]: getPseudoStyles(selectors[selector]),
    }),
    {},
  );
}
