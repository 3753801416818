import React from "react";
import { omit } from "lodash";
import { Stack } from "../Stack";
import { Box } from "../Box";
import { createAndUseContext } from "../../core";

const [CheckboxGroupProvider, useCheckboxGroup] = createAndUseContext({
  name: "CheckboxGroup",
  required: false,
});

const CheckboxGroup = React.forwardRef(function CheckboxGroup(
  {
    isDisabled,
    isReadOnly,
    onChange,
    defaultValues = [],
    children,
    as: Component,
    ...props
  },
  forwardedRef,
) {
  const [values, setValues] = React.useState(defaultValues);
  const { current: isControlled } = React.useRef("values" in props);

  const getValues = () => (isControlled ? props.values : values);

  const onGroupChange = (event) => {
    const { checked, value } = event.target;
    let newValues;
    if (checked) {
      // add the value
      newValues = [...getValues(), value];
    } else {
      // remove the value
      newValues = getValues().filter((val) => val !== value);
    }

    if (!isControlled) {
      setValues(newValues);
    }

    onChange?.(newValues);
  };

  const context = {
    isDisabled,
    isReadOnly,
    values: getValues(),
    onChange: onGroupChange,
  };

  return (
    <CheckboxGroupProvider value={context}>
      <Box
        as={Component}
        ref={forwardedRef}
        role="group"
        {...omit(props, "values")}
      >
        {children}
      </Box>
    </CheckboxGroupProvider>
  );
});

CheckboxGroup.defaultProps = {
  spacing: "none",
  as: Stack,
};

export { CheckboxGroup, useCheckboxGroup };
