function ApiError({ error }) {
  if (typeof error === "string") {
    return error;
  } else if (Array.isArray(error)) {
    return [...error].join(" + ");
  }

  return JSON.stringify(error, null, 2);
}

export { ApiError };
