import { capitalize } from "lodash";
import { Table, Tag, Typography } from "antd";
import { useMutation } from "react-query";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import React, { useEffect } from "react";

import {
  __DEPRECATED__ErrorHandler,
  Banner,
  Button,
  Card,
  Modal,
  NavigateLink,
  notification,
} from "@gfw/corvus";
import { apiClient } from "@gfw/backend-connector";
import { BANNER_TYPE, SORT_ORDER } from "@gfw/core";
import { CursorListControls, useCursorPagination } from "@gfw/orion";

import { Page, PageHeader } from "@src/resources/generic/components";
import { StyledFlex, StyledFlexAction, StyledText } from "../Banner.styles";

dayjs.extend(localizedFormat);

const { Text } = Typography;

// This page is shown at /banner
function BannerList() {
  const pagination = useCursorPagination({
    data: { orderBy: { field: "NAME", order: "asc" } },
  });

  const {
    mutateAsync: getBanners,
    data: banners = [],
    isLoading,
  } = useMutation(
    async () => {
      const response = await apiClient.get(`/banners`);
      return response.data.banners;
    },
    { onError: __DEPRECATED__ErrorHandler },
  );

  const { mutateAsync: deleteBanner, isLoading: isDeleteLoading } = useMutation(
    (id) => apiClient.delete(`/banners/${id}`),
    {
      onSuccess: () => {
        notification.success({ message: "Banner deleted successfully" });
        getBanners();
      },
      onError: __DEPRECATED__ErrorHandler,
    },
  );

  const handleTableChange = (_1, _filters, sorter) => {
    const { columnKey, order } = sorter;

    pagination.resetCursor({
      orderBy: {
        field: columnKey,
        order: order === SORT_ORDER.DESCEND ? SORT_ORDER.DESC : SORT_ORDER.ASC,
      },
    });
  };

  const antdColumns = [
    {
      title: "Title",
      sorter: true,
      dataIndex: "title",
      key: "title",
      defaultSortOrder: "ascend",
      ellipsis: true,
      render: (name, banner) => (
        <NavigateLink noPadding to={`/banner/${banner?._id}`}>
          <StyledText ellipsis strong>
            {name}
          </StyledText>
        </NavigateLink>
      ),
      showSorterTooltip: { title: "Sort on title" },
    },
    {
      width: 130,
      title: "Start at",
      dataIndex: "startAt",
      key: "startAt",
      render: (value) => dayjs(value).format("L LT"),
    },
    {
      width: 130,
      title: "End at",
      dataIndex: "endAt",
      key: "endAt",
      render: (value) => dayjs(value).format("L LT"),
    },
    {
      width: 130,
      title: "Type",
      dataIndex: "bannerType",
      key: "bannerType",
      render: (value, _banner) => {
        const bannerType = capitalize(BANNER_TYPE[value]);
        const banner = {
          title: bannerType,
          bannerType: BANNER_TYPE.BANNER,
          alertType: _banner.alertType,
        };
        return <Banner banner={banner} isAdminApp={true} />;
      },
    },
    {
      width: 100,
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      render: (isActive, banner) => {
        //unknown type
        let color = "grey";
        let label = "Inactive";
        if (banner.isDisplayed) {
          color = "green";
          label = "Displayed";
        } else if (isActive) {
          color = "blue";
          label = "Active";
        }
        return <Tag color={color}>{label}</Tag>;
      },
    },
    {
      width: 160,
      title: "Actions",
      render: (banner) => {
        return (
          <StyledFlexAction>
            <NavigateLink icon="edit" to={`/banner/${banner._id}`}>
              Edit
            </NavigateLink>
            <Modal
              hideFooterBorder
              hideHeaderBorder
              onOk={() => deleteBanner(banner._id)}
              title="Delete banner"
              trigger={
                <Button
                  danger
                  icon="delete"
                  loading={isDeleteLoading}
                  noPadding
                  type="link"
                >
                  Delete
                </Button>
              }
            >
              <Text>Are you sure that you want to delete this banner?</Text>
            </Modal>
          </StyledFlexAction>
        );
      },
    },
  ];

  useEffect(() => {
    getBanners();
  }, [getBanners]);

  return (
    <Page>
      <PageHeader iconType="banner">Banner</PageHeader>
      <Card>
        <StyledFlex>
          <NavigateLink icon="add" noPadding to="/banner/new">
            New Banner
          </NavigateLink>
        </StyledFlex>
        <Table
          columns={antdColumns}
          dataSource={banners}
          loading={isLoading}
          onChange={handleTableChange}
          pagination={false}
          rowKey={(item) => item._id}
        />
        <CursorListControls pagination={pagination} show={banners.length > 0} />
      </Card>
    </Page>
  );
}

export default BannerList;
