import React from "react";
import styled from "styled-components";

import { FONT_SIZES } from "@gfw/corvus";

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  margin: 16px 0;
  font-size: ${FONT_SIZES.xl2};
`;

function PageTitle({ children }) {
  return <StyledDiv>{children}</StyledDiv>;
}

export { PageTitle };
