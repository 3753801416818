import { Input, List } from "antd";
import { useMutation } from "react-query";
import gql from "graphql-tag";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { apiClient } from "@gfw/backend-connector";
import { Button, Loading, Modal, useThrottledValue } from "@gfw/corvus";

import { UserSearchResult, UserSearchResultAction } from "./UserSearch";

const StyledInput = styled(Input)`
  width: 50%;
`;

const GET_USERS = gql`
  query SearchQuery($query: String!) {
    Users(first: 10, where: { name_contains: $query }) {
      edges {
        node {
          id
          name
          email
          status
        }
      }
    }
  }
`;

function AddExistingUserAction({ disabled, onActionCompleted }) {
  const [query, setQuery] = useState("");
  const [users, setUsers] = useState();

  const throttledTerm = useThrottledValue(query, 300);

  useEffect(() => {
    if (query) fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [throttledTerm]);

  const { mutateAsync: fetch, isLoading } = useMutation(
    () => {
      return apiClient.graphql(GET_USERS, { variables: { query } });
    },
    {
      onSuccess: (response) => {
        setUsers(response.Users.edges);
      },
    },
  );

  return (
    <Modal
      footer={null}
      title="Add existing user without invite"
      trigger={
        <Button
          confirmCancelText="Ok"
          confirmOkText="Don't care"
          confirmTitle="No no, plz use invite instead"
          disabled={disabled}
        >
          Add Existing User
        </Button>
      }
    >
      <StyledInput
        onChange={(e) => setQuery(e.target.value)}
        placeholder="Find users..."
        value={query}
      />
      {isLoading && <Loading size="small" />}
      {!!users && (
        <List
          dataSource={users}
          locale={{ emptyText: "Sorry, no users that matched that query." }}
          renderItem={(user) => (
            <List.Item>
              <UserSearchResult {...user.node} />
              <UserSearchResultAction
                onUserAddedToProfile={onActionCompleted}
                {...user.node}
              />
            </List.Item>
          )}
          split={false}
        />
      )}
    </Modal>
  );
}

export { AddExistingUserAction };
