import { get, has } from "lodash";

import { getHeight } from "../../height/height";
import { getWidth } from "../../width/width";
import { warning } from "@gfw/core";

// these are our pre-defined sizes used by Inputs, Buttons, etc (but also available on all components through Box)
export const SIZES = {
  xs: "64px",
  sm: "128px",
  md: "256px",
  lg: "512px",
};

const isScaleValue = (value) => has(SIZES, value);

export function getSize(size) {
  return get(SIZES, size, SIZES.xs);
}
export function mapToSize(value) {
  if (isScaleValue(value)) {
    return { width: SIZES[value] };
  } else {
    warning(
      true,
      `[@gfw:orion] size should only be used for pre-defined sizes. Maybe you need to change this to width="${value}" and height="${value}" instead.`,
    );

    return {
      width: getHeight(value),
      height: getWidth(value),
    };
  }
}
