import { css } from "styled-components";
import { mapPropsToFontSize, getFontSize } from "./font-size";
import { mapPropsToLineHeight } from "./line-height";
import { mapPropsToFontWeight, getFontWeight } from "./font-weight";
import { mapToWordBreak } from "./word-break";
import { mapToLetterSpacing } from "./letter-spacing";
import { mixin } from "../../utils";

export const typography = [mapPropsToFontWeight];

// merge all typography related styling utilities into a 'module'
const module = css`
  ${mixin("font")};
  ${mapPropsToFontSize};
  ${mapPropsToLineHeight};
  ${mixin("text-transform")};
  ${mixin("text-align")};
  ${mixin("font-style")};
  ${mixin("vertical-align")};
  ${mixin("white-space")};
  ${mixin("word-break", mapToWordBreak)};
  ${mixin("letter-spacing", mapToLetterSpacing)};
  ${typography};
`;

// export each styling utility seperatly also
export { getFontSize, getFontWeight, mapPropsToFontSize, mapPropsToFontWeight };

// but the default export is the complete module
export default module;
