import { get } from "lodash";
export function declareVariant(...args) {
  if (args.length === 1) {
    return declareVariant("variant", ...args);
  }

  const [prop, variants] = args;

  const getDefaultVariantIfExists = () => get(variants, "default");

  return function variant(props) {
    const key = props[prop];
    const keys = (key && key.split(" ")) || [];

    if (keys.length > 1) {
      return keys.reduce((prev, key) => [...prev, get(variants, key)], []);
    } else {
      return get(variants, key, getDefaultVariantIfExists());
    }
  };
}
