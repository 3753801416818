import { Machine, assign } from "xstate";

const ListBoxState = {
  Idle: "idle",
  Suggesting: "suggesting", // listbox is showing options to the user
};

const ListBoxEvent = {
  BUTTON_MOUSE_DOWN: "BUTTON_MOUSE_DOWN", // button is clicked
  OPTION_MOUSE_DOWN: "OPTION_MOUSE_DOWN", // option is clicked/selected
  MOUSE_DOWN: "MOUSE_DOWN",
  CLOSE: "CLOSE",
};

const setValue = assign({
  value: (_, event) => event.value,
  label: (_, event) => event.label,
});

const machine = Machine(
  {
    id: "listbox",
    initial: ListBoxState.Idle,
    states: {
      [ListBoxState.Idle]: {
        on: {
          [ListBoxEvent.BUTTON_MOUSE_DOWN]: {
            target: ListBoxState.Suggesting,
          },
        },
      },
      [ListBoxState.Suggesting]: {
        on: {
          [ListBoxEvent.BUTTON_MOUSE_DOWN]: {
            target: ListBoxState.Idle,
            actions: ["focusButton"],
          },
          [ListBoxEvent.OPTION_MOUSE_DOWN]: {
            target: ListBoxState.Idle,
            actions: [setValue, "focusButton"],
          },
          [ListBoxEvent.MOUSE_DOWN]: {
            target: ListBoxState.Idle,
            cond: "clickedOutsideOfListBox",
          },
        },
      },
    },
  },
  {},
);

export { machine, ListBoxState, ListBoxEvent };
