import React from "react";
import { createPopper } from "@popperjs/core";
import { Portal } from "reakit/Portal";
import { Box } from "../Box";
import { composeRefs } from "../../core";
import maxSize from "./maxSizeModifier";
import applyMinWidth from "./applyMinWidthModifier";

const Overlay = React.forwardRef(function Overlay(
  { children, targetRef, isVisible, placement, offset, ...props },
  forwardedRef,
) {
  const [styles, setStyles] = React.useState({});
  const popper = React.useRef(null);
  const overlayRef = React.useRef();

  React.useEffect(() => {
    popper.current = createPopper(targetRef.current, overlayRef.current, {
      placement: placement,
      strategy: "absolute",
      modifiers: [
        {
          name: "applyStyles",
          enabled: false,
        },
        {
          name: "offset",
          options: {
            offset,
          },
        },
        {
          name: "preventOverflow",
          enabled: true,
        },
        {
          name: "flip",
          enabled: true,
        },
        applyMinWidth,
        {
          ...maxSize,
          options: {
            padding: 8,
          },
        },
        {
          name: "applyMaxSize",
          enabled: true,
          phase: "beforeWrite",
          requires: ["maxSize"],
          fn({ state }) {
            const { height } = state.modifiersData.maxSize;
            state.styles.popper.maxHeight = `${height}px`;
            state.styles.popper.overflowY = "auto";
          },
        },
        {
          name: "updateState",
          phase: "write",
          enabled: true,
          fn({ state }) {
            setStyles({
              ...state.styles.popper,
            });
          },
        },
      ],
    });
    return () => {
      if (popper.current) {
        popper.current.destroy?.();
        popper.current = null;
      }
    };
  }, [targetRef, overlayRef, placement, offset]);

  React.useEffect(() => {
    if (popper.current) {
      popper.current.forceUpdate();
    }
  }, [isVisible]);

  return (
    <Portal>
      <Box
        ref={composeRefs(overlayRef, forwardedRef)}
        {...props}
        visibility={isVisible ? "visible" : "hidden"}
        style={styles}
      >
        {children}
      </Box>
    </Portal>
  );
});

Overlay.defaultProps = {
  bg: "white",
  "z-index": "overlay",
  "box-shadow": "xl",
  border: true,
  // see: https://popper.js.org/docs/v2/constructors/#options
  placement: "bottom",
  // see: https://popper.js.org/docs/v2/modifiers/offset/
  offset: [0, 0],
};

export { Overlay };
