import { Table } from "antd";
import { useQuery } from "react-query";
import gql from "graphql-tag";
import React from "react";

import { apiClient } from "@gfw/backend-connector";
import { Card, NavigateLink } from "@gfw/corvus";
import { Text } from "@gfw/orion";

const GET_RSS_PROVIDERS = gql`
  query RssProviders {
    RssFeeds {
      url
      id
      provider {
        name
      }
    }
  }
`;

function RssFeedList() {
  const { data: RssFeeds = [] } = useQuery(
    ["rssFeedList"],
    async () => {
      const response = await apiClient.graphql(GET_RSS_PROVIDERS);

      if (response?.RssFeeds) {
        return response.RssFeeds;
      } else {
        return { error: "error" };
      }
    },
    { refetchOnWindowFocus: false },
  );

  const columns = [
    {
      width: "65%",
      title: "Url",
      dataIndex: "url",
      key: "url",
      render: (url, rss) => {
        return (
          <NavigateLink noPadding to={`/news/rssfeed/${rss.id}/edit}`}>
            <Text
              css={`
                text-align: initial;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              `}
            >
              {url}
            </Text>
          </NavigateLink>
        );
      },
      ellipsis: true,
    },
    {
      title: "Provider",
      dataIndex: ["provider", "name"],
      key: "provider",
      render: (name) => {
        const ProviderName = name ? name : "-";
        return ProviderName;
      },
      ellipsis: true,
    },
  ];

  return (
    <Card
      extra={
        <NavigateLink icon="add" to="/news/rssfeed/new">
          Add RSS Feed
        </NavigateLink>
      }
      title={<Text typography="h6">Rss Feeds</Text>}
    >
      {RssFeeds && (
        <Table
          columns={columns}
          dataSource={RssFeeds}
          pagination={false}
          rowKey={(item) => item.id}
          size="small"
        />
      )}
    </Card>
  );
}

export { RssFeedList };
