import { mapPropsToBoxShadow } from "./box-shadow";
import { mapPropsToOpacity } from "./opacity";
import { css } from "styled-components";

// merge multiple style functions into 1 (the layout module)
const effects = css`
  ${mapPropsToBoxShadow};
  ${mapPropsToOpacity};
`;

export { mapPropsToBoxShadow, mapPropsToOpacity };

export default effects;
