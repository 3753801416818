import PropTypes from "prop-types";
import React from "react";

import { getColor } from "../../style/index";
import { Icon } from "./Icon";

function FilterIcon({ filtered }) {
  return (
    <Icon
      fs="1.3x"
      style={{ color: filtered ? getColor("primary.main") : undefined }}
    >
      search
    </Icon>
  );
}

FilterIcon.propTypes = {
  filtered: PropTypes.bool,
};

export { FilterIcon };
