import { get } from "lodash";
import { css } from "styled-components";
import { SIZES } from "./sizes";

const MIN_HEIGHTS = {
  ...SIZES,
};

export function mapPropsToMinHeight(props) {
  const value = get(props, "min-height");

  if (value) {
    return css`
      min-height: ${get(MIN_HEIGHTS, value, value)};
    `;
  }
}
