import { Tag } from "antd";
import React from "react";

import { colors, Icon, Space } from "@gfw/corvus";
import { FlexBox, Text } from "@gfw/orion";

export function UserSearchResult({ ...user }) {
  return (
    <FlexBox>
      <Space>
        <Icon fs="1.5em" type="user" />
        <FlexBox orientation="vertical">
          <Text typography="subtitle-2 icon">
            <Space size="small">
              {user.name}
              {user.status === "INACTIVE" && (
                <Tag color={colors.disabled}>Inactive</Tag>
              )}
            </Space>
          </Text>
          <Text opacity={0.9} typography="caption">
            {user.email}
          </Text>
        </FlexBox>
      </Space>
    </FlexBox>
  );
}
