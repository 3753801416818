import { get } from "lodash";
import { css } from "styled-components";

export const LINE_HEIGHTS = {
  normal: "normal",
  none: 1,
  body: 1.5,
  short: 1.375,
  shorter: 1.25,
  tall: 1.625,
  taller: 2,
};

export function mapPropsToLineHeight(props) {
  const value = get(props, "line-height");

  if (value) {
    return css`
      line-height: ${get(LINE_HEIGHTS, value, value)};
    `;
  }
}
