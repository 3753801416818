import { include, mixin } from "../../style";
import styled, { css } from "styled-components";

const Box = styled("div")`
  position: relative;
  padding: 0;
  margin: 0;
  border-radius: 2px;
  ${include("display")};
  ${include("border")};
  ${include("height")};
  ${include("flexbox")};
  ${include("space")};
  ${include("color")};
  ${include("bg")};
  ${include("flex-item")};
  ${include("typography")};
  ${include("layout")};
  ${include("interactivity")};
  ${include("effects")};

  ${include("sizing")};
  ${include("width")};
  ${include("pseudo")};
  ${include("grid")};

  ${include("variants")};

  ${mixin("$transform")};
  ${mixin(
    "$css",
    (style) => css`
      ${style}
    `,
  )};
`;

export { Box };
