import React from "react";
import { Input } from "../Input";

const TextArea = React.forwardRef(function TextArea({ resize, ...props }, ref) {
  return <Input as="textarea" style={{ resize }} ref={ref} {...props} />;
});

TextArea.defaultProps = {
  "line-height": "short",
  "min-height": "5rem",
  "max-width": "full",
  py: 2,
  resize: "vertical",
};

export { TextArea };
