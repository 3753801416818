import { ComboBox } from "./ComboBox";
import { ComboBoxInput } from "./ComboBoxInput";
import { ComboBoxOverlay } from "./ComboBoxOverlay";
import { ComboBoxList } from "./ComboBoxList";
import { ComboBoxOption } from "./ComboBoxOption";

ComboBox.Input = ComboBoxInput;
ComboBox.Overlay = ComboBoxOverlay;
ComboBox.List = ComboBoxList;
ComboBox.Option = ComboBoxOption;

export { ComboBox };
