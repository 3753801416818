import { Observer, useLocalObservable } from "mobx-react";
import { useNavigate } from "react-router-dom";
import React from "react";

import {
  __DEPRECATED__ErrorHandler,
  Button,
  Card,
  notification,
} from "@gfw/corvus";
import { apiClient } from "@gfw/backend-connector";

import { Page, PageHeader } from "@src/resources/generic/components";
import { RssFeedForm } from "@src/resources/News/components";

function AddRssFeedPage() {
  const navigate = useNavigate();

  const feed = useLocalObservable(() => ({
    url: "",
    provider: null,

    update(property, value) {
      this[property] = value;
    },

    getSnapshot() {
      return {
        ...JSON.parse(JSON.stringify(this)),
        provider: this.provider.id,
      };
    },

    get valid() {
      const URL = new RegExp(
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
      );
      return this.url && this.url.match(URL) && this.provider;
    },
    get invalid() {
      return !this.valid;
    },
  }));

  const createRssFeed = async () => {
    try {
      const response = await apiClient.post(
        `/news/rssfeed`,
        feed.getSnapshot(),
      );
      notification.success({ message: `The RSS feed has been added!` });
      navigate(`/news/rssfeed/${response.data._id}`);
    } catch (error) {
      __DEPRECATED__ErrorHandler(error);
    }
  };

  return (
    <Page>
      <PageHeader iconType="news">Add a RSS feed</PageHeader>
      <Card>
        <Card.Body style={{ width: "50%" }}>
          <RssFeedForm feed={feed} />
        </Card.Body>
        <Observer>
          {() => (
            <Button
              disabled={feed.invalid}
              mt="lg2"
              onClick={createRssFeed}
              type="primary"
            >
              CREATE
            </Button>
          )}
        </Observer>
      </Card>
    </Page>
  );
}

export { AddRssFeedPage };
