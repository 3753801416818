import { pick, get } from "lodash";

function getStyleValue(prop, value) {
  switch (prop) {
    case "orientation":
      return get({ vertical: "column", horizontal: "row" }, value, "row");

    default:
      return value;
  }
}

// TODO:
// when justify-self" is either left|right
// [`margin-${value === "right" ? "left" : "right"}`]: "auto",

// when justify-self" is center
// {
//    margin-left: auto
//    margin-right: auto
// }

export function mapPropsToFlexItem(props) {
  const flexKeys = ["flex"];
  const properties = pick(props, ["justify-self", "align-self", ...flexKeys]);

  // translates
  // * justify-self: "right|left" => margin-[left|right]: auto
  // * align-self => align-self: value

  const mapFlexItemPropToCssDeclaration = (prop, value) => {
    switch (prop) {
      case "justify-self":
        // TODO: we should really fix this as it clashes with grid items
        // this is just a little hack
        if (["right", "left"].includes(value)) {
          return { [`margin-${value === "right" ? "left" : "right"}`]: "auto" };
        } else {
          return {};
        }
      default:
        return { [prop]: value };
    }
  };

  return Object.entries(properties).reduce((prev, [prop, value]) => {
    if (flexKeys.includes(prop)) {
      return {
        ...prev,
        [prop]: value,
      };
    }
    return {
      ...prev,
      ...mapFlexItemPropToCssDeclaration(prop, value),
    };
  }, {});
}

export function mapPropsFlexBox(props) {
  // TODO: we should move things that deal with alignment to modules/box-alignment like tailwind does it since these affect both flex and grid.
  const properties = pick(props, [
    "align-items",
    "justify-content",
    "flex-direction",
    "flex-wrap",
    "orientation",
  ]);

  return Object.entries(properties).reduce(
    (prev, [prop, value]) => ({
      ...prev,
      [get({ orientation: "flex-direction" }, prop, prop)]: getStyleValue(
        prop,
        value,
      ),
    }),
    {},
  );
}
