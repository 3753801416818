import { css } from "styled-components";
import { mixin } from "../../utils";

const mixins = [
  mixin("cursor"),
  mixin("outline"),
  mixin("user-select"),
  mixin("pointer-events"),
];

export { mixins };

export default css`
  ${mixins};
`;
