import { Observer, useLocalObservable } from "mobx-react";
import { useNavigate } from "react-router-dom";
import React from "react";

import {
  __DEPRECATED__ErrorHandler,
  Button,
  Card,
  notification,
} from "@gfw/corvus";
import { apiClient } from "@gfw/backend-connector";

import { NewsProviderForm } from "@src/resources/News/components";
import { newsProviderModel } from "@src/resources/News/utils";
import { Page, PageHeader } from "@src/resources/generic/components";

function AddNewsProviderPage() {
  const navigate = useNavigate();

  const provider = useLocalObservable(() => newsProviderModel);

  const createNewsProviderHandler = async () => {
    try {
      const response = await apiClient.post(
        `/news/providers`,
        provider.getSnapshot(),
      );
      notification.success({ message: `The provider has been created!` });
      navigate(`/news/providers/${response.data.id}`);
    } catch (error) {
      __DEPRECATED__ErrorHandler(error);
    }
  };

  return (
    <Page>
      <PageHeader iconType="news">Create News Provider</PageHeader>
      <Card>
        <Card.Body style={{ width: "50%" }}>
          <NewsProviderForm provider={provider} />
        </Card.Body>
        <Observer>
          {() => (
            <Button
              disabled={provider.invalid}
              mt="lg2"
              onClick={createNewsProviderHandler}
              type="primary"
            >
              CREATE
            </Button>
          )}
        </Observer>
      </Card>
    </Page>
  );
}

export { AddNewsProviderPage };
