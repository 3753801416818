import React from "react";
import { Machine } from "xstate";
import { useMachine } from "@xstate/react";
import { Box } from "../Box";
import { getOwnerDocument, useNextId } from "../../core";

export const event = {
  FOCUS: "FOCUS",
  CHANGE: "CHANGE",
  CLOSE: "CLOSE",
  SELECT: "SELECT",
  NAVIGATE: "NAVIGATE",
};

const machine = Machine(
  {
    id: "combobox",
    initial: "idle",
    states: {
      idle: {
        on: {
          [event.FOCUS]: [
            {
              target: "suggesting",
              cond: "shouldSuggestOnFocus",
            },
            {
              target: "interacting",
            },
          ],
          [event.NAVIGATE]: "suggesting",
          [event.CHANGE]: "suggesting",
        },
      },
      suggesting: {
        on: {
          [event.CHANGE]: {}, // do nothing...just stay in this state
          [event.NAVIGATE]: {}, // do nothing...just stay in this state
          [event.SELECT]: "idle",
        },
      },
      // this just acts like a "temporary" state when we can't go directly to suggesting (maybe there is a better way??)
      interacting: {
        on: {
          [event.CHANGE]: "suggesting",
          [event.NAVIGATE]: "suggesting",
        },
      },
    },
    on: {
      [event.CLOSE]: "idle",
    },
  },
  {},
);

// TODO: Move all things related to Descendants to its own thing later on
function noop() {}
function createDescendantsContext(initialValue = {}) {
  return React.createContext({
    descendants: [],
    registerDescendant: noop,
    unregisterDescendant: noop,
    ...initialValue,
  });
}
function useDescendants() {
  return React.useState([]);
}
function DescendantsProvider({ context: Context, items, set, children }) {
  const registerDescendant = React.useCallback(({ element, ...args }) => {
    if (!element) {
      return;
    }

    set((items) => {
      let newItem;
      let newItems;
      // if we have no items, just register the descendant at index 0
      if (items.length === 0) {
        newItem = {
          element,
          index: 0,
          ...args,
        };
        newItems = [...items, newItem];
      } else if (items.find((item) => item.element === element)) {
        // the element is already registered
        newItems = items;
      } else {
        let index = items.findIndex((item) => {
          if (!item.element || !element) {
            return false;
          }

          return Boolean(
            item.element.compareDocumentPosition(element) &
              Node.DOCUMENT_POSITION_PRECEDING,
          );
        });

        newItem = {
          element,
          index,
          ...args,
        };

        if (index === -1) {
          // if an index is not found, push it to the end
          newItems = [...items, newItem];
        } else {
          // push the item into the correct index
          newItems = [...items.slice(0, index), newItem, ...items.slice(index)];
        }
      }
      return newItems.map((item, index) => ({ ...item, index }));
    });
    // set is a state setter initialized by the useDescendants hook. (React.useState)...it's safe to ignore...it won't change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const unregisterDescendant = React.useCallback(
    (element) => {
      if (!element) {
        return;
      }

      set((items) => items.filter((item) => element !== item.element));
    },
    // set is a state setter initialized by the useDescendants hook. (React.useState)...it's safe to ignore...it won't change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const value = React.useMemo(() => {
    return {
      descendants: items,
      registerDescendant,
      unregisterDescendant,
    };
  }, [items, registerDescendant, unregisterDescendant]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
}
export function useDescendant({ context, element, ...args }) {
  const {
    registerDescendant,
    unregisterDescendant,
    descendants,
  } = React.useContext(context);

  React.useLayoutEffect(() => {
    registerDescendant({ element, ...args });

    return () => unregisterDescendant(element);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [element, ...Object.values(args)]);

  return descendants.findIndex((item) => item.element === element);
}
// END Descendants

const ComboBoxContext = React.createContext();
export const ComboBoxDescendantsContext = createDescendantsContext();

function useComboBox() {
  return React.useContext(ComboBoxContext);
}

function useOnKeyDownEventHandler() {
  const { dispatch, isSuggesting, selectedValue, onSelect } = useComboBox();
  const { descendants: options } = React.useContext(ComboBoxDescendantsContext);

  return function handler(e) {
    let index = options.findIndex(({ value }) => value === selectedValue);
    function getFirstOption() {
      return options[0];
    }
    function getLastOption() {
      return options[options.length - 1];
    }
    function getNextOption() {
      let atBottom = index === options.length - 1;
      if (atBottom) {
        return getFirstOption();
      } else {
        // Go to the next item in the list
        return options[(index + 1) % options.length];
      }
    }

    function getPreviousOption() {
      let atTop = index === 0;
      if (atTop) {
        return getLastOption();
      } else if (index === -1) {
        // displaying the user's value, so go select the last one
        return getLastOption();
      } else {
        // normal case, select previous
        return options[(index - 1 + options.length) % options.length];
      }
    }

    // for getting key codes see this: https://keycode.info/
    switch (e.key) {
      case "ArrowDown":
        e.preventDefault();
        dispatch(event.NAVIGATE);
        if (isSuggesting && options.length) {
          let next = getNextOption();
          onSelect(next.value);
        }
        break;
      case "ArrowUp":
        e.preventDefault();
        dispatch(event.NAVIGATE);
        if (isSuggesting && options.length) {
          let previous = getPreviousOption();
          onSelect(previous.value);
        }
        break;
      case "Escape":
        dispatch(event.CLOSE);
        break;
      case "Enter":
        e.preventDefault();
        if (index !== -1) {
          // this mimics a "click" event on an option, e.g. onSelect + send event
          onSelect(options[index].value);
          dispatch(event.SELECT);
        }
        break;
    }
  };
}

function useOnBlurEventHandler() {
  const { state, dispatch, overlayRef, inputRef } = useComboBox();

  return function handler() {
    const ownerDocument = getOwnerDocument(inputRef.current) || document;
    requestAnimationFrame(() => {
      // we only want to "blur" if focus is outside the of the combobox
      if (
        ownerDocument.activeElement !== inputRef.current &&
        overlayRef.current
      ) {
        if (overlayRef.current.contains(ownerDocument.activeElement)) {
          // focus is still inside the combobox, keep it active
          if (state.value !== "interacting") {
            //dispatch(INTERACT); // TODO...
          }
        } else {
          // focus is outside the combobox, close it.
          dispatch(event.CLOSE);
        }
      }
    });
  };
}

const ComboBox = React.forwardRef(function ComboBox(
  { onSelect, onBlur, onStateChange, shouldOpenOnFocus, children, ...props },
  ref,
) {
  const lastEventType = React.useRef();
  const [state, dispatch, service] = useMachine(machine, {
    guards: {
      shouldSuggestOnFocus: () =>
        shouldOpenOnFocus && lastEventType.current !== event.SELECT,
    },
  });
  const inputRef = React.useRef();
  const overlayRef = React.useRef();
  const listboxId = useNextId("listbox");
  const [value, setValue] = React.useState(null);
  const [options, setOptions] = useDescendants();

  React.useEffect(() => {
    const subscription = service.subscribe((state) => {
      if (state.changed) {
        if (state.event.type === event.SELECT) {
          inputRef.current.focus();
        }
        if (state.event.type === event.CLOSE) {
          onBlur && onBlur();
        }
        lastEventType.current = state.event.type;
      }
    });

    return subscription.unsubscribe;
  }, [onBlur, service]);

  React.useEffect(() => {
    onStateChange &&
      onStateChange({
        isSuggesting: state.matches("suggesting"), // TODO: align with ctx
      });
  }, [onStateChange, state]);

  const onSelectHandler = (value) => {
    onSelect && onSelect(value);
    setValue(value);
  };

  const context = {
    onSelect: onSelectHandler,
    inputRef,
    overlayRef,
    isSuggesting: state.matches("suggesting"),
    selectedValue: value,
    listboxId,
    service,
    state,
    dispatch,
  };

  return (
    <DescendantsProvider
      context={ComboBoxDescendantsContext}
      items={options}
      set={setOptions}
    >
      <ComboBoxContext.Provider value={context}>
        <Box ref={ref} {...props}>
          {children}
        </Box>
      </ComboBoxContext.Provider>
    </DescendantsProvider>
  );
});

export {
  ComboBox,
  useComboBox,
  useOnBlurEventHandler,
  useOnKeyDownEventHandler,
};
