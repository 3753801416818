import { Select, Space } from "antd";
import PropTypes from "prop-types";
import React from "react";

import { Button } from "../Button/Button";
import { FlexBox } from "../FlexBox/FlexBox";
import { Text } from "../Text/Text";

const { Option } = Select;

function ListControls(props) {
  const {
    hasNextPage,
    hasPreviousPage,
    goNextPage,
    goPreviousPage,
    setNumItems,
    numItems,
    rowOptions,
    rowText,
    isPreviousData,
  } = props;

  function onSelectClick(option) {
    setNumItems(option);
  }

  function onPreviousClick() {
    goPreviousPage();
  }
  function onNextClick() {
    goNextPage();
  }

  const text = rowText || "Rows per page";

  return (
    <FlexBox align-items="center">
      {rowOptions && (
        <Space size="small">
          <Text fs={1}>{text}</Text>
          <Select value={numItems} onSelect={onSelectClick} size="sm">
            {rowOptions.map((rowOption) => (
              <Option key={rowOption} value={rowOption}>
                {rowOption}
              </Option>
            ))}
          </Select>
        </Space>
      )}
      <Button
        iconFs={24}
        icon="caret-left"
        disabled={!hasPreviousPage || isPreviousData}
        onClick={onPreviousClick}
      />
      <Button
        iconFs={24}
        icon="caret-right"
        disabled={!hasNextPage || isPreviousData}
        onClick={onNextClick}
      />
    </FlexBox>
  );
}

ListControls.propTypes = {
  hasNextPage: PropTypes.bool,
  hasPreviousPage: PropTypes.bool,
  goNextPage: PropTypes.func,
  goPreviousPage: PropTypes.func,
  setNumItems: PropTypes.func,
  numItems: PropTypes.number,
  rowOptions: PropTypes.array,
  rowText: PropTypes.string,
  isPreviousData: PropTypes.bool,
};

export { ListControls };
