import { PAGE_SIZE } from "../../core/pageSize";
import { useSetState } from "../../core/useSetState";

function useCursorPagination(options) {
  const pageSizeInitial = options?.pageSizeOptions || PAGE_SIZE;
  const data = options?.data || {};

  const [pagination, setPagination] = useSetState({
    pageSize: pageSizeInitial[0],
    pageSizeOptions: pageSizeInitial,
    first: pageSizeInitial[0],
    last: null,
    after: null,
    before: null,
    data,
  });

  function goNextPage() {
    const { endCursor } = pagination;

    setPagination({
      last: null,
      before: null,
      first: pagination.pageSize,
      after: endCursor,
    });
  }

  function goPreviousPage() {
    const { startCursor } = pagination;

    setPagination({
      before: startCursor,
      last: pagination.pageSize,
      first: null,
      after: null,
    });
  }

  function setPageSize(value) {
    setPagination({
      pageSize: value,
      first: pagination.pageSize,
      last: null,
      after: null,
      before: null,
    });
  }

  function setPageInfo(pageInfo) {
    setPagination({ ...pageInfo });
  }

  function resetCursor(newData = {}) {
    setPagination({
      first: pagination.pageSize,
      last: null,
      after: null,
      before: null,
      data: { ...pagination.data, ...newData },
    });
  }

  function getGraphVariables() {
    return {
      first: !pagination.before ? pagination.pageSize : null, //Default to first
      last: pagination.before ? pagination.pageSize : null,
      after: pagination.after,
      before: pagination.before,
      ...pagination.data,
    };
  }

  function getDataValue(key) {
    return pagination?.data?.[key];
  }

  return {
    ...pagination,
    goNextPage,
    goPreviousPage,
    setPageSize,
    setPageInfo,
    getGraphVariables,
    resetCursor,
    getDataValue,
  };
}

export { useCursorPagination };
