import { useQuery } from "react-query";
import React from "react";

import { apiClient } from "@gfw/backend-connector";
import { Card, Grid } from "@gfw/corvus";
import { Text } from "@gfw/orion";

import { StyledFlex } from "./DueDiligenceAnalytics.styles";

export function DueDiligenceAnalytics() {
  const { data, isLoading } = useQuery("due-diligence-analytics", () => {
    return apiClient.get("/admin/due-diligence-count");
  });

  return (
    <Card
      isLoading={isLoading}
      size="small"
      title={
        <StyledFlex>
          <Text fs="md">Due Diligence</Text>
          <Text fs="md">Count</Text>
        </StyledFlex>
      }
    >
      <Grid.Row gutter={[0, 16]}>
        <Grid.Col span={24}>
          <StyledFlex>
            <Text>Total Campaigns</Text>
            <Text>{data?.data?.totalCampaigns}</Text>
          </StyledFlex>
        </Grid.Col>
        <Grid.Col span={24}>
          <StyledFlex>
            <Text>Total Completed Campaigns</Text>
            <Text>{data?.data?.totalCompletedCampaigns}</Text>
          </StyledFlex>
        </Grid.Col>
        <Grid.Col span={24}>
          <StyledFlex>
            <Text>Total Requests</Text>
            <Text>{data?.data?.totalRequests}</Text>
          </StyledFlex>
        </Grid.Col>
        <Grid.Col span={24}>
          <StyledFlex align="center" justify="space-between">
            <Text>Total Reports</Text>
            <Text>{data?.data?.totalReports}</Text>
          </StyledFlex>
        </Grid.Col>
        <Grid.Col span={24}>
          <StyledFlex align="center" justify="space-between">
            <Text>Total Completed Reports</Text>
            <Text>{data?.data?.totalCompletedReports}</Text>
          </StyledFlex>
        </Grid.Col>
      </Grid.Row>
    </Card>
  );
}
