import { Select } from "antd";
import React from "react";

import { Button, Space } from "@gfw/corvus";
import { FlexBox, Text } from "@gfw/orion";

const { Option } = Select;

function ListControls({
  pageInfo,
  itemsPerPageState,
  onNext,
  onPrevious,
  rowOptions,
}) {
  const [itemsPerPage, setItemsPerPage] = itemsPerPageState;

  const { hasNextPage, hasPreviousPage } = pageInfo;
  const enablePrevious = hasPreviousPage;
  const enableNext = hasNextPage;

  return (
    <FlexBox align-items="center">
      {rowOptions && (
        <Space size="small">
          <Text fs={1}>Rows per page</Text>
          <Select onSelect={setItemsPerPage} size="sm" value={itemsPerPage}>
            {rowOptions.map((rowOption) => (
              <Option key={rowOption} value={rowOption}>
                {rowOption}
              </Option>
            ))}
          </Select>
        </Space>
      )}
      <Button
        disabled={!enablePrevious}
        icon="arrowLeft"
        iconFs={24}
        onClick={onPrevious}
      />
      <Button
        disabled={!enableNext}
        icon="arrowRight"
        iconFs={24}
        onClick={onNext}
      />
    </FlexBox>
  );
}

export { ListControls };
