import { Observer, useLocalObservable } from "mobx-react";
import { useNavigate } from "react-router-dom";
import React from "react";

import {
  __DEPRECATED__ErrorHandler,
  Button,
  Card,
  notification,
} from "@gfw/corvus";
import { apiClient } from "@gfw/backend-connector";

import { NewsArticleForm } from "@src/resources/News/components";
import { newsArticleModel } from "@src/resources/News/utils";
import { Page, PageHeader } from "@src/resources/generic/components";

function AddNewsArticlePage() {
  const navigate = useNavigate();

  const article = useLocalObservable(() => newsArticleModel);

  const createNewsArticle = async () => {
    try {
      const response = await apiClient.post(
        `/news/articles`,
        article.getSnapshot(),
      );
      notification.success({ message: `The article has been created!` });
      navigate(`/news/articles/${response.data.id}`);
    } catch (error) {
      __DEPRECATED__ErrorHandler(error);
    }
  };

  return (
    <Page>
      <PageHeader iconType="news">Create News Article</PageHeader>
      <Card>
        <NewsArticleForm article={article} />
        <Observer>
          {() => (
            <Button
              disabled={article.invalid}
              mt="lg2"
              onClick={createNewsArticle}
              type="primary"
            >
              CREATE
            </Button>
          )}
        </Observer>
      </Card>
    </Page>
  );
}

export { AddNewsArticlePage };
