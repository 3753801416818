import { Route, Routes } from "react-router-dom";
import React from "react";

import MergeProfiles from "./MergeProfiles";
import NewProfilePage from "./NewProfilePage";
import ProfilePage from "./ProfilePage";
import ProfilesPage from "./ProfilesPage";

// This is the root module for all the profile routes (e.g. a route handler for /profiles)
function Index() {
  return (
    <Routes>
      <Route element={<NewProfilePage />} path="new" />
      <Route
        element={<MergeProfiles />}
        path="merge/:profileIdToRemove/:profileIdToKeep"
      />
      <Route element={<ProfilePage />} path=":id/*" />
      <Route element={<ProfilesPage />} path="*" />
    </Routes>
  );
}

export default Index;
