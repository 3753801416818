import { Button } from "antd";
import React, { useEffect, useState } from "react";

import { Box, FlexBox, QuestionnaireProvider, Text } from "@gfw/orion";
import { isArray, isEqual, isObject, uniq } from "lodash";

const setValues = (questionnaire, answers = {}) => {
  for (const x in answers) {
    questionnaire = setValue(questionnaire, x, answers[x]);
  }
  return questionnaire;
};

const setValue = (questionnaire, key, answer) => {
  if (!isArray(questionnaire) && !isObject(questionnaire)) {
    return questionnaire;
  }
  if (questionnaire.key === key) {
    questionnaire.answer = answer;
  }

  if (questionnaire.categories) {
    questionnaire.categories.forEach((category) =>
      setValue(category, key, answer),
    );
  }
  if (questionnaire.questions) {
    questionnaire.questions.forEach((question) =>
      setValue(question, key, answer),
    );
  }
  if (questionnaire.choices) {
    questionnaire.choices.forEach((choice) => {
      if (choice.questions) {
        choice.question = setValue(
          { questions: choice.questions },
          key,
          answer,
        );
      }
    });
  }
  if (!isArray(questionnaire) && isObject(questionnaire)) {
    for (const x in questionnaire) {
      questionnaire[x] = setValue(questionnaire[x], key, answer);
    }
  }
  return questionnaire;
};

const getValues = (questionnaire, answers = {}) => {
  if (!isArray(questionnaire) && !isObject(questionnaire)) {
    return answers;
  }
  if (questionnaire.answer) {
    if (questionnaire.type == "choice" && questionnaire.isMultipleChoice) {
      answers[questionnaire.key] = isArray(questionnaire.answer)
        ? questionnaire.answer
        : [questionnaire.answer];
    } else {
      if (
        (isArray(questionnaire.answer) && questionnaire.answer.length) ||
        !isArray(questionnaire.answer)
      ) {
        answers[questionnaire.key] = questionnaire.answer;
      }
    }
  }

  if (questionnaire.categories) {
    for (const category of questionnaire.categories) {
      const partialAnswers = getValues(category);
      answers = { ...answers, ...partialAnswers };
    }
  }
  if (questionnaire.questions) {
    for (const question of questionnaire.questions) {
      const partialAnswers = getValues(question);
      answers = { ...answers, ...partialAnswers };
    }
  }
  if (questionnaire.choices) {
    for (const choice of questionnaire.choices) {
      if (choice.questions) {
        const partialAnswers = getValues({ questions: choice.questions });
        answers = { ...answers, ...partialAnswers };
      }
    }
  }
  if (!isArray(questionnaire) && isObject(questionnaire)) {
    for (const x in questionnaire) {
      const partialAnswers = getValues(questionnaire[x]);
      answers = { ...answers, ...partialAnswers };
    }
  }
  return answers;
};

const categoriesArrayToObject = (questionnaireCategories) => {
  const obj = {};

  questionnaireCategories.forEach((cat) => (obj[cat.id] = cat));
  return obj;
};

function QuestionnaireConflictsResolver({
  profileToRemove,
  profileToKeep,
  nextStep,
  previousStep,
  setInformation,
  informationToKeep,
}) {
  const [idx, setIdx] = useState(0);
  const [questionnaire, setQuestionnaire] = useState({});
  const [categoriesIds, setCategoriesIds] = useState([]);

  useEffect(() => {
    const catIds = [];
    const profileToRemoveQuestionnaire = categoriesArrayToObject(
      profileToRemove.questionnaire.categories,
    );
    const profileToKeepQuestionnaire = categoriesArrayToObject(
      profileToKeep.questionnaire.categories,
    );
    Object.keys(profileToRemoveQuestionnaire).forEach((id) => {
      catIds.push(id);
      if (!profileToKeepQuestionnaire[id]) {
        profileToKeepQuestionnaire[id] = profileToRemoveQuestionnaire[id];
        profileToKeepQuestionnaire[id].warning =
          "Data copied from the profile to remove, please check the values";
      }
    });
    Object.keys(profileToKeepQuestionnaire).forEach((id) => {
      catIds.push(id);
    });
    setValues(profileToKeepQuestionnaire, informationToKeep);
    setCategoriesIds(uniq(catIds));
    // delta between info and the last question: update info
    const profileToKeepInfo = getValues(profileToKeepQuestionnaire);
    if (!isEqual(profileToKeepInfo, informationToKeep)) {
      for (const key in profileToKeepInfo) {
        if (
          JSON.stringify(profileToKeepInfo[key]) !==
          JSON.stringify(informationToKeep[key])
        ) {
          setInformation(key, profileToKeepInfo[key]);
        }
      }
    }
    setQuestionnaire({
      toKeep: profileToKeepQuestionnaire,
      toRemove: profileToRemoveQuestionnaire,
    });
  }, [
    profileToRemove,
    profileToKeep,
    setInformation,
    setQuestionnaire,
    setCategoriesIds,
    informationToKeep,
  ]);

  const catId = categoriesIds[idx];
  const profileToRemoveQuestions = (
    <QuestionnaireProvider isReadOnly={true} key={`category${idx}`}>
      {questionnaire?.toRemove?.[catId]?.questions.map(
        ({ key, ...question }) => (
          <Box key={key}>
            <QuestionnaireProvider.Question id={key} {...question} />
          </Box>
        ),
      )}
    </QuestionnaireProvider>
  );

  const profileToKeepQuestions = (
    <>
      {!!questionnaire?.toKeep?.[catId]?.warning && (
        <Text fs={4} style={{ color: "red" }}>
          WARNING: {questionnaire?.toKeep?.[catId]?.warning}
        </Text>
      )}
      <QuestionnaireProvider
        forceEditMode={true}
        key={`category${idx}`}
        onQuestionUpdated={(event) => {
          setInformation(event.id, event.answer);
        }}
      >
        {questionnaire?.toKeep?.[catId]?.questions?.map(
          ({ key, ...question }) => (
            <QuestionnaireProvider.Question
              id={key}
              key={key}
              {...question}
              isEditable={true}
              isReadOnly={false}
            />
          ),
        )}
      </QuestionnaireProvider>
    </>
  );

  return (
    <>
      <FlexBox>
        <Box w="50%">
          <Text color="#42a5ac" fs={3} fw="bold">{`${profileToRemove.name}\`s ${
            questionnaire?.toRemove?.[catId]?.name || "not defined"
          }`}</Text>
          <br />
          {profileToRemoveQuestions}
        </Box>
        <Box w="50%">
          <Text
            color="#42a5ac"
            fs={3}
            fw="bold"
          >{`${profileToKeep.name}\`s ${questionnaire?.toKeep?.[catId]?.name}`}</Text>
          <br />
          {profileToKeepQuestions}
        </Box>
      </FlexBox>
      <FlexBox justify="space-around" mt="xl">
        {previousStep && (
          <Button onClick={previousStep} width="150px">
            Previous
          </Button>
        )}
        <Button
          onClick={() => {
            idx < categoriesIds.length - 1 ? setIdx(idx + 1) : nextStep();
            window.scrollTo(0, 0);
          }}
          type="primary"
          width="150px"
        >
          Next
        </Button>
      </FlexBox>
    </>
  );
}

export default QuestionnaireConflictsResolver;
