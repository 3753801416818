import { assign, Machine } from "xstate";
import { useMachine } from "@xstate/react";
import React from "react";

import { apiClient } from "@gfw/backend-connector";

const State = {
  Idle: "IDLE",
  // we're editing the questionnaire
  Editing: "EDITING",
  // we're submitting the changes
  Submitting: "SUBMITTING",
};

const Event = {
  EDIT: "EDIT",
  UPDATE: "UPDATE",
  CANCEL: "CANCEL",
  SUBMIT: "SUBMIT",
};

const machine = Machine({
  id: "questionnaire",
  initial: State.Idle,
  states: {
    [State.Idle]: {
      // we'll always start with a clean changelog
      entry: assign((context) => {
        context.changes = {};
      }),
      on: {
        [Event.EDIT]: {
          target: State.Editing,
        },
      },
    },
    [State.Editing]: {
      on: {
        [Event.SUBMIT]: {
          target: State.Submitting,
        },
        [Event.UPDATE]: {
          actions: assign(
            (context, event) =>
              // when we get a change store that locally so we know what to submit later on
              (context.changes[event.question.id] = event.question.answer),
          ),
        },
      },
    },
    [State.Submitting]: {
      invoke: {
        src: "onUpdateProfileInformation",
        onDone: {
          target: State.Idle,
          actions: "onProfileInformationUpdated",
        },
      },
    },
  },
  on: {
    [Event.CANCEL]: {
      target: State.Idle,
    },
  },
});

function useQuestionnaire({
  onProfileInformationUpdated,
  forceRefetchProfileContext,
}) {
  const [state, dispatch] = useMachine(machine, {
    services: {
      onUpdateProfileInformation: async (context) => {
        await apiClient.put(`/profile/information`, context.changes);
        // HACK: current legacy questionnaire is using profile context to prefill dd request even it was already replied
        // when you edit and save, it updates the profile, but the profile is not updated:/
        forceRefetchProfileContext?.();
        return context.changes;
      },
    },
    actions: {
      onProfileInformationUpdated: (_context, event) => {
        onProfileInformationUpdated(event.data);
      },
    },
  });

  const onCancelEdit = React.useCallback(() => {
    dispatch({
      type: Event.CANCEL,
    });
  }, [dispatch]);

  const onSubmit = React.useCallback(() => {
    dispatch({
      type: Event.SUBMIT,
    });
  }, [dispatch]);

  const onEdit = React.useCallback(() => {
    dispatch({
      type: Event.EDIT,
    });
  }, [dispatch]);

  const onQuestionUpdated = React.useCallback(
    (question) => {
      dispatch({
        type: Event.UPDATE,
        question,
      });
    },
    [dispatch],
  );

  const isEditing = React.useMemo(() => {
    return [State.Editing, State.Submitting].includes(state.value);
  }, [state.value]);

  // props that we send to the Questionnaire component
  const getQuestionnaireProps = () => ({
    onQuestionUpdated,
    key: isEditing ? 1 : Date.now(), // HACK: this forces the Questionnaire to re-render when we go in and out of edit mode
    // double hack, return Date.now() to rerender answers modified :/
  });

  return {
    onCancelEdit,
    onEdit,
    onSubmit,
    isEditing,
    getQuestionnaireProps,
  };
}

export { useQuestionnaire };
