import { Route, Routes } from "react-router-dom";
import React from "react";

import {
  AddNewsArticlePage,
  AddNewsProviderPage,
  AddRssFeedPage,
  EditNewsArticlePage,
  EditNewsProviderPage,
  EditRssFeedPage,
  NewsPage,
} from "./components";

function Index() {
  return (
    <Routes>
      <Route element={<AddNewsArticlePage />} path="articles/new" />
      <Route element={<EditNewsArticlePage />} path="articles/:id/*" />
      <Route element={<AddNewsProviderPage />} path="providers/new" />
      <Route element={<EditNewsProviderPage />} path="providers/:id/*" />
      <Route element={<AddRssFeedPage />} path="rssfeed/new" />
      <Route element={<EditRssFeedPage />} path="rssfeed/:id/*" />

      <Route element={<NewsPage />} path="*" />
    </Routes>
  );
}

export default Index;
