import { Flex } from "antd";
import styled from "styled-components";

import { FONT_SIZES, FONT_WEIGHTS } from "@gfw/corvus";

export const StyledTitle = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  line-height: 1.57;
  letter-spacing: 0.00714em;
  font-size: ${FONT_SIZES.sm};
  font-weight: ${FONT_WEIGHTS.medium};
`;

export const StyledFlex = styled(Flex)`
  min-width: 0;
`;

export const StyledSubTitle = styled.p`
  white-space: inherit;
  line-height: 1.6;
  letter-spacing: 0.03em;
  font-size: ${FONT_SIZES.xs};
  font-weight: ${FONT_WEIGHTS.regular};
  margin: 0;
  opacity: 0.9;
`;

export const StyledProfilValue = styled.p`
  white-space: inherit;
  line-height: 1.57;
  letter-spacing: 0.00714em;
  font-size: ${FONT_SIZES.md};
  font-weight: ${FONT_WEIGHTS.medium};
  margin: 0;
  margin-left: auto;
`;
