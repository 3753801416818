import React from "react";
import { Box } from "../Box";
import { useListBox, ListBoxEvent } from "./ListBox";

function getListBoxOptionStyle({ color }) {
  return {
    color: `${color}.500`,
    px: "16px",
    py: "8px",
    outline: "none",
    pseudo: {
      hover: {
        bg: `${color}.100`,
      },
    },
  };
}

const ListBoxOption = React.forwardRef(function Component(
  { children, value, color, ...props },
  forwardedRef,
) {
  const { dispatch, onChange } = useListBox();

  const styleProps = getListBoxOptionStyle({
    color: color === "neutral" ? "gray" : color,
  });

  function onClickHandler() {
    dispatch({
      type: ListBoxEvent.OPTION_MOUSE_DOWN,
      value,
    });

    onChange && onChange(value);
  }

  return (
    <Box
      ref={forwardedRef}
      as="li"
      role="option"
      tabIndex={-1}
      onClick={onClickHandler}
      {...styleProps}
      {...props}
    >
      {children}
    </Box>
  );
});

// default styling
ListBoxOption.defaultProps = {
  cursor: "pointer",
  color: "neutral",
};

export { ListBoxOption };
