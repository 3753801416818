import { has } from "lodash";
import { mapPropsFlexBox, mapPropsToFlexItem } from "./flexbox";
import { mapPropsToBgColor, mapPropsToColor } from "./color";
import { mapPropsToDisplay } from "./layout";
import { mapPropsToHeight } from "./height";
import { mapPropsToPseudoSelector } from "./pseudo";
import { mapPropsToSpace } from "./space";
import { mapPropsToWidth } from "./width";
import effects from "./modules/effects";
import grid from "./modules/grid";
import interactivity from "./modules/interactivity";
import layout, { mapPropsToPosition } from "./modules/layout";
import mapPropsToBorder from "./border";
import sizing from "./modules/sizing";
import typography, {
  mapPropsToFontSize,
  mapPropsToFontWeight,
} from "./modules/typography";
import warning from "tiny-warning";

function mapPropsToHidden({ hidden }) {
  return (
    hidden && {
      display: "none",
    }
  );
}

// usage:: variants={[colors, layouts, ...]}
function mapPropsToVariants({ variants }) {
  if (variants) {
    return function (props) {
      return variants.reduce((prev, variant) => [...prev, variant(props)], []);
    };
  }
}

// list of styling mixins/modules that can be used like  ${include("mixin")};
const AVAILABLE_MIXINS = {
  // modules
  typography,
  layout,
  interactivity,
  effects,
  sizing,
  grid,

  // individual styling functions
  "font-size": mapPropsToFontSize,
  "font-weight": mapPropsToFontWeight,
  position: mapPropsToPosition,
  pseudo: mapPropsToPseudoSelector,

  // not migrated yet...
  width: mapPropsToWidth,
  height: mapPropsToHeight,
  color: mapPropsToColor,
  bg: mapPropsToBgColor,
  flexbox: mapPropsFlexBox,
  space: mapPropsToSpace,
  border: mapPropsToBorder,
  "flex-item": mapPropsToFlexItem,
  hidden: mapPropsToHidden,
  variants: mapPropsToVariants,
  display: mapPropsToDisplay,
};

export function include(mixin) {
  warning(
    has(AVAILABLE_MIXINS, mixin),
    `[@gfw/orion/style] Trying to use include("${mixin}") but that mixin/module was not found, check included mixins.`,
  );

  return AVAILABLE_MIXINS[mixin];
}
