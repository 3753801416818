import { Layout } from "antd";
import { Navigate, Route, Routes } from "react-router-dom";
import React from "react";
import styled from "styled-components";

import { IconsList } from "@gfw/corvus";
import AppBanner from "./AppBanner";
import Banner from "@src/resources/Banner/pages";
import CustomQuestionnaire from "@src/resources/CustomQuestionnaire";
import Logout from "@src/resources/Logout";
import NavBar from "./NavBar";
import News from "@src/resources/News";
import OverviewPage from "@src/resources/Overview/OverviewPage";
import Profiles from "@src/resources/Profile/pages";
import Requests from "@src/resources/Requests";
import SearchBar from "./SearchBar";
import StatsPage from "@src/resources/Stats";
import Users from "@src/resources/User/pages";

const HeaderBackground = styled(Layout.Header)`
  position: sticky;
  z-index: 10;
  width: 100%;
  top: 0;
  padding: 5px 20px;
`;

function AppHeader() {
  return (
    <HeaderBackground>
      <SearchBar />
    </HeaderBackground>
  );
}

function AuthenticatedRouter() {
  return (
    <Layout style={{ height: "100vh" }}>
      <NavBar />
      <Layout>
        <AppHeader />
        <AppBanner />
        <Layout.Content
          id="main-content"
          style={{
            padding: "24px",
            paddingBottom: "80px",
            overflowX: "auto",
            overflowY: "scroll",
          }}
        >
          <Routes>
            <Route element={<Profiles />} path="profiles/*" />
            <Route element={<Requests />} path="requests" />
            <Route element={<Users />} path="users/*" />
            <Route element={<Banner />} path="banner/*" />
            <Route element={<News />} path="news/*" />
            <Route element={<CustomQuestionnaire />} path="questionnaire/*" />
            <Route element={<OverviewPage />} path="overview" />
            <Route element={<StatsPage />} path="stats" />
            <Route element={<Logout />} path="logout" />
            <Route element={<IconsList />} path="/icons" />
            <Route element={<Navigate to="/overview" />} index />
          </Routes>
        </Layout.Content>
      </Layout>
    </Layout>
  );
}

export default AuthenticatedRouter;
