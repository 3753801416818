export function useForm(onSubmit) {
  // TODO: submitting should disable and possibly indicate that the form is submitting

  return {
    onSubmit(e) {
      if (e) {
        if (typeof e.preventDefault === "function") {
          e.preventDefault();
        }
        if (typeof e.stopPropagation === "function") {
          e.stopPropagation();
        }
      }

      try {
        onSubmit(e);
      } catch (error) {
        // TODO: setError(true) AND something with the error??
        // TEMP
        console.log("useForm:ERROR", error);
      }
    },
  };
}
