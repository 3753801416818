import { getIsMaybeRelativeSize } from "../utils";
import { has, pick } from "lodash";

/* Height Scale */
export const HEIGHTS = {
  1: "1rem",
  2: "2rem",
  3: "4rem",
  4: "8rem",
  5: "16rem",
};

const isScaleValue = (value) => has(HEIGHTS, value);

function transformHeightValue(value) {
  let h = Math.abs(value);
  if (isNaN(h)) {
    h = String(value);
    return getIsMaybeRelativeSize(h);
  } else {
    if (Number.isInteger(h)) {
      return h === 100 ? "100%" : `${h}px`;
    } else {
      return `${h * 100}%`;
    }
  }
}

export function mapPropsToHeight(props) {
  const properties = pick(props, ["h", "height"]);

  return Object.values(properties).reduce(
    (styles, value) => ({
      ...styles,
      height: getHeight(value),
    }),
    {},
  );
}

export function getHeight(value) {
  if (isScaleValue(value)) {
    return HEIGHTS[value];
  } else {
    return transformHeightValue(value);
  }
}
