import React from "react";
import { isObject, isString } from "lodash";

let id = 0;

export function generateNextId() {
  return ++id;
}

function getIdFromProps(props) {
  if (isObject(props)) {
    return "id" in props ? String(props.id) : undefined;
  } else {
    return undefined;
  }
}

/**
 * Joins strings to format IDs for compound components.
 *
 */
export function formatId(...args) {
  return args.filter((arg) => arg != null).join("--");
}

export function useNextId(options) {
  const initialId = getIdFromProps(options) || generateNextId();
  const [id] = React.useState(initialId);

  return id != null
    ? formatId(isString(options) ? options : null, id)
    : undefined;
}
