import { Flex } from "antd";
import { Link } from "react-router-dom";
import React from "react";
import styled from "styled-components";

import { Icon } from "@gfw/corvus";
import { ProfileTypes } from "@gfw/core";

import {
  StyledFlex,
  StyledProfilValue,
  StyledSubTitle,
  StyledTitle,
} from "./ProfileItem.styles";

export const ProfileItem = styled(function ({
  profile,
  profileValue,
  ...props
}) {
  const { name, id, location, types } = profile;

  return (
    <Link
      {...props}
      css={`
        color: inherit;
        overflow: hidden;
      `}
      to={`/profiles/${id}/details`}
    >
      <Flex align="center">
        <Icon fs="xl2" mr="sm" opacity={0.5} type="profile" />
        <StyledFlex vertical>
          <StyledTitle>{name}</StyledTitle>
          {types.length === 1 && (
            <StyledSubTitle>
              {ProfileTypes.byId(types[0])?.name} - {location}
            </StyledSubTitle>
          )}
          {types.length !== 1 && <StyledSubTitle>{location}</StyledSubTitle>}
        </StyledFlex>
        <StyledProfilValue>
          {!!profileValue && profileValue(profile)}
        </StyledProfilValue>
      </Flex>
    </Link>
  );
})`
  display: flex;
  flex-direction: column;
  white-space: nowrap;
`;
