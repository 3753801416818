import { Table, Tag } from "antd";
import { useMutation } from "react-query";
import dayjs from "dayjs";
import React, { useEffect } from "react";

import {
  __DEPRECATED__ErrorHandler,
  Card,
  NavigateLink,
  Space,
} from "@gfw/corvus";
import { apiClient } from "@gfw/backend-connector";
import { BASE_SORT_COLUMN, normalizeString } from "@gfw/core";

import { Page, PageHeader } from "@src/resources/generic/components";
import { StyledFlex, StyledText } from "./CustomQuestionnaireList.styles";

function CustomQuestionnaireList() {
  const {
    mutateAsync: getQuestionnaires,
    data: questionnaires = [],
    isLoading,
  } = useMutation(
    async () => {
      const response = await apiClient.get(`/questionnaire/all`);
      return response.data.questionnaires;
    },
    {
      onError: __DEPRECATED__ErrorHandler,
    },
  );

  const antdColumns = [
    {
      ...BASE_SORT_COLUMN,
      title: "Title",
      dataIndex: "label",
      key: "label",
      ellipsis: true,
      render: (name, questionnaire) => (
        <NavigateLink noPadding to={`/questionnaire/${questionnaire?._id}`}>
          <StyledText fw="bold">{name}</StyledText>
        </NavigateLink>
      ),
      sorter: (a, b) =>
        (normalizeString(a.label).toLowerCase() >
          normalizeString(b.label).toLowerCase()) -
        (normalizeString(a.label).toLowerCase() <
          normalizeString(b.label).toLowerCase()),
    },
    {
      width: 200,
      title: "Profile",
      dataIndex: "profileName",
      key: "profileName",
      render: (name, obj) => {
        let color = "green";
        let label = "Shared";
        if (obj.profileOId) {
          color = "blue";
          label = name;
        }
        return <Tag color={color}>{label}</Tag>;
      },
    },
    {
      width: 130,
      title: "created at",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => {
        return dayjs(createdAt).format("YYYY-MM-DD HH:mm");
      },
    },
    {
      width: 100,
      title: "Status",
      dataIndex: "isPublished",
      key: "isPublished",
      render: (isPublished) => {
        let color = "green";
        let label = "Published";
        if (!isPublished) {
          color = "red";
          label = "Not published";
        }
        return <Tag color={color}>{label}</Tag>;
      },
    },
  ];

  useEffect(() => {
    getQuestionnaires();
  }, [getQuestionnaires]);

  return (
    <Page>
      <PageHeader iconType="document">Questionnaire</PageHeader>
      <Card>
        <StyledFlex>
          <Space>
            <NavigateLink
              data-testid="navigateCreateNewCQ"
              icon="add"
              noPadding
              to="/questionnaire/new"
            >
              Create
            </NavigateLink>
            <NavigateLink
              data-testid="navigateImportCQ"
              icon="upload"
              noPadding
              to="/questionnaire/import"
            >
              Import
            </NavigateLink>
          </Space>
        </StyledFlex>
        <Table
          columns={antdColumns}
          dataSource={questionnaires}
          loading={isLoading}
          pagination={false}
          rowKey={(item) => item._id}
        />
      </Card>
    </Page>
  );
}

export default CustomQuestionnaireList;
