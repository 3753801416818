import { Flex, List } from "antd";
import React from "react";

import { Loading, Card as OverviewCard } from "@gfw/corvus";

import { ListControls } from "@src/resources/generic/components/ListControls";
import { ProfileItem } from "./ProfileItem";
import {
  StyledFlex,
  StyledListItem,
  StyledSubTitle,
  StyledSubTitleR,
  StyledTitle,
} from "./Card.styles";

export function Card({
  title,
  actions,
  profiles = [],
  profileValue,
  isLoading,
  listControls,
}) {
  return (
    <OverviewCard
      extra={actions}
      size="small"
      title={<StyledTitle>{title.main}</StyledTitle>}
    >
      <Flex>
        <StyledSubTitle>{title.left}</StyledSubTitle>
        {title.right && <StyledSubTitleR>{title.right}</StyledSubTitleR>}
      </Flex>
      {isLoading ? (
        <Loading />
      ) : (
        <List
          dataSource={profiles}
          renderItem={(profile) => (
            <StyledListItem>
              <ProfileItem
                profile={profile.node || profile}
                profileValue={profileValue}
              />
            </StyledListItem>
          )}
          split={false}
        ></List>
      )}
      {listControls && (
        <StyledFlex justify="flex-end">
          <ListControls
            itemsPerPageState={listControls.itemsPerPageState}
            onNext={listControls.onNext}
            onPrevious={listControls.onPrevious}
            pageInfo={listControls.pageInfo}
            rowOptions={listControls.rowOptions}
          />
        </StyledFlex>
      )}
    </OverviewCard>
  );
}
