import { has, pick } from "lodash";
import { SIZES } from "./sizes";
// TODO: fix this (merge these 2 size concepts)
import { SIZES as NEW_SIZES } from "./size";

const MIN_WIDTHS = {
  ...NEW_SIZES,
  ...SIZES,
};
function transformSizeValue(value) {
  return value;
}

export function mapPropsToMinWidth(props) {
  const properties = pick(props, ["min-width", "min-w"]);

  return Object.values(properties).reduce((styles, value) => {
    return {
      ...styles,
      ["min-width"]: getMinWidth(value),
    };
  }, {});
}

const isScaleValue = (value) => has(MIN_WIDTHS, value);

export function getMinWidth(width) {
  if (isScaleValue(width)) {
    return MIN_WIDTHS[width];
  } else {
    return transformSizeValue(width);
  }
}
