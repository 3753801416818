import { Flex, List } from "antd";
import styled from "styled-components";

import { FONT_SIZES, scale } from "@gfw/corvus";

export const StyledListItem = styled(List.Item)`
  padding: 8px 0 !important;
`;

export const StyledFlex = styled(Flex)`
  margin-top: ${scale["lg"]};
`;

export const StyledSubTitle = styled.p`
  white-space: inherit;
  margin: 0;
`;
export const StyledSubTitleR = styled(StyledSubTitle)`
  margin-left: auto;
`;
export const StyledTitle = styled(StyledSubTitle)`
  white-space: inherit;
  margin: 0;
  font-size: ${FONT_SIZES.md};
`;
