import React from "react";
import { Input, InputGroup } from "../Input";
import { Icon } from "../../components";

const DateInput = React.forwardRef(function DateInput(props, forwardedRef) {
  return (
    <InputGroup size="sm">
      <Icon fs="1.2x">calendar</Icon>
      <Input
        ref={forwardedRef}
        type="text"
        placeholder="YYYY-MM-DD"
        {...props}
      />
    </InputGroup>
  );
});

export { DateInput };
