/* eslint-disable sort-imports-es6-autofix/sort-imports-es6 */
/* eslint-disable react/jsx-sort-props */
import PropTypes from "prop-types";
import React from "react";

import { useResizeObserver } from "../../../../core";

import { Text, TextArea, Box, Input, FormControl, Field } from "../../..";

import { useQuestionnaireQuestion } from "../Questionnaire";

function ReadOnlyTextQuestion({ label, subtitle, answer }) {
  return (
    <Box>
      {subtitle && (
        <Text fw="bold" color="text.secondary">
          {subtitle}
        </Text>
      )}
      <Field label={label}>{answer || "-"}</Field>
    </Box>
  );
}
ReadOnlyTextQuestion.propTypes = {
  label: PropTypes.string,
  subtitle: PropTypes.string,
  answer: PropTypes.string,
};

function InlineTextInput({ label, subtitle, helpText, ...props }) {
  let question = useQuestionnaireQuestion(props);
  if (question.isReadOnly) {
    return (
      <ReadOnlyTextQuestion
        label={label}
        subtitle={subtitle}
        answer={question.answer}
      />
    );
  }

  return (
    <FormControl data-testid={question.id}>
      {subtitle && (
        <FormControl.Text fw="bold" color="text.secondary">
          {subtitle}
        </FormControl.Text>
      )}
      <FormControl.Label>{label}</FormControl.Label>
      {helpText ? (
        <FormControl.Text mb={"xs"} mt={"0"}>
          {helpText}
        </FormControl.Text>
      ) : null}
      <Input
        defaultValue={question.answer}
        onBlur={(event) => question.onAnswerUpdated(event.target.value)}
        size="lg"
      />
    </FormControl>
  );
}
InlineTextInput.propTypes = {
  label: PropTypes.string,
  subtitle: PropTypes.string,
  helpText: PropTypes.string,
};

function InlineTextArea({ label, subtitle, helpText, ...props }) {
  let question = useQuestionnaireQuestion(props);

  const [height, setHeight] = React.useState(0);
  const textareaRef = React.useRef();
  // we need to use a ResizeObserver here since this element will be inside a hidden div (Accordion) and become visible...thus resized.
  // the scroll height is needed since it has overflow: hidden and we need to get the height "if the scroll was visible"...
  const dimensions = useResizeObserver(textareaRef, "target.scrollHeight");

  React.useEffect(() => {
    // in FF the scroll height changes when you set the height...weird...doesn't happen in Chrome
    // thus we need guard behind this
    if (dimensions.scrollHeight && height === 0) {
      setHeight(dimensions.scrollHeight || 0);
    }
  }, [dimensions.scrollHeight, height]);

  const onInputHandler = () => {
    setTimeout(() => {
      setHeight("auto");
      if (textareaRef.current) setHeight(textareaRef.current.scrollHeight);
    }, 0);
  };

  if (question.isReadOnly) {
    return (
      <ReadOnlyTextQuestion
        label={label}
        subtitle={subtitle}
        answer={question.answer}
      />
    );
  }

  return (
    <FormControl data-testid={question.id}>
      {subtitle && (
        <FormControl.Text fw="bold" color="text.secondary">
          {subtitle}
        </FormControl.Text>
      )}
      <FormControl.Label>{label}</FormControl.Label>
      {helpText ? (
        <FormControl.Text mb={"xs"} mt={"0"}>
          {helpText}
        </FormControl.Text>
      ) : null}
      <TextArea
        defaultValue={question.answer}
        onBlur={(event) => question.onAnswerUpdated(event.target.value)}
        size="lg"
        rows={1}
        style={{
          resize: "none",
          height,
          minHeight: "35px",
          overflow: "hidden",
        }}
        ref={textareaRef}
        onInput={onInputHandler}
      />
    </FormControl>
  );
}
InlineTextArea.propTypes = {
  label: PropTypes.string,
  subtitle: PropTypes.string,
  helpText: PropTypes.string,
};

function TextQuestion({ isMultiLine, ...props }) {
  if (isMultiLine) {
    return <InlineTextArea {...props} />;
  } else {
    return <InlineTextInput {...props} />;
  }
}
TextQuestion.propTypes = {
  isMultiLine: PropTypes.bool,
};

export default TextQuestion;
