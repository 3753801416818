import { get, has } from "lodash";

// https://chakra-ui.com/theme#z-index
// we probably don't need all of these or could call them something else, main idea is to get a hierarchy of z-indexes
export const Z_INDEXES = {
  hide: -1,
  auto: "auto",
  base: 0,
  docked: 10,
  dropdown: 1000,
  sticky: 1100,
  banner: 1200,
  overlay: 1300,
  modal: 1400,
  popover: 1500,
  toast: 1600,
  tooltip: 1700,
};

const isScaleValue = (value) => has(Z_INDEXES, value);

export function mapPropsToZIndex(props) {
  const value = get(props, "z-index");

  return (
    value && {
      zIndex: getZIndex(value),
    }
  );
}

export function getZIndex(value) {
  if (isScaleValue(value)) {
    return Z_INDEXES[value];
  } else {
    return value;
  }
}
