function isDOMEnvironment() {
  return (
    typeof window !== "undefined" &&
    typeof window.document !== "undefined" &&
    typeof window.document.createElement !== "undefined"
  );
}
export function getOwnerDocument(element) {
  if (element && element.ownerDocument) {
    return element.ownerDocument;
  } else {
    return isDOMEnvironment() ? document : null;
  }
}
