import { FormControl, useFormControl } from "./FormControl";
import { FormControlLabel } from "./FormControlLabel";
import { FormControlFeedback } from "./FormControlFeedback";
import { FormControlText } from "./FormControlText";

FormControl.Label = FormControlLabel;
FormControl.Text = FormControlText;
FormControl.Feedback = FormControlFeedback;

export { FormControl, useFormControl };
