import { Flex, Input, Radio } from "antd";
import { observer } from "mobx-react";
import { useQuery } from "react-query";
import gql from "graphql-tag";
import React from "react";

import { apiClient } from "@gfw/backend-connector";
import { DatePicker, Loading, Space } from "@gfw/corvus";

import {
  StyledFieldSet,
  StyledLabel,
  StyledLegend,
  StyledSelect,
} from "./NewsArticleForm.styles";

const NEWS_PROVIDERS = gql`
  query NewsProviders {
    NewsProviders {
      id
      name
    }
  }
`;

const NewsArticleForm = observer(function NewNewsArticleForm({
  article,
  isEditing = false,
}) {
  // TODO: why do we need to load the providers on every news article...here context + hook would be a better choice
  const { data: providers = [], isLoading } = useQuery(
    ["newsProviders"],
    async () => {
      const response = await apiClient.graphql(NEWS_PROVIDERS);

      if (response?.NewsProviders) {
        return response.NewsProviders;
      } else {
        return { error: "error" };
      }
    },
    { refetchOnWindowFocus: false },
  );

  const types = [
    { name: "External Link", id: "externalLink" },
    { name: "rss", id: "rss" },
  ];

  if (isLoading || (isEditing && !article.provider)) {
    return <Loading />;
  }

  const onSelectProvider = (providerId) =>
    article.update(
      "provider",
      providers.find((p) => p.id === providerId),
    );

  return (
    <Flex gap="middle" vertical>
      <div>
        <StyledLabel>Title</StyledLabel>
        <Input
          onChange={(e) => {
            article.update("title", e.target.value);
          }}
          required={true}
          value={article.title}
        />
      </div>
      <div>
        <StyledLabel>Subtitle</StyledLabel>
        <Input.TextArea
          autoSize={{ minRows: 2, maxRows: 6 }}
          onChange={(e) => {
            article.update("subtitle", e.target.value);
          }}
          value={article.subtitle}
        />
      </div>
      <StyledFieldSet>
        <StyledLegend>Type</StyledLegend>
        <Radio.Group
          onChange={(e) => article.update("type", e.target.value)}
          value={article.type}
        >
          <Space>
            {types.map((type) => (
              <Radio key={type.id} value={type.id}>
                {type.name}
              </Radio>
            ))}
          </Space>
        </Radio.Group>
      </StyledFieldSet>
      {article?.type && (
        <div>
          <StyledLabel>Link</StyledLabel>
          <Input
            onChange={(e) => {
              article.update("link", e.target.value);
            }}
            value={article.link}
          />
        </div>
      )}
      <div>
        <StyledLabel>Provider</StyledLabel>
        <StyledSelect
          defaultValue={article.provider?.name}
          onSelect={onSelectProvider}
          options={providers.map((p) => ({
            label: p.name,
            value: p.id,
          }))}
          placeholder="Select a provider..."
        />
      </div>
      <StyledFieldSet>
        <StyledLegend>Published date</StyledLegend>
        <DatePicker
          defaultValue={article.publishedAt}
          onDateSelected={(_, dateString) => {
            article.update("publishedAt", dateString);
          }}
        />
      </StyledFieldSet>
    </Flex>
  );
});

export { NewsArticleForm };
