import { Flex } from "antd";
import { isArray, isBoolean, omit } from "lodash";
import PropTypes from "prop-types";
import React from "react";

import { Box, FlexBox, Text } from "../../components";
import { warning } from "@gfw/core";

function getFieldValue(value) {
  if (isBoolean(value)) {
    return value ? "YES" : "NO";
  } else if (isArray(value)) {
    return (
      <Flex vertical>
        {value.map((v, index) => (
          <Field key={index}>{v}</Field>
        ))}
      </Flex>
    );
  }

  return value;
}

function Field({ label, children, ...props }) {
  warning(
    "value" in props,
    `[@gfw:orion] Field no longer has support for 'value'. Just use children as your value.`,
  );
  warning(
    "type" in props,
    `[@gfw:orion] Field no longer has support for 'type'. Field converts value automatically`,
  );

  // TODO: aria-labelledby on the field value with ref to field "label"
  return (
    <FlexBox
      direction="column"
      line-height={1.5}
      {...omit(props, "value", "type")}
    >
      <Text as="span" typography="form-label">
        {label}
      </Text>
      <Box
        align-items="center"
        display="inline-flex"
        font-weight="medium"
        white-space="pre-line"
      >
        {getFieldValue(props.value || children)}
      </Box>
    </FlexBox>
  );
}

Field.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node,
  value: PropTypes.any,
};

export { Field };
