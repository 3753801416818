import { Box } from "../Box";
import { CheckboxGroup, useCheckboxGroup } from "../CheckboxGroup";
import { CheckboxSquare as CheckboxIndeterminate } from "@styled-icons/boxicons-regular/CheckboxSquare";
import { Checkbox as CheckboxOff } from "@styled-icons/boxicons-regular/Checkbox";
import { CheckboxChecked as CheckboxOn } from "@styled-icons/boxicons-regular/CheckboxChecked";
import { FlexBox } from "../FlexBox";
import { Icon } from "../Icon";
import { mergeEventHandlers } from "../../core";
import { omit } from "lodash";
import { VisuallyHidden } from "../VisuallyHidden";
import { warning } from "@gfw/core";
import React from "react";

function useCheckboxStyle({ isChecked, isDisabled }) {
  return React.useMemo(
    () => ({
      getLabelStyle: () => ({
        opacity: isDisabled ? (isChecked ? 1 : 0.5) : 1,
        cursor: isDisabled ? "default" : "pointer",
      }),
      getIconStyle: () => ({
        color: isChecked
          ? isDisabled
            ? "currentColor"
            : "gfw.600"
          : "currentColor",
      }),
    }),
    [isChecked, isDisabled],
  );
}

const Checkbox = React.forwardRef(function Checkbox(
  {
    children,
    isIndeterminate,
    checked: _checked,
    defaultIsChecked = false,
    onBlur,
    name,
    value,
    ...props
  },
  forwardedRef,
) {
  const group = useCheckboxGroup();
  let { current: isControlled } = React.useRef("isChecked" in props);

  let isChecked = props.isChecked;
  if (group?.values && value) {
    // the group decides this for us
    isChecked = group.values.includes(value);
    isControlled = true;
  }

  let onChange = props.onChange;
  if (group?.onChange && value) {
    // use the onChange handler of the group
    onChange = group.onChange;
  }

  let isDisabled = group?.isDisabled || props.isDisabled;
  let isReadOnly = group?.isReadOnly || props.isReadOnly;

  const [_isChecked, setIsChecked] = React.useState(
    isChecked || defaultIsChecked,
  );

  const getIsChecked = () => (isControlled ? isChecked : _isChecked);

  if (_checked !== undefined) {
    warning(
      true,
      `[@gfw:orion] Checkbox has deprecated support for 'checked'. Please use 'isChecked' instead.`,
    );
    // just to make it work anyway...
    isChecked = _checked;
    isControlled = true;
  }

  const { getIconStyle, getLabelStyle } = useCheckboxStyle({
    isChecked: getIsChecked(),
    isDisabled: isDisabled || isReadOnly,
  });

  const handleOnChange = (event) => {
    event.stopPropagation();
    if (isReadOnly || isDisabled) {
      event.preventDefault();
      return;
    }
    if (!isControlled) {
      setIsChecked(event.target.checked);
    }

    onChange?.(event);
  };

  /*   
    A Checkbox consist of:
      1. A Label
      2. A Hidden Input element (makes it work like a "real" <input type="checkbox" />)
      3. A Visual Icon that acts as the checkbox for the UI
  */

  return (
    <FlexBox
      align="center"
      as="label"
      data-checked={getIsChecked()}
      variant="inline"
      {...omit(props, "isChecked", "isReadOnly", "onChange", "isDisabled")}
      {...getLabelStyle()}
    >
      <VisuallyHidden
        as="input"
        checked={getIsChecked()}
        disabled={isDisabled || isReadOnly}
        name={name}
        onBlur={onBlur}
        onChange={mergeEventHandlers(handleOnChange, props.onChange)}
        ref={forwardedRef}
        type="checkbox"
        value={value}
      />
      <FlexBox
        align="center"
        aria-hidden
        justify="center"
        variant="inline"
        {...getIconStyle()}
      >
        <Icon
          as={
            getIsChecked()
              ? isIndeterminate
                ? CheckboxIndeterminate
                : CheckboxOn
              : CheckboxOff
          }
          fs="2em"
        />
      </FlexBox>
      {children && <Box ml="4px">{children}</Box>}
    </FlexBox>
  );
});

// makes it possible to do <Checkbox.Group> <Checkbox /> </Checkbox.Group>
Checkbox.Group = CheckboxGroup;

export { Checkbox };
