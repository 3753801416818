import { Tooltip, Button as AntdButton, Popconfirm } from "antd";
import React from "react";
import styled from "styled-components";

import { FlexBox } from "../FlexBox/FlexBox";
import { Icon } from "../Icon/Icon";

// For more details, please refer to https://ant.design/components/button/

const StyledButton = styled(AntdButton)`
  background-color: ${(props) => props.bgcolor || undefined};
  border-color: ${(props) => props.bgcolor || undefined};
  /* Set width of button from props or default to auto */
  width: ${(props) => props.width};
  /* Set height of button from props or default to auto */
  height: ${(props) => props.height};
  /* Set padding of button to 0 if nopadding is true, for example, to align button to start */
  padding: ${(props) => (props.nopadding ? 0 : undefined)};
  /* Set border of button to 0 if iconbutton is true, for example, for pagination buttons */
  border: ${(props) => (props.iconbutton ? 0 : undefined)};

  span {
    white-space: pre-line;
    text-align: left;
  }
  :hover {
    background-color: ${(props) => props.bgcolor || undefined};
    border-color: ${(props) => props.bgcolor || undefined};
    opacity: ${(props) => (props.bgcolor ? 0.7 : undefined)};
  }
`;

const Button = ({ ...props }) => {
  const {
    tooltip,
    block,
    children,
    danger,
    disabled,
    ghost,
    href,
    icon,
    loading,
    onClick,
    shape,
    size,
    target,
    type,
    htmlType,
    width,
    height,
    noPadding,
    bgColor,
    iconFs,
    iconMr,
    iconMt,
    dataTestId,
    confirmTitle,
    confirmPlacement,
    confirmOkText,
    confirmCancelText,
    ...rest
  } = props;
  const antdProps = {
    block,
    children,
    danger,
    disabled,
    ghost,
    href,
    loading,
    onClick,
    shape,
    size,
    target,
    htmlType,
    width,
    height,
    // nopadding is a string in lowercase because DOM complains otherwise
    nopadding: noPadding ? `${noPadding}` : undefined,
    // bgcolor is a string in lowercase because DOM complains otherwise
    bgcolor: bgColor ? `${bgColor}` : undefined,
    // iconbutton is a string in lowercase because DOM complains otherwise
    iconbutton: !!icon && !children ? "true" : undefined,
    // datatestid is a string in lowercase because DOM complains otherwise
    datatestid: dataTestId ? `${dataTestId}` : undefined,
  };

  let buttonIcon = icon;
  const buttonType = !!icon && !children ? "link" : type;
  const fs = !!icon && !children ? 3 : 2;
  const mr = !!icon && !children ? 0 : "xs";

  if (typeof icon === "string") {
    buttonIcon = (
      <Icon fs={iconFs ?? fs} mr={iconMr ?? mr} mt={iconMt || "-2px"}>
        {icon}
      </Icon>
    );
  }

  const CustomButton = (
    <StyledButton
      icon={buttonIcon}
      type={buttonType}
      data-testid={dataTestId}
      {...antdProps}
      onClick={confirmTitle ? undefined : antdProps.onClick}
    />
  );
  const ConfirmButton =
    confirmTitle && !disabled ? (
      <Popconfirm
        title={confirmTitle}
        onConfirm={antdProps.onClick}
        placement={confirmPlacement}
        okText={confirmOkText}
        cancelText={confirmCancelText}
      >
        {CustomButton}
      </Popconfirm>
    ) : (
      CustomButton
    );
  if (tooltip) {
    return (
      <FlexBox {...rest}>
        <Tooltip title={tooltip}>{ConfirmButton}</Tooltip>
      </FlexBox>
    );
  }
  return <FlexBox {...rest}>{ConfirmButton}</FlexBox>;
};

export { Button };
