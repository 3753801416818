import { mapPropsToMinHeight } from "./min-height";
import { mapPropsToMaxWidth } from "./max-width";
import { mapPropsToMaxHeight } from "./max-height";
import { mapPropsToMinWidth } from "./min-width";
import { mapToSize, getSize } from "./size";
import { mixin } from "../../utils";
import { css } from "styled-components";

// merge multiple style functions into 1 (the sizing module)
const sizing = css`
  ${mapPropsToMinHeight};
  ${mapPropsToMaxWidth};
  ${mapPropsToMaxHeight};
  ${mapPropsToMinWidth};
  ${mixin("size", mapToSize)};
`;

export { mapPropsToMinHeight, getSize };

export default sizing;
