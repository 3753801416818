import { types } from "mobx-state-tree";
import { useLocalObservable } from "mobx-react";
import gql from "graphql-tag";
import React, { useEffect } from "react";

import { PROFILE_STATE } from "@gfw/core";

const ProfileModelFragment = gql`
  fragment ProfileModel on Profile {
    oid
    id
    name
    createdAt
    updatedAt
    location
    state
    types
    isPremium
    lastConnectionAt
    proposedBy
    draftedAt
    draftedBy
    users {
      id
    }
  }
`;

const ProfileModel = types
  .model("Profile", {
    name: types.string,
    oid: types.string,
    location: types.maybeNull(types.string),
    draftedAt: types.maybeNull(types.string),
    draftedBy: types.maybeNull(types.string),
    proposedBy: types.maybeNull(types.array(types.string)),
    id: types.string,
    createdAt: types.string,
    types: types.array(types.string),
    state: types.number,
    updatedAt: types.string,
    isPremium: types.boolean,
    lastConnectionAt: types.maybeNull(types.string),
    users: types.frozen(types.array(types.model({ id: types.string }))),
  })
  .views((self) => ({
    get isDraft() {
      return self.state === PROFILE_STATE.DRAFT;
    },
    get isProposed() {
      return self.state === PROFILE_STATE.PROPOSED;
    },
    get isActive() {
      return self.state !== PROFILE_STATE.INACTIVE;
    },
    get hasUsers() {
      return self.users.length > 0;
    },
  }))
  .actions((self) => ({
    // setProfile serves the Hack below to ensure instance model is updated
    setProfile(profile) {
      self.name = profile.name;
      self.oid = profile.oid;
      self.location = profile.location;
      self.draftedAt = profile.draftedAt;
      self.draftedBy = profile.draftedBy;
      self.proposedBy = profile.proposedBy;
      self.id = profile.id;
      self.createdAt = profile.createdAt;
      self.types = profile.types;
      self.state = profile.state;
      self.updatedAt = profile.updatedAt;
      self.isPremium = profile.isPremium;
      self.lastConnectionAt = profile.lastConnectionAt;
      self.users = profile.users;
    },
  }));

const ProfileContext = React.createContext();

function useProfile() {
  const instance = React.useContext(ProfileContext);
  if (!instance) {
    throw new Error(`useProfile must be used within a ProfileProvider`);
  }

  return instance;
}

function ProfileProvider({ profile, ...props }) {
  const instance = useLocalObservable(() => ProfileModel.create(profile));

  // HACK: dirty code to ensure mobx instance is updated since profile prop changes do not update the instance
  // The reason is the model defined. It stores data at the root which is not recommended as you are not allowed to update the full object
  // Therefore we use an effect to maually update the instance
  useEffect(() => {
    instance.setProfile(profile);
  }, [profile, instance]);
  return <ProfileContext.Provider value={instance} {...props} />;
}

export { ProfileProvider, useProfile, ProfileModelFragment };
