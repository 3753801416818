import { isFunction } from "lodash";

function composeEventHandlers(...fns) {
  return fns.reduce(
    (handlers, handler) => {
      if (isFunction(handler)) {
        return function composedEventHandler(...args) {
          handlers.apply(this, args);
          handler.apply(this, args);
        };
      } else {
        return handlers;
      }
    },
    () => {},
  );
}

function mergeEventHandlers(...fns) {
  return function (event) {
    fns.some((fn) => {
      fn && fn(event);
      return event && event.defaultPrevented;
    });
  };
}

export { composeEventHandlers, mergeEventHandlers };
