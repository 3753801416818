import React from "react";

import { Icon } from "@gfw/corvus";

import { PageTitle, PageTitleTypo } from "@src/resources/generic/components";

function PageHeader({ children, iconType }) {
  return (
    <PageTitle>
      <Icon mr="sm" type={iconType} />
      <PageTitleTypo>{children}</PageTitleTypo>
    </PageTitle>
  );
}

export { PageHeader };
