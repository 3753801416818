import { get } from "lodash";

const radii = {
  sm: "0.125rem",
  md: "0.25rem",
  lg: "0.5rem",
  circle: "100%",
  full: "9999px",
  none: "0",
};

export function mapPropsToBorderRadius(props) {
  // TODO: add support for things like this: (ref: https://tailwindcss.com/docs/border-radius)
  // border-radius="x:small" => border-left-radius + border-right-radius => value from scale

  const value = get(props, "border-radius");

  if (value) {
    return {
      borderRadius: getBorderRadius(value),
    };
  }

  return null;
}
export function getBorderRadius(value) {
  return get(radii, value, value);
}
