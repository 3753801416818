import { App as AntdApp, ConfigProvider } from "antd";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "reduxjs-toolkit-persist/integration/react";
import { Provider, useDispatch, useSelector } from "react-redux";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { StyleSheetManager } from "styled-components";
import React, { useEffect } from "react";

// CSS imports
import "@src/assets/css/admin.css";

// init i18n to be compatible with orion component
// no translations expected on admin website
import "@src/i18n";

// /admin APP
import {
  ApiErrorHandler,
  defaultShouldForwardPropFn,
  getColor,
  getUIConfig,
  GlobalWatchFonts,
  GlobalWatchStyles,
  InitCorvus,
  Loading,
  useAnalytics,
} from "@gfw/corvus";
import {
  appStateInit,
  getStore,
  selectorUsersMeIsAuthenticated,
} from "@gfw/backend-connector";

import AuthenticatedRouter from "./AuthenticatedRouter";
import PublicRouter from "./PublicRouter";

const queryClient = new QueryClient();

const { isDevelopment, isProduction, isStaging, isPullRequest, MATOMO_ID } =
  getUIConfig();

function RouterSelector() {
  useAnalytics({ matomoId: MATOMO_ID });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(appStateInit({ isAdmin: true, isCypress: !!window.Cypress }));
  }, [dispatch]);

  const isAuthenticated = useSelector(selectorUsersMeIsAuthenticated);

  if (isAuthenticated) return <AuthenticatedRouter />;
  return <PublicRouter />;
}

function App() {
  const { store, persistor } = getStore({
    isDevelopment,
    isProduction,
    isStaging,
    isPullRequest,
  });

  return (
    <Provider store={store}>
      <PersistGate loading={<Loading />} persistor={persistor}>
        <ConfigProvider
          notification={{ style: { top: 50 } }}
          theme={{
            token: {
              colorPrimary: getColor("primary.main"),
              colorLink: getColor("primary.main"),
              colorLinkHover: getColor("primary.light"),
              colorLinkActive: getColor("primary.dark"),
              borderRadius: 0,
            },
          }}
        >
          <AntdApp>
            <QueryClientProvider client={queryClient}>
              <BrowserRouter>
                <InitCorvus />
                <ApiErrorHandler />
                <StyleSheetManager
                  shouldForwardProp={defaultShouldForwardPropFn}
                >
                  <GlobalWatchFonts />
                  <GlobalWatchStyles $bg={getColor("gray.200")} />
                  <RouterSelector />
                </StyleSheetManager>
              </BrowserRouter>
              <ReactQueryDevtools position="bottom-right" />
            </QueryClientProvider>
          </AntdApp>
        </ConfigProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
