import React from "react";
import { Box } from "../Box";

const ListBoxList = React.forwardRef(function Component(
  { children, ...props },
  forwardedRef,
) {
  return (
    <Box
      ref={forwardedRef}
      as="ul"
      role="listbox"
      css={`
        list-style: none;
        user-select: none;
      `}
      tabIndex={-1}
      {...props}
    >
      {children}
    </Box>
  );
});

export { ListBoxList };
