import { mapKeys, uniq } from "lodash";
import { Tag } from "antd";
import React from "react";

import { PROFILE_STATE, profileStateToString, ProfileTypes } from "@gfw/core";

const flat = (obj, prefix = "") => {
  let keys = {};
  mapKeys(obj, function (value, key) {
    if (typeof value === "object") {
      keys = {
        ...keys,
        ...flat(value, `${prefix}${!prefix ? "" : "."}${key}`),
      };
    } else {
      keys[`${prefix}${!prefix ? "" : "."}${key}`] = value;
    }
  });
  return keys;
};

const getStatsKeys = (profile) => {
  delete profile.stats.counterpartiesLocations;
  delete profile.stats.counterpartiesTypes;

  return flat(profile.stats);
};

const getStateTag = (state) => {
  let color = "grey";
  let label = profileStateToString(state);
  if (state === PROFILE_STATE.DRAFT) {
    color = "blue";
  } else if (state === PROFILE_STATE.PROPOSED) {
    color = "purple";
  } else if (state === PROFILE_STATE.INACTIVE) {
    color = "red";
  } else if (state === PROFILE_STATE.OFFICIAL) {
    color = "green";
  }
  return <Tag color={color}>{label}</Tag>;
};

const buildDataSource = (profileToRemove, profileToKeep) => {
  const data = [];
  const profileIdToKeep = profileToKeep.id;
  const profileIdToRemove = profileToRemove.id;

  data.push({
    name: "name",
    [profileIdToKeep]: profileToKeep.name,
    [profileIdToRemove]: profileToRemove.name,
  });
  data.push({
    name: "profileId",
    [profileIdToKeep]: profileToKeep.id,
    [profileIdToRemove]: profileToRemove.id,
  });
  data.push({
    name: "state",
    [profileIdToKeep]: getStateTag(profileToKeep.state),
    [profileIdToRemove]: getStateTag(profileToRemove.state),
  });

  data.push({
    name: "createdAt",
    [profileIdToKeep]: profileToKeep.createdAt,
    [profileIdToRemove]: profileToRemove.createdAt,
  });
  data.push({
    name: "types",
    [profileIdToKeep]: profileToKeep.types.map((type) => (
      <Tag key={type}>{ProfileTypes.byId(type).name}</Tag>
    )),
    [profileIdToRemove]: profileToRemove.types.map((type) => (
      <Tag key={type}>{ProfileTypes.byId(type).name}</Tag>
    )),
  });

  const profileToKeepStats = getStatsKeys(profileToKeep);
  const profileToRemoveStats = getStatsKeys(profileToRemove);

  const statsKeys = uniq([
    ...Object.keys(profileToKeepStats),
    ...Object.keys(profileToRemoveStats),
  ]);
  for (const statsKey of statsKeys) {
    data.push({
      name: statsKey,
      [profileIdToKeep]: profileToKeepStats[statsKey] ?? "-",
      [profileIdToRemove]: profileToRemoveStats[statsKey] ?? "-",
    });
  }
  return data;
};

export default buildDataSource;
