import React from "react";
import { omit } from "lodash";
import { Stack } from "../Stack";
import { Box } from "../Box";
import { getValidReactChildren, generateNextId } from "../../core";

const RadioGroup = React.forwardRef(function RadioGroup(
  {
    isDisabled,
    isReadOnly,
    onChange,
    defaultValue = "",
    name,
    children,
    as: Component,
    ...props
  },
  forwardedRef,
) {
  const [value, setValue] = React.useState(defaultValue);
  const { current: isControlled } = React.useRef("value" in props);

  const getValue = () => (isControlled ? props.value : value);
  // all radios in a group must use the same name
  const getName = () => name || `radio--${generateNextId()}`;

  const onGroupChange = (event) => {
    if (!isControlled) {
      setValue(event.target.value);
    }

    onChange?.(event);
  };

  const radios = getValidReactChildren(children).map((radio) => {
    return React.cloneElement(radio, {
      isDisabled: isDisabled || radio.props.isDisabled,
      isReadOnly: isReadOnly || radio.props.isReadOnly,
      isChecked: radio.props.value === getValue(),
      onChange: onGroupChange,
      name: getName(),
    });
  });

  return (
    <Box
      as={Component}
      ref={forwardedRef}
      role="radiogroup"
      {...omit(props, "value")}
    >
      {radios}
    </Box>
  );
});

RadioGroup.defaultProps = {
  spacing: "none",
  as: Stack,
};

export { RadioGroup };
