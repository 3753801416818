import { Box } from "../Box";
import React from "react";

function VisuallyHidden(props) {
  return (
    <Box
      $css={`
      clip: rect(0px, 0px, 0px, 0px);
    `}
      border={false}
      height="1px"
      m="-1px"
      overflow="hidden"
      p="0px"
      position="absolute"
      white-space="nowrap"
      width="1px"
      {...props}
    />
  );
}

export { VisuallyHidden };
