import React from "react";
import { useMachine } from "@xstate/react";
import { machine, ListBoxEvent, ListBoxState } from "./listbox-machine";

const ListBoxContext = React.createContext();

function useListBox() {
  return React.useContext(ListBoxContext);
}

function ListBox({
  children,
  defaultValue,
  value,
  onChange,
  isDisabled = false,
  ...props
}) {
  // DOM refs
  let buttonRef = React.useRef();
  let overlayRef = React.useRef();

  const { current: isControlled } = React.useRef(value != null);

  const [state, dispatch] = useMachine(machine, {
    actions: {
      focusButton: () => {
        buttonRef.current && buttonRef.current.focus();
      },
    },
    guards: {
      clickedOutsideOfListBox: (_, event) => {
        if (event.type === ListBoxEvent.MOUSE_DOWN) {
          let { current: button } = buttonRef;
          let { current: overlay } = overlayRef;
          let { relatedTarget } = event;

          // Close the overlay...IF:
          return !!(
            // clicked element is not the button
            (
              relatedTarget !== button.current &&
              // clicked element is not inside the button
              button &&
              !button.contains(relatedTarget) &&
              // clicked element is not inside the overlay
              overlay &&
              !overlay.contains(relatedTarget)
            )
          );
        }
        return false;
      },
    },
    context: {
      value: (isControlled ? value : defaultValue) || null,
    },
  });

  const isExpanded = React.useMemo(() => {
    return [ListBoxState.Suggesting].includes(state.value);
  }, [state.value]);

  React.useEffect(() => {
    function listener(event) {
      let { target, relatedTarget, origin } = event;

      if (origin) {
        // see https://appradius.co/blog/cross-origin-communication-iframe-parent-website
        return;
      }
      dispatch({
        type: ListBoxEvent.MOUSE_DOWN,
        relatedTarget: relatedTarget || target,
      });
    }
    window.addEventListener("mousedown", listener);
    return () => {
      window.removeEventListener("mousedown", listener);
    };
  }, [dispatch]);

  let context = React.useMemo(
    () => ({
      refs: {
        button: buttonRef,
        overlay: overlayRef,
      },
      onChange,
      dispatch,
      state,
      isExpanded,
      isDisabled,
    }),
    [onChange, dispatch, state, isExpanded, isDisabled],
  );

  return (
    <ListBoxContext.Provider value={context} {...props}>
      {children}
    </ListBoxContext.Provider>
  );
}

export { ListBox, useListBox, ListBoxEvent, ListBoxState };
