import React from "react";

import { Page, PageHeader } from "@src/resources/generic/components";

import ProfilesList from "./ProfilesList";

function ProfileListPage() {
  return (
    <Page>
      <PageHeader iconType="profiles">Profiles</PageHeader>
      <ProfilesList />
    </Page>
  );
}

export default ProfileListPage;
