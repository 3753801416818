import React, {
  Children,
  forwardRef,
  useCallback,
  useMemo,
  useState,
} from "react";
import { isEqual } from "lodash";
import styled from "styled-components";

import {
  Box,
  Button,
  FlexBox,
  Icon,
  Input,
  InputGroup,
  ListBox,
} from "../../components";

const StyledButton = styled(Button)`
  & button {
    color: #9e9e9e;
    display: flex;
    align-items: center;
  }
  & button:hover {
    color: #757575;
  }
`;

function useMultiSelect({ onSelect, value, defaultValue = [] }) {
  const [selection, updateSelection] = useState(value || defaultValue);

  // add a new value
  const onChangeHandler = useCallback(
    (newValue) => {
      let newSelection = [...selection, newValue];
      updateSelection(newSelection);
      onSelect && onSelect(newSelection);
    },
    [onSelect, selection],
  );

  const onRemoveHandler = useCallback(
    (option) => {
      let newSelection = selection.filter((value) => !isEqual(value, option));
      updateSelection(newSelection);
      onSelect && onSelect(newSelection);
    },
    [onSelect, selection],
  );

  return useMemo(
    () => ({
      selection,
      onChange: onChangeHandler,
      onRemove: onRemoveHandler,
    }),
    [onChangeHandler, onRemoveHandler, selection],
  );
}

function SelectedOptions({ options, onRemoveSelectedOption, optionToString }) {
  const onRemoveSelectedOptionHandler = (option) => (event) => {
    // stops the event from bubbling up to the ListBoxButton
    event.stopPropagation();
    onRemoveSelectedOption(option);
  };

  return (
    <FlexBox align="center" overflow="hidden" wrap="wrap">
      {options.map((option, index) => (
        <FlexBox
          key={index}
          align="center"
          bg="gray.200"
          border-radius="2px"
          m="2px"
          px="6px"
          py="3px"
          line-height="normal"
          min-width="0px"
        >
          <Box word-break="truncate">{optionToString(option)}</Box>
          <StyledButton
            noPadding
            iconFs={12}
            icon="close"
            onClick={onRemoveSelectedOptionHandler(option)}
            ml="6px"
            width="12px"
            height="12px"
          />
        </FlexBox>
      ))}
    </FlexBox>
  );
}

const MultiSelectInput = forwardRef(function MultiSelectInput(
  { children, size = "md", width, ...props },
  forwardedRef,
) {
  return (
    <InputGroup
      ref={forwardedRef}
      variant="outline"
      cursor="pointer"
      height="auto"
      min-height={InputGroup.defaultProps.height}
      min-width={width || size}
      max-width="fit-content"
      outline="none"
      {...props}
    >
      <Input as="div">{children}</Input>
      <Icon fs="1.2em" mr="8px">
        caret-down
      </Icon>
    </InputGroup>
  );
});

const MultiSelect = forwardRef(function MultiComponent(
  {
    children,
    placeholder,
    onSelect,
    value,
    defaultValue,
    optionToString,
    onBlur,
    size,
    ...props
  },
  forwardedRef,
) {
  const { onChange, onRemove, selection } = useMultiSelect({
    onSelect,
    value,
    defaultValue,
  });

  const options = Children.toArray(children).filter((option) => {
    // an option has a value
    // our selection contain these values
    // by combining these we'll figure out if we have selected the option already and removed that from the presented options
    return selection.some((value) => isEqual(option.props.value, value))
      ? false
      : true;
  });

  return (
    <ListBox onChange={onChange} value={selection}>
      <ListBox.Button
        ref={forwardedRef}
        as={MultiSelectInput}
        size={size}
        onBlur={onBlur}
        {...props}
      >
        {selection.length > 0 ? (
          <SelectedOptions
            optionToString={optionToString}
            options={selection}
            onRemoveSelectedOption={onRemove}
          />
        ) : (
          placeholder
        )}
      </ListBox.Button>
      <ListBox.Overlay>
        <ListBox.List>{options}</ListBox.List>
      </ListBox.Overlay>
    </ListBox>
  );
});

MultiSelect.Option = ListBox.Option;

MultiSelect.defaultProps = {
  optionToString: (v) => String(v),
};

export { MultiSelect };
