import { Flex } from "antd";
import styled from "styled-components";

import { FONT_WEIGHTS, scale } from "@gfw/corvus";

export const StyledFlex = styled(Flex)`
  margin-bottom: ${scale["lg"]};
`;

export const StyledText = styled.p`
  max-width: 300px;
  white-space: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  font-weight: ${FONT_WEIGHTS.bold};
`;
