import { Flex, Input } from "antd";
import styled from "styled-components";

import { scale } from "@gfw/corvus";

import BackgroundImage from "@src/assets/images/background.png";

export const StyledFlex = styled(Flex)`
  margin-top: ${scale["lg"]};
`;

export const Background = styled("div")`
  background-image: url(${BackgroundImage});
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

export const StyledInput = styled(Input)`
  padding: ${scale["xs"]};
  min-width: 64px;
`;
