import React from "react";
import { merge, get } from "lodash";
import { warning } from "@gfw/core";
import { Box } from "../Box";
import { useFormControl } from "../FormControl";
import { getSize } from "../../style";

const INPUT_VARIANTS = {
  outline: {
    border: true,
    bg: "transparent",
    pseudo: {
      hover: {
        border: { color: "gray.400" },
      },
      focus: {
        border: { color: "gfw.700" },
      },
      disabled: {
        px: 0,
        bg: "transparent",
        border: false,
      },
      "read-only": {
        px: 0,
        bg: "transparent",
        border: false,
        "user-select": "all",
      },
      invalid: {
        border: { color: "error" },
      },
    },
  },
  unstyled: {
    bg: "transparent",
    border: "none",
  },
};

function getSafeVariant(variant) {
  warning(
    ["standard", "outlined"].includes(variant),
    `[@gfw:orion] Input no longer has support for variant "${variant}".`,
  );

  return get(INPUT_VARIANTS, variant, INPUT_VARIANTS.outline);
}

function getInputStyle({ variant, ...props }) {
  return merge({}, getSafeVariant(variant), props);
}

const Input = React.forwardRef(function Input(props, ref) {
  const { variant, as, ...inputProps } = props;

  warning(
    "label" in props,
    `[@gfw:orion] Input no longer has support for "label". Please remove it.`,
  );

  const formControl = useFormControl(props);

  const inputStyleProps = getInputStyle({
    variant,
  });

  return (
    <Box
      name={formControl.name}
      id={formControl.id}
      as={as}
      ref={ref}
      css={`
        appearance: none;
        && {
          outline: none;
          filter: none;
        }
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
          -webkit-transition-delay: 9999s;
        }
        &:-moz-autofill,
        &:-moz-autofill-preview {
          filter: none;
        }
        &[type="number"] {
          -moz-appearance: textfield;
        }
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      `}
      aria-readonly={formControl.isReadOnly}
      readOnly={formControl.isReadOnly}
      aria-disabled={formControl.isDisabled}
      disabled={formControl.isDisabled || undefined}
      aria-invalid={Boolean(formControl.isInvalid)}
      data-orion-element="input"
      display="flex"
      align-items="center"
      font-weight="regular"
      min-width={getSize("xs")}
      max-width="100%"
      {...inputStyleProps}
      {...inputProps}
    />
  );
});

Input.defaultProps = {
  as: "input",
  type: "text",
  variant: "outline",
  "font-size": "12px",
  px: "8px",
  height: "32px",
  transition: "all short",
};

Input.OrionComponent = "Input";

export { Input, getInputStyle };
