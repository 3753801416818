import { get, isBoolean, isNumber, isPlainObject } from "lodash";
import { getColor } from "../color";

const SHORTHAND_DIRECTIONS = {
  t: ["top"],
  r: ["right"],
  b: ["bottom"],
  l: ["left"],
  x: ["left", "right"],
  y: ["top", "bottom"],
};

function transformBorderWidthValue(value) {
  if (isBoolean(value)) {
    return value ? "1px" : 0;
  } else if (isPlainObject(value)) {
    // try to get the color from something like this { width: 8 }
    const width = get(value, "width", 1);
    return `${width}px`;
  } else {
    return "1px";
  }
}

function transformBorderStyleValue(value) {
  if (isBoolean(value)) {
    return value ? "solid" : "none";
  } else if (isNumber(value)) {
    return value === 0 ? "none" : "solid";
  } else if (isPlainObject(value)) {
    // try to get the color from something like this { style: "dashed" }
    const style = get(value, "style", "solid");
    return style;
  } else {
    return "solid";
  }
}

function transformBorderColorValue(value) {
  if (isBoolean(value)) {
    return value ? getColor("border") : "transparent";
  } else if (isPlainObject(value)) {
    // try to get the color from something like this { color: "primary.main" }
    const color = get(value, "color", "border");
    return getColor(color);
  } else {
    return value;
  }
}

function addBorderStyles(prop, value) {
  if (value === "none") {
    return {
      [prop]: "none",
    };
  } else if (["top", "bottom", "right", "left"].includes(value)) {
    return addBorderStyles(`border-${value}`, true);
  } else {
    return {
      [`${prop}-width`]: transformBorderWidthValue(value),
      [`${prop}-style`]: transformBorderStyleValue(value),
      [`${prop}-color`]: transformBorderColorValue(value),
    };
  }
}

function getBorderProperties(...args) {
  if (args.length > 1) {
    const direction = args[1];
    return get(SHORTHAND_DIRECTIONS, direction, [direction]).map(
      (dir) => `border-${dir}`,
    );
  } else {
    return ["border"];
  }
}

const VALID_PROP = new RegExp(/^border-?(l|left|r|right|t|top|b|bottom|x|y)*$/);
export function mapPropsToBorder(props) {
  // get all prop keys that match the regex
  const keys = Object.keys(props)
    .filter((key) => VALID_PROP.test(key))
    .sort((a, b) => a.length - b.length); // try to do a logic sorting here

  const styles = keys.map((key) => {
    const properties = getBorderProperties(...key.split("-"));
    const value = props[key];

    return properties.reduce(
      (styles, prop) => ({
        ...styles,
        ...addBorderStyles(prop, value),
      }),
      {},
    );
  });

  return styles.reduce((prev, next) => ({ ...prev, ...next }), {});
}
