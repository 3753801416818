import { Typography } from "antd";
import React from "react";

function PageTitleTypo({ children }) {
  return (
    <Typography.Title level={3} style={{ margin: 0, fontWeight: "bold" }}>
      {children}
    </Typography.Title>
  );
}

export { PageTitleTypo };
