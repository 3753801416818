import React from "react";
import { useForm } from "./useForm";

function Form({ onSubmit, ...props }) {
  const FormProps = useForm(onSubmit);

  return <form {...props} {...FormProps} />;
}

export { Form, useForm };
