export function mapPropsToDisplay({ d, display }) {
  return (
    d ||
    (display && {
      display: d || display,
    })
  );
}

// TODO: can we use this somehow to exclude this from the rendered output
mapPropsToDisplay.propKeys = ["d", "display"];
