import React from "react";
import styled, { css, keyframes } from "styled-components";

import { declareVariant, include } from "../../style";

const sizes = declareVariant("size", {
  xs: css`
    transform: scale(0.5);
  `,
  sm: css`
    transform: scale(0.7);
  `,
  small: css`
    transform: scale(0.7);
  `,
  medium: css`
    transform: none;
  `,
  large: css`
    transform: scale(2);
  `,
});

const ellipsis1 = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`;

const ellipsis2 = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
`;

const ellipsis3 = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(2);
  }
`;

const Ellipsis = styled("div")`
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  ${sizes};

  div {
    position: absolute;
    top: 27px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    ${include("bg")};
    animation-timing-function: cubic-bezier(0, 1, 1, 0);

    &:nth-child(1) {
      left: 6px;
      animation: ${ellipsis1} 0.6s infinite;
    }
    &:nth-child(2) {
      left: 6px;
      animation: ${ellipsis2} 0.6s infinite;
    }
    &:nth-child(3) {
      left: 26px;
      animation: ${ellipsis2} 0.6s infinite;
    }
    &:nth-child(4) {
      left: 45px;
      animation: ${ellipsis3} 0.6s infinite;
    }
  }
`;

function Loading(props) {
  return (
    <Ellipsis {...props}>
      <div />
      <div />
      <div />
      <div />
    </Ellipsis>
  );
}

Loading.defaultProps = {
  bg: "primary.main",
  size: "medium",
};

export { Loading };
