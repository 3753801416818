import { pick } from "lodash";

function getStyleValue(prop, value) {
  return value;
}

export function mapPropsToPosition(props) {
  const directions = ["top", "right", "bottom", "left"];

  const properties = pick(props, [...directions, "position"]);

  return Object.keys(properties).reduce((styles, prop) => {
    return {
      ...styles,
      [prop]: getStyleValue(prop, properties[prop]),
    };
  }, {});
}
