import { Select } from "antd";
import styled from "styled-components";

import { colors, FONT_SIZES, FONT_WEIGHTS, scale } from "@gfw/corvus";

export const StyledFieldSet = styled.fieldset`
  position: relative;
  margin: 0;
  border: 1px solid rgba(0, 0, 0, 0.42);
  border-radius: 4px;
  line-height: 1;
`;
export const StyledLegend = styled("legend")`
  color: ${colors.text.secondary};
  transform: scale(0.75);
  font-size: ${FONT_SIZES.md};
  padding-left: ${scale["xs"]};
  padding-right: ${scale["xs"]};
  margin-left: ${scale["md"]};
`;

export const StyledLabel = styled.label`
  white-space: inherit;
  font-size: ${FONT_SIZES.xs};
  font-weight: ${FONT_WEIGHTS.regular};
  color: ${colors.gray[600]};
  margin: 0;
`;

export const StyledSelect = styled(Select)`
  width: 100%;
`;
