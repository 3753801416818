import { Skeleton } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import qs from "qs";
import React, { useState } from "react";
import saveAs from "file-saver";

import {
  __DEPRECATED__ErrorHandler,
  Button,
  Card,
  FormatText,
  ItemBuilderModal,
  Modal,
  notification,
  QuestionnaireNavigator,
  Space,
} from "@gfw/corvus";
import { apiClient } from "@gfw/backend-connector";

import {
  CollapseStyled,
  Owner,
  PaginationStyled,
  Spacer,
} from "./CustomQuestionnaireEdit.styles";

function CustomQuestionnaireEdit() {
  const { id } = useParams();
  const location = useLocation();
  const [islatestVersion, setIsLatestVersion] = useState(false);

  // detect the version expect based on the query string
  const params = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const [requestedVersion, setRequestedVersion] = useState(params.version);
  const navigate = useNavigate();

  const getQuestionnaire = useQuery({
    queryKey: "questionnaire",
    queryFn: async () => {
      const queryString = qs.stringify({ v: requestedVersion });
      const response = await apiClient.get(
        `/questionnaire/${id}/built${queryString ? `?${queryString}` : ""}`,
      );
      const isLatest = response.data?._v === response.data?.latestVersion;
      setRequestedVersion(isLatest ? undefined : response.data?._v);
      setIsLatestVersion(isLatest);
      return response.data;
    },
    onError: __DEPRECATED__ErrorHandler,
    refetchOnWindowFocus: false,
  });

  const getQuestionnaires = useQuery({
    queryKey: "questionnaireList",
    queryFn: async () => {
      const response = await apiClient.get(`/questionnaire/all`);
      return response.data.questionnaires;
    },
    onError: __DEPRECATED__ErrorHandler,
    refetchOnWindowFocus: false,
  });

  const addQuestionnaireItem = useMutation(
    ({ currentId, item }) => {
      return apiClient.post(`/questionnaire/${currentId}/item`, item);
    },
    {
      onSuccess: () => {
        getQuestionnaire.refetch();
        notification.success({
          message: "Item added",
          // to avoid notification spam during e2e tests
          duration: window.Cypress ? 0.5 : undefined,
        });
      },
      onError: (error) => {
        if (error?.response?.status === 423) {
          notification.error({
            message: "Oops, something went wrong",
            description: "Please try again in a few seconds",
          });
        } else {
          __DEPRECATED__ErrorHandler(error);
        }
      },
    },
  );
  const isPublished = getQuestionnaire?.data?.isPublished;
  const togglePublishQuestionnaire = useMutation(
    () => {
      if (isPublished) {
        return apiClient.put(`/questionnaire/${id}/unpublish`);
      } else {
        return apiClient.put(`/questionnaire/${id}/publish`);
      }
    },
    {
      onSuccess: () => {
        getQuestionnaire.refetch();
        notification.success({
          message: `Questionnaire ${!isPublished ? "" : "un"}published`,
        });
      },
      onError: __DEPRECATED__ErrorHandler,
    },
  );

  const moveQuestionnaireItem = useMutation(
    ({ itemOId, parentOId, index }) => {
      return apiClient.post(`/questionnaire/${itemOId}/move`, {
        parentOId,
        index,
      });
    },
    {
      onSuccess: () => {
        getQuestionnaire.refetch();
        notification.success({ message: "Item moved" });
      },
      onError: __DEPRECATED__ErrorHandler,
    },
  );

  const editQuestionnaireItem = useMutation(
    async ({ item, message: content = "Item updated" }) => {
      await apiClient.put(`/questionnaire/${item._id}`, {
        ...item,
        items: item.items?.map?.((el) => ({
          itemOId: el.itemOId ?? el._id,
          itemVersion: el.itemVersion ?? el._v,
        })),
      });
      await getQuestionnaire.refetch();
      notification.success({ message: content });
    },
    {
      onError: __DEPRECATED__ErrorHandler,
    },
  );

  const deleteQuestionnaireItem = useMutation(
    async (itemId) => apiClient.delete(`/questionnaire/${itemId}`),
    {
      onSuccess: () => {
        getQuestionnaire.refetch();
        notification.success({ message: "Item deleted" });
      },
      onError: __DEPRECATED__ErrorHandler,
    },
  );

  const { mutateAsync: exportQuestionnaire, isLoading: isExporting } =
    useMutation(
      () => {
        return apiClient.get(`/questionnaire/${id}/export`);
      },
      {
        onSuccess: (response) => {
          const data = JSON.stringify(response.data, null, 2);
          const file = new Blob([data], {
            type: "application/json",
          });
          saveAs(
            file,
            `${new Date().toISOString().split("T")[0]}-${
              getQuestionnaire.data.label
            }.json`,
            { autoBom: true },
          );
        },
        onError: __DEPRECATED__ErrorHandler,
      },
    );

  const { mutateAsync: deleteQuestionnaire, isLoading: isDeleting } =
    useMutation(() => apiClient.delete(`/questionnaire/${id}`), {
      onSuccess: () => navigate("/questionnaire"),
      onError: __DEPRECATED__ErrorHandler,
    });

  if (!getQuestionnaire.data?._id) {
    return <Skeleton />;
  }

  return (
    <Card
      extra={
        <Space>
          <ItemBuilderModal
            actions={{
              editQuestionnaireItem,
            }}
            buttonLabel="Edit"
            disabled={!islatestVersion}
            item={getQuestionnaire.data}
            mode="edit"
          />
          <Button
            data-testid={isPublished ? "Unpublish" : "Publish"}
            disabled={
              getQuestionnaire?.isLoading ||
              getQuestionnaire?.isRefetching ||
              !getQuestionnaire?.data?.items?.length ||
              !islatestVersion
            }
            icon={isPublished ? "exclamation" : "confirmOutline"}
            loading={togglePublishQuestionnaire.isLoading}
            noPadding
            onClick={togglePublishQuestionnaire.mutateAsync}
            type="link"
          >
            {isPublished ? "Unpublish" : "Publish"}
          </Button>
          <Button
            data-testid="Export"
            disabled={!islatestVersion}
            icon="download"
            loading={isExporting}
            noPadding
            onClick={exportQuestionnaire}
            type="link"
          >
            Export
          </Button>
          <Modal
            hideFooterBorder
            hideHeaderBorder
            loading={isDeleting}
            onOk={deleteQuestionnaire}
            title="Delete questionnaire"
            trigger={
              <Button
                danger
                data-testid="Delete"
                disabled={!islatestVersion}
                icon="delete"
                noPadding
                type="link"
              >
                Delete
              </Button>
            }
          >
            Are you sure that you want to delete this questionnaire?
          </Modal>
        </Space>
      }
      isLoading={getQuestionnaire.isLoading}
      title={getQuestionnaire.data.label}
    >
      {!!getQuestionnaire.data.profileName && (
        <Owner data-testid="profileName">
          Owned by {getQuestionnaire.data.profileName}
        </Owner>
      )}
      <CollapseStyled
        expandIconPosition={"end"}
        ghost
        items={[
          {
            key: "1",
            label: `Version ${
              requestedVersion ?? getQuestionnaire.data.latestVersion
            }`,
            children: (
              <PaginationStyled
                current={Number(
                  requestedVersion ?? getQuestionnaire.data.latestVersion,
                )}
                onChange={async (newVersion) => {
                  const queryString = qs.stringify(
                    {
                      ...qs.parse(location.search, {
                        ignoreQueryPrefix: true,
                      }),
                      version: newVersion,
                    },
                    { arrayFormat: "comma" },
                  );
                  await setRequestedVersion(Number(newVersion));
                  await navigate(`?${queryString}`);
                  getQuestionnaire.refetch();
                }}
                pageSize={1}
                showQuickJumper
                showSizeChanger={false}
                total={getQuestionnaire.data.latestVersion}
              />
            ),
          },
        ]}
      />
      {!!getQuestionnaire.data.description?.length && (
        <>
          <Card.Meta
            description={
              <FormatText data-testid="QuestionnaireDescription">
                {getQuestionnaire.data.description}
              </FormatText>
            }
          />
          <Spacer />
        </>
      )}

      <QuestionnaireNavigator
        actions={{
          addQuestionnaireItem,
          deleteQuestionnaireItem,
          editQuestionnaireItem,
          moveQuestionnaireItem,
          getQuestionnaire,
          getQuestionnaires,
          onClick: (id) => {
            const selectedDiv = document.getElementById(id);
            selectedDiv?.scrollIntoView?.({ behavior: "smooth" });
          },
        }}
        filterEmptyMenu
        isEditable={true}
        isLoading={getQuestionnaire.isLoading}
        questionnaire={getQuestionnaire.data}
        showBuilder={islatestVersion}
        showInfo={false}
      />
    </Card>
  );
}

export default CustomQuestionnaireEdit;
