import { Form, Upload } from "antd";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import React, { useState } from "react";
import styled from "styled-components";

import {
  Button,
  Card,
  getUIConfig,
  ItemOwnerForm,
  notification,
} from "@gfw/corvus";
import { selectorUsersMeToken } from "@gfw/backend-connector";

import { Page, PageHeader } from "@src/resources/generic/components";

const Description = styled.div`
  margin-top: 20px;
  margin-bottom: 30px;
`;

const { GFW_API_URL } = getUIConfig();

function CustomQuestionnaireImport() {
  const [form] = Form.useForm();
  const token = useSelector(selectorUsersMeToken);

  const [questionnaireSettings, setQuestionnaireSettings] = useState();
  const navigate = useNavigate();

  const onChange = (info) => {
    if (info.file.status === "done") {
      notification.success({
        message: `${info.file.name} file uploaded successfully`,
      });
    } else if (info.file.status === "error") {
      notification.error({
        message: "Oops, something went wrong",
        description: `${info.file.name} file upload failed.`,
      });
    }
    if (info.file.response?.questionnaireOId) {
      navigate(`/questionnaire/${info.file.response.questionnaireOId}`);
    }
  };

  return (
    <Page>
      <PageHeader iconType="document">Import questionnaire</PageHeader>
      <Card>
        {!questionnaireSettings && (
          <>
            <Form
              autoComplete="off"
              form={form}
              layout="vertical"
              onFinish={(values) => setQuestionnaireSettings(values)}
            >
              <ItemOwnerForm form={form} />
              <Button htmlType="submit" loading={false} type="primary">
                Next
              </Button>
            </Form>
          </>
        )}
        {questionnaireSettings && (
          <>
            <Button
              noPadding
              onClick={() => setQuestionnaireSettings(undefined)}
              type="link"
            >
              &lt; Back
            </Button>
            <Description>
              Please select the questionnaire, it will be uploaded as{" "}
              <b> {questionnaireSettings.name ?? "shared"} </b>
            </Description>
            <Upload
              accept={".json"}
              action={`${GFW_API_URL}/questionnaire/import`}
              data={(data) => {
                if (questionnaireSettings.profileOId) {
                  data.profileOId = questionnaireSettings.profileOId;
                }
                return data;
              }}
              headers={{
                Authorization: `Bearer ${token}`,
              }}
              onChange={onChange}
            >
              <Button icon="upload">Select and import</Button>
            </Upload>
          </>
        )}
      </Card>
    </Page>
  );
}

export default CustomQuestionnaireImport;
