import React from "react";
import { ListBox, Input, InputGroup, Icon } from "../../components";

function useSelect({ onSelect, value, defaultValue }) {
  const [selection, updateSelection] = React.useState(value || defaultValue);

  const onSelectionChange = React.useCallback(
    (newValue) => {
      updateSelection(newValue);
      onSelect && onSelect(newValue);
    },
    [onSelect],
  );

  return React.useMemo(
    () => ({
      selection,
      onSelectionChange,
    }),
    [onSelectionChange, selection],
  );
}

const SelectInput = React.forwardRef(function SelectInput(
  { children, size = "md", width, ...props },
  forwardedRef,
) {
  return (
    <InputGroup
      ref={forwardedRef}
      variant="outline"
      cursor="pointer"
      height="auto"
      min-height={InputGroup.defaultProps.height}
      min-width={width || size}
      max-width="fit-content"
      outline="none"
      {...props}
    >
      <Input as="div">{children}</Input>
      <Icon fs="1.2em" mr="8px">
        caret-down
      </Icon>
    </InputGroup>
  );
});

const Select = React.forwardRef(function Select(
  {
    children,
    placeholder,
    onSelect,
    optionToString,
    onBlur,
    size,
    value,
    defaultValue,
    ...props
  },
  forwardedRef,
) {
  const { onSelectionChange, selection } = useSelect({
    onSelect,
    value,
    defaultValue,
  });

  return (
    <ListBox onChange={onSelectionChange} value={selection}>
      <ListBox.Button
        ref={forwardedRef}
        as={SelectInput}
        size={size}
        onBlur={onBlur}
        {...props}
      >
        {selection || selection === 0 ? optionToString(selection) : placeholder}
      </ListBox.Button>
      <ListBox.Overlay>
        <ListBox.List>{children}</ListBox.List>
      </ListBox.Overlay>
    </ListBox>
  );
});

Select.Option = ListBox.Option;

Select.defaultProps = {
  optionToString: (v) => String(v),
};

export { Select };
