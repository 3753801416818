import React from "react";
import { Box } from "../Box";
import { Button } from "../Button";
import { useComposedRef } from "../../core";
import { useListBox, ListBoxEvent } from "./ListBox";

const ListBoxButton = React.forwardRef(function Component(
  { children, ...props },
  forwardedRef,
) {
  const {
    dispatch,
    isExpanded,
    isDisabled,
    state,
    refs: { button: buttonRef },
  } = useListBox();

  const ref = useComposedRef(buttonRef, forwardedRef);

  let label = React.useMemo(() => {
    // If the button has children, we just render them as the label
    if (children) {
      return children;
    } else {
      return String(state.context.value);
    }
  }, [children, state.context.value]);

  function onClickHandler() {
    dispatch({
      type: ListBoxEvent.BUTTON_MOUSE_DOWN,
    });
  }

  return (
    <Box
      ref={ref}
      aria-haspopup="listbox"
      aria-expanded={isExpanded}
      tabIndex={isDisabled ? -1 : 0}
      role="button"
      onClick={onClickHandler}
      {...props}
    >
      {label}
    </Box>
  );
});

ListBoxButton.defaultProps = {
  as: Button,
};

export { ListBoxButton };
