import React from "react";

import {
  NewsArticleList,
  NewsProviderList,
  RssFeedList,
} from "@src/resources/News/components";
import { Page, PageHeader } from "@src/resources/generic/components";

function NewsPage() {
  return (
    <Page>
      <PageHeader iconType="news">News</PageHeader>
      <NewsProviderList />
      <NewsArticleList />
      <RssFeedList />
    </Page>
  );
}

export { NewsPage };
