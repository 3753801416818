import { Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import React from "react";

import {
  __DEPRECATED__ErrorHandler,
  Button,
  FormInput,
  Loading,
  NavigateLink,
  notification,
} from "@gfw/corvus";
import {
  apiClient,
  selectorUsersByOId,
  userGetByOId,
} from "@gfw/backend-connector";
import { GfwJoi } from "@gfw/core";

function EditUser() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const user = useSelector(selectorUsersByOId(id));
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const { mutateAsync: submitChanges, isLoading } = useMutation(
    (values) => apiClient.put(`/users/${id}`, values),
    {
      onError: __DEPRECATED__ErrorHandler,
      onSuccess: () => {
        dispatch(userGetByOId({ userOId: id }));
        notification.success({ message: "Name updated" });
        navigate(`/users/${id}/read`, { replace: true });
      },
    },
  );

  if (user.isLoading) {
    return <Loading size="small" />;
  }

  return (
    <>
      <NavigateLink icon="undo" noPadding to={`/users/${id}/read`}>
        Undo
      </NavigateLink>
      <>
        <Form
          form={form}
          initialValues={{
            lastName: user?.name.last,
            firstName: user?.name.first,
            username: user?.username,
          }}
          layout="vertical"
          onFinish={submitChanges}
        >
          <FormInput
            autoComplete="firstname"
            disabled={isLoading}
            label="First name"
            name="firstName"
            rules={[
              {
                required: true,
                message: "Required",
              },
              {
                validator: (_, value) => {
                  if (value.trim()) return Promise.resolve();
                  return Promise.reject(new Error("Required"));
                },
              },
            ]}
          />
          <FormInput
            autoComplete="lastname"
            disabled={isLoading}
            label="Last name"
            name="lastName"
            rules={[
              {
                required: true,
                message: "Required",
              },
              {
                validator: (_, value) => {
                  if (value.trim()) return Promise.resolve();
                  return Promise.reject(new Error("Required"));
                },
              },
            ]}
          />
          <FormInput
            autoComplete="username"
            disabled={isLoading}
            label="Username"
            name="username"
            rules={[
              {
                required: true,
                message: "Required",
              },
              {
                validator: (_, value) => {
                  if (value.trim()) return Promise.resolve();
                  return Promise.reject(new Error("Required"));
                },
              },
              {
                validator: (_, value) => {
                  const emailValidation = GfwJoi.string()
                    .tldEmail()
                    .validate(value);
                  if (!emailValidation.error) return Promise.resolve();
                  return Promise.reject(
                    new Error("Please enter a valid email"),
                  );
                },
              },
            ]}
          />
          <Button
            block
            data-testid="submit"
            loading={isLoading}
            onClick={form.submit}
            type="primary"
          >
            Update
          </Button>
        </Form>
      </>
    </>
  );
}

export default EditUser;
