import { css } from "styled-components";
const shortHandCSSProperties = {
  m: "margin",
  p: "padding",
};

export const directions = {
  t: "top",
  r: "right",
  b: "bottom",
  l: "left",
  x: ["left", "right"],
  y: ["top", "bottom"],
};

export function mapShortHandToCssProperties(key) {
  // maps m => ["margin"]
  // OR
  // maps my => ["margin-left", "margin-right"]

  // split pt (and other abbrevations) to p+t, e.g. shorthand + direction
  const [abbr, dir] = key.split("");

  // map m => margin, p => padding, b => border-width ...etc
  const property = shortHandCSSProperties[abbr];

  // maps t => top, y => [top bottom], etc
  const direction = directions[dir];

  if (direction) {
    return Array.isArray(direction)
      ? direction.map((d) => `${property}-${d}`)
      : [`${property}-${direction}`];
  } else {
    return [property];
  }
}

export function mapToCssProp(prop) {
  // e.g. ["grid-row-start", "-ms-grid-row"]
  if (Array.isArray(prop)) {
    return (value) => {
      return prop.reduce((prev, key) => {
        return [
          ...prev,
          css`
            ${key}: ${value};
          `,
        ];
      }, []);
    };
  }

  return (value) => css`
    ${prop}: ${value};
  `;
}

function getStyleProp(prop) {
  if (prop.startsWith("$")) {
    return prop.substring(1);
  }

  return prop;
}
export function mixin(
  prop,
  mapPropAndValueToCss = (value) => ({ [getStyleProp(prop)]: value }), // default
) {
  return function (props) {
    const value = props[prop];
    if (value) {
      return mapPropAndValueToCss(value);
    } else {
      return null;
    }
  };
}

const RELATIVE_SIZE = new RegExp(/\dx$/); // should match "1.5x" but not "15px"

export function getIsMaybeRelativeSize(size) {
  if (RELATIVE_SIZE.test(size)) {
    return `${size.split("x")[0]}em`;
  } else {
    return size;
  }
}
