import { getSpace } from "../../space/space";

export function mapToColumnGap(value) {
  const gap = getSpace(value);
  return {
    "grid-column-gap": gap,
    "column-gap": gap,
  };
}

export function mapToTemplateColumns(value) {
  let cssValue = value;

  // e.g. grid-template-columns={3} => you want 3 columns all with the same size
  if (Number.isInteger(value)) {
    cssValue = `repeat(${value}, 1fr)`;
  }

  return {
    "grid-template-columns": cssValue,
  };
}
