import { get } from "lodash";
import { mapShortHandToCssProperties } from "../utils";

const REG = /^[mp][trblxy]?$/;
export const scale = {
  0: "0",
  1: "0.25rem",
  2: "0.5rem",
  3: "1rem",
  4: "2rem",
  5: "4rem",
  6: "8rem",
  7: "16rem",

  // new scale
  none: 0,
  xs: "4px",
  sm: "8px",
  md: "12px",
  lg: "16px",
  lg2: "24px",
  xl: "32px",
};

function transformStyleValue(value) {
  const isNegative = value < 0;
  const absolute = Math.abs(value);
  if (isNaN(absolute)) {
    return getSpace(value);
  } else {
    return isNegative ? `-${getSpace(absolute)}` : getSpace(value);
  }
}
export function mapPropsToSpace(props) {
  // get all prop keys that corresponds to either m* || p*
  const keys = Object.keys(props)
    .filter((key) => REG.test(key))
    // sort alphabetically descending
    .sort((a, b) => {
      if (a > b) {
        return -1;
      }
      if (b > a) {
        return 1;
      }

      return 0;
    })
    // and sort them again by length
    .sort((a, b) => a.length - b.length);

  return keys.map((key) => {
    const value = props[key]; // 0...7,

    // maps m => ["margin"], mt => ["margin-top"], mx => ["margin-left", "margin-right"] etc
    // OR
    // maps p => ["padding"], py => ["padding-top", "padding-bottom"] etc
    const properties = mapShortHandToCssProperties(key);

    // IN: ["margin-left", "margin-right"]
    // OUT: { margin-left: value; margin-right: value; }
    return properties.reduce(
      (prev, prop) => ({
        ...prev,
        [prop]: transformStyleValue(value),
      }),
      {},
    );
  });
}

export function getSpace(value) {
  return get(scale, value, value);
}
