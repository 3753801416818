import { Table } from "antd";
import React from "react";

import { Button, NavigateLink } from "@gfw/corvus";
import { FlexBox, Text } from "@gfw/orion";

import buildDataSource from "./overviewBuild";

function OverviewMerge({ profileToRemove, profileToKeep, nextStep }) {
  const columns = [
    {
      title: "Stat",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Will be removed after merge",
      dataIndex: profileToRemove.id,
      key: profileToRemove.id,
    },
    {
      title: "Will be kept",
      dataIndex: profileToKeep.id,
      key: profileToKeep.id,
    },
  ];

  return (
    <>
      <Text fs={3} mb="lg">
        Overview
      </Text>
      <Table
        columns={columns}
        dataSource={buildDataSource(profileToRemove, profileToKeep)}
        pagination={false}
        rowKey="name"
        size="small"
      />
      <FlexBox justify="space-around" mt="lg">
        <NavigateLink
          to={`/profiles/merge/${profileToKeep.id}/${profileToRemove.id}`}
        >
          Switch profiles
        </NavigateLink>
        <Button
          onClick={nextStep}
          style={{ width: 150, marginRight: 15 }}
          type="primary"
        >
          Next
        </Button>
      </FlexBox>
    </>
  );
}

export default OverviewMerge;
