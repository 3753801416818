// ref: https://material.io/design/color/the-color-system.html#color-usage-palettes
export const gray = {
  50: "#fafafa",
  100: "#f5f5f5",
  200: "#eeeeee",
  300: "#e0e0e0",
  400: "#bdbdbd",
  500: "#9e9e9e",
  600: "#757575",
  700: "#616161",
  800: "#424242",
  900: "#212121",
  A100: "#d5d5d5",
  A200: "#aaaaaa",
  A400: "#303030",
  A700: "#616161",
};

export const red = {
  50: "#ffebee",
  60: "#fedddd",
  100: "#ffcdd2",
  200: "#ef9a9a",
  300: "#e57373",
  400: "#ef5350",
  500: "#f44336",
  600: "#e53935",
  700: "#d32f2f",
  800: "#c62828",
  900: "#b71c1c",
  A100: "#ff8a80",
  A200: "#ff5252",
  A400: "#ff1744",
  A700: "#d50000",
};

export const green = {
  50: "#E8F5E9",
  100: "#C8E6C9",
  200: "#A5D6A7",
  300: "#81C784",
  400: "#66BB6A",
  500: "#4CAF50",
  600: "#43A047",
  700: "#388E3C",
  800: "#2E7D32",
  900: "#1B5E20",
  A100: "#B9F6CA",
  A200: "#69F0AE",
  A400: "#00E676",
  A700: "#00C853",
};

export const orange = {
  50: "#f7ebe9",
  100: "#f7d0c3",
  200: "#f2b39c",
  300: "#ef9776",
  400: "#ed8258",
  500: "#ec6f3e",
  600: "#e1683a",
  700: "#d36236",
  800: "#c45c33",
  900: "#a9512f",
};

// #42a5ac
export const gfw = {
  10: "#e9f4f5",
  50: "#e1f6f6",
  100: "#b6e9e9",
  200: "#8adadc",
  300: "#63cbce",
  400: "#4fc0c5",
  500: "#46b5be",
  600: "#42a5ac",
  700: "#3d9094",
  800: "#387c7d",
  900: "#2f5856",
};

// common colors
const common = {
  white: "#ffffff",
  black: "#333333",
  success: green.A700,
  error: "#ff4d4f",
  info: "#0288D1",
  warning: "#FAAD14",
};

// text colors
export const text = {
  primary: "rgba(0, 0, 0, 0.87)",
  secondary: "rgba(0, 0, 0, 0.54)",
  disabled: "rgba(0, 0, 0, 0.38)",
  hint: "rgba(0, 0, 0, 0.38)",
  on: {
    dark: "rgb(239, 240, 240)",
    light: "rgba(0, 0, 0, 0.87)",
  },
};

const action = {
  disabled: {
    text: "rgba(0, 0, 0, 0.26)",
    background: "rgba(0, 0, 0, 0.12)",
  },
  highlighted: "rgba(200,200,200, 0.2)",
};

const primary = {
  superLight: gfw[10],
  light: gfw[300],
  main: gfw[600],
  dark: gfw[700],
  text: common.white,
};

const secondary = {
  light: orange[300],
  main: orange[600],
  dark: orange[700],
  text: "#eff0f0",
};

export const COLORS = {
  gfw,
  gray,
  green,
  orange,
  red,
};

const colors = {
  ...common,
  gfw,
  red,
  gray,
  green,
  orange,
  border: gray[300],
  primary,
  secondary,
  surface: common.white,
  text,
  action,
  none: "transparent",
  inherit: "inherit",
  current: "currentColor",
  disabled: "#C7C7C7",
};

export default colors;
