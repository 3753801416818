import { Flex } from "antd";
import React from "react";

function Page({ children }) {
  return (
    <Flex gap="small" vertical>
      {children}
    </Flex>
  );
}

export { Page };
