import { Select, Typography } from "antd";
import React from "react";

import { apiClient } from "@gfw/backend-connector";
import { FlexBox } from "@gfw/orion";
import { getUserRoles } from "@gfw/core";
import { Icon } from "@gfw/corvus";

import { useProfile } from "@src/resources/Profile/profile-context";

const { Text } = Typography;

function ProfileRolePicker({ onSelect }) {
  const handleSelectProfileUserType = (role) => {
    onSelect(role);
  };

  return (
    <Select
      onSelect={handleSelectProfileUserType}
      placeholder={<Text>Add to profile</Text>}
    >
      {getUserRoles().map((role, index) => (
        <Select.Option key={`${role}::${index}`} value={role}>
          {role}
        </Select.Option>
      ))}
    </Select>
  );
}

export function UserSearchResultAction({ onUserAddedToProfile, ...user }) {
  const profile = useProfile();
  const [state, setState] = React.useState({
    status: null,
    data: {},
  });

  async function addUserToProfile(role) {
    try {
      const response = await apiClient.post(`/profiles/${profile.id}/users`, {
        userOId: user.id,
        role: role,
      });

      setState({
        status: response.status,
        data: response.data,
      });
      onUserAddedToProfile(user.id);
    } catch (error) {
      // TODO: handle this error state...show something?
      setState({
        status: error.response.status,
        data: error.response.data,
      });
    }
  }

  return (
    <FlexBox width="120px">
      {state.status && state.status === 200 ? (
        <Icon color="success" fs="1.5em" type="confirmOutline" />
      ) : (
        <ProfileRolePicker onSelect={addUserToProfile} />
      )}
    </FlexBox>
  );
}
