import { useQuery } from "react-query";
import gql from "graphql-tag";
import React from "react";

import { apiClient } from "@gfw/backend-connector";
import { Grid } from "@gfw/corvus";
import { PROFILE_STATE } from "@gfw/core";

import { Card } from "./Card";
import { DueDiligenceAnalytics } from "./DueDiligenceAnalytics";
import { Page, PageHeader } from "@src/resources/generic/components";
import { WarningBanner } from "./WarningBanner";

const LIST_SIZE = 10;

const OverviewQuery = gql`
  query OverviewQuery($DRAFT: Int, $PROPOSED: Int) {
    draftProfiles: Profiles(where: { state: $DRAFT }) {
      edges {
        node {
          id
          name
          location
          types
        }
      }
    }
    proposedProfiles: Profiles(where: { state: $PROPOSED }) {
      edges {
        node {
          id
          name
          location
          types
        }
      }
    }
  }
`;

function OverviewPage() {
  const { data, isLoading: overviewLoading } = useQuery(["overview"], () => {
    return apiClient.graphql(OverviewQuery, {
      variables: {
        first: LIST_SIZE,
        DRAFT: PROFILE_STATE.DRAFT,
        PROPOSED: PROFILE_STATE.PROPOSED,
      },
    });
  });
  const { draftProfiles, proposedProfiles } = data || {};

  return (
    <Page>
      <WarningBanner />
      <PageHeader iconType="dashboard">Overview</PageHeader>
      <Grid.Row gutter={[16, 16]}>
        {!!proposedProfiles?.edges?.length && (
          <Grid.Col sm={8} xs={24}>
            <Card
              isLoading={overviewLoading}
              profiles={proposedProfiles?.edges}
              title={{ main: "Proposed profiles ", left: "name" }}
            />
          </Grid.Col>
        )}
        {!!draftProfiles?.edges?.length && (
          <Grid.Col sm={8} xs={24}>
            <Card
              isLoading={overviewLoading}
              profiles={draftProfiles?.edges}
              title={{ main: "Draft Profiles", left: "name" }}
            />
          </Grid.Col>
        )}
        <Grid.Col sm={8} xs={24}>
          <DueDiligenceAnalytics />
        </Grid.Col>
      </Grid.Row>
    </Page>
  );
}

export default OverviewPage;
