import React from "react";
import { Text } from "../Text";
import { useFormControl } from "./FormControl";

const FormControlLabel = React.forwardRef(function FormControlLabel(
  { children, htmlFor, ...props },
  ref,
) {
  const formControl = useFormControl(props);

  return (
    <Text
      ref={ref}
      typography="form-label"
      as="label"
      display="inline-block"
      htmlFor={htmlFor || formControl.id}
      cursor="default"
      {...props}
    >
      {children}
    </Text>
  );
});

export { FormControlLabel };
