import { css } from "styled-components";
import { getFontWeight } from "../modules/typography";
import { getColor } from "../color";
import { has, get } from "lodash";
import warning from "tiny-warning";

// src: https://material-ui.com/customization/default-theme/
// live demo: https://material.io/design/typography/the-type-system.html#type-scale
export const typographies = {
  h1: css`
    font-size: 6rem;
    line-height: 1;
    font-weight: ${getFontWeight("light")};
    letter-spacing: -0.01562em;
  `,
  h2: css`
    font-size: 3.75rem;
    line-height: 1;
    font-weight: ${getFontWeight("light")};
    letter-spacing: -0.00833em;
  `,
  h3: css`
    font-size: 3rem;
    line-height: 1.04;
    font-weight: ${getFontWeight("regular")};
    letter-spacing: 0em;
  `,
  h4: css`
    font-size: 2.125rem;
    line-height: 1.17;
    font-weight: ${getFontWeight("regular")};
    letter-spacing: 0.00735em;
  `,
  h5: css`
    font-size: 1.5rem;
    line-height: 1.33;
    font-weight: ${getFontWeight("regular")};
    letter-spacing: 0em;
  `,
  h6: css`
    font-size: 1.25rem;
    line-height: 1.6;
    font-weight: ${getFontWeight("medium")};
    letter-spacing: 0.0075em;
  `,
  body: css`
    font-size: 1rem;
    line-height: 1.5;
    font-weight: ${getFontWeight("regular")};
    letter-spacing: 0.00938em;
  `,
  "body-2": css`
    font-size: 0.875rem;
    line-height: 1.5;
    font-weight: ${getFontWeight("regular")};
    letter-spacing: 0.01071em;
  `,
  "body-small": css`
    font-size: 0.75rem;
    line-height: 1.5;
    font-weight: ${getFontWeight("regular")};
    letter-spacing: 0.01071em;
  `,
  subtitle: css`
    font-size: 1rem;
    line-height: 1.75;
    font-weight: ${getFontWeight("regular")};
    letter-spacing: 0.00938em;
  `,
  "subtitle-2": css`
    font-size: 0.875rem;
    line-height: 1.57;
    font-weight: ${getFontWeight("medium")};
    letter-spacing: 0.00714em;
  `,
  caption: css`
    font-size: 0.75rem;
    line-height: 1.66;
    font-weight: ${getFontWeight("regular")};
    letter-spacing: 0.03333em;
  `,
  button: css`
    font-size: 0.875rem;
    line-height: 1.75;
    font-weight: ${getFontWeight("medium")};
    letter-spacing: 0.02857em;
    text-transform: uppercase;
  `,
  overline: css`
    font-size: 0.75rem;
    line-height: 2.66;
    font-weight: ${getFontWeight("regular")};
    letter-spacing: 0.08333em;
    text-transform: uppercase;
  `,
  fieldLabel: css`
    font-size: 0.75rem;
    line-height: 2.66;
    font-weight: ${getFontWeight("regular")};
    letter-spacing: 0.00714em;
  `,
  fieldValue: css`
    font-size: 0.75rem;
    line-height: 1.57;
    font-weight: ${getFontWeight("bold")};
    letter-spacing: 0.00714em;
  `,
  "form-label": css`
    font-size: 0.75rem;
    font-weight: ${getFontWeight("regular")};
    letter-spacing: 0.00714em;
    color: ${getColor("gray.600")};
  `,
  heading: css`
    font-size: 16px;
    font-weight: ${getFontWeight("bold")};
    line-height: 1;
  `,
};

export function getTypography(typography) {
  warning(
    has(typographies, typography),
    `[@gfw/orion/style] Trying to use typography ("${typography}") but that typography was not found, check included typographies.`,
  );

  return typographies[typography];
}

export function createTypography(typography, options = {}) {
  warning(
    Object.keys(options).length > 0,
    `[@gfw/orion/typography] Trying to create a typography without any options. You can probably use "getTypography(typography)" instead.`,
  );

  const supportedStyleFunctions = {
    "font-weight": getFontWeight,
    color: getColor,
  };

  return Object.entries(options).reduce((prev, next) => {
    const [key, value] = next;

    const fn = get(supportedStyleFunctions, key, (x) => x);

    return [
      ...prev,
      css`
        ${key}: ${fn(value)};
      `,
    ];
  }, getTypography(typography));
}
