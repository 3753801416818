import { mapPropsToBorderRadius, getBorderRadius } from "./border-radius";
import { mapPropsToBorder } from "./border";
import { css } from "styled-components";

// merge 2 utilities into 1
const border = css`
  ${mapPropsToBorder};
  ${mapPropsToBorderRadius};
`;

export default border;

export { getBorderRadius, mapPropsToBorder, mapPropsToBorderRadius };
