import { has } from "lodash";
import { css } from "styled-components";

const WORD_BREAKS = {
  truncate: css`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `,
};

const isScaleValue = (value) => has(WORD_BREAKS, value);

export function mapToWordBreak(value) {
  if (isScaleValue(value)) {
    return WORD_BREAKS[value];
  } else {
    return {
      "word-break": value,
    };
  }
}
