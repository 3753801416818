import React from "react";

/**
 * Creates a React Context and returns the Provider and a useContext hook.
 *
 */
function createAndUseContext(options = {}) {
  const { required = true, name } = options;

  const Context = React.createContext();

  function useContext() {
    const context = React.useContext(Context);
    if (!context && required) {
      let message = name
        ? `use${name} must be used together with the ${name}Provider`
        : "useContext can only be used together with a Provider";
      throw new Error(message);
    }
    return context;
  }

  return [Context.Provider, useContext, Context];
}

export { createAndUseContext };
