import React from "react";
import { createPopper } from "@popperjs/core";
import { Portal } from "reakit/Portal";
import {
  useComboBox,
  useOnKeyDownEventHandler,
  useOnBlurEventHandler,
} from "./ComboBox";
import { Box } from "../Box";
import { useComposedRef } from "../../core";

const ComboBoxOverlay = React.forwardRef(function ComboBoxOverlay(
  { children, ...props },
  forwardedRef,
) {
  const { overlayRef, inputRef, isSuggesting } = useComboBox();
  const ref = useComposedRef(overlayRef, forwardedRef);
  const [styles, setStyles] = React.useState({});
  const popper = React.useRef(null);
  const onKeyDownHandler = useOnKeyDownEventHandler();
  const onBlurEventHandler = useOnBlurEventHandler();

  React.useEffect(() => {
    popper.current = createPopper(inputRef.current, overlayRef.current, {
      placement: "bottom-start",
      strategy: "absolute",
      modifiers: [
        {
          name: "applyStyles",
          enabled: false,
        },
        {
          name: "offset",
          options: {
            offset: [0, -2], // SETTABLE??
          },
        },
        {
          name: "flip",
          enabled: true,
        },
        {
          name: "updateState",
          phase: "write",
          enabled: true,
          fn({ state }) {
            setStyles({
              ...state.styles.popper,
              minWidth: state.rects.reference.width,
            });
          },
        },
      ],
    });
    return () => {
      if (popper.current) {
        popper.current.destroy?.();
        popper.current = null;
      }
    };
  }, [inputRef, overlayRef]);

  React.useEffect(() => {
    if (popper.current) {
      popper.current.forceUpdate();
    }
  }, [isSuggesting]);

  return (
    <Portal onKeyDown={onKeyDownHandler} onBlur={onBlurEventHandler}>
      <Box
        tabIndex={-1}
        hidden={!isSuggesting}
        ref={ref}
        {...props}
        style={styles}
      >
        {children}
      </Box>
    </Portal>
  );
});

ComboBoxOverlay.defaultProps = {
  "box-shadow": "xl",
  border: { color: "gfw.700" },
  bg: "white",
  "z-index": "overlay",
};

export { ComboBoxOverlay };
