import { Input } from "antd";
import styled from "styled-components";

import { colors, FONT_SIZES, FONT_WEIGHTS } from "@gfw/corvus";

export const StyledLabel = styled.label`
  white-space: inherit;
  font-size: ${FONT_SIZES.xs};
  font-weight: ${FONT_WEIGHTS.regular};
  color: ${colors.gray[600]};
  margin: 0;
`;
export const StyledLabelSmall = styled(StyledLabel)`
  font-size: 10px;
`;

export const StyledInput = styled(Input)`
  min-width: 64px;
`;
