import React from "react";
import ReactJson from "react-json-view";

function ProfileMergeHistory({ profile }) {
  return (
    <ReactJson
      collapsed={1}
      displayDataTypes={false}
      name="Merge History"
      src={profile.mergeHistory}
    />
  );
}

export default ProfileMergeHistory;
