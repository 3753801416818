import React from "react";

/**
 * simpler form of the useReducer hook especially useful for setState with objects
 *
 * @returns [state, setState]
 * @example const [state, setState] = useSetState({
    foo: null,
    bar: false,
  });
  setState({bar: true}) // => {foo: null, bar: true}
 */
export function useSetState(initialState) {
  return React.useReducer(
    (state, newState) => ({ ...state, ...newState }),
    initialState,
  );
}

/**
 * the useSetState hook that only updates the state while the component is mounted.
 * Useful for async state operations.
 *
 * @returns [state, setState]
 */
export function useSafeSetState(initialState) {
  const [state, setState] = useSetState(initialState);

  const mountedRef = React.useRef(false);
  React.useEffect(() => {
    mountedRef.current = true;
    return () => (mountedRef.current = false);
  }, []);
  const safeSetState = (...args) => mountedRef.current && setState(...args);

  return [state, safeSetState];
}
