import React from "react";
import { Text } from "../Text";

const FormControlText = React.forwardRef(function Component(
  { children, ...props },
  ref,
) {
  return (
    <Text
      as="span"
      ref={ref}
      mt="4px"
      color="gray.500"
      display="inline-flex"
      align="center"
      {...props}
    >
      {children}
    </Text>
  );
});

export { FormControlText };
