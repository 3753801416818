import { Form, Input } from "antd";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";

import {
  __DEPRECATED__ErrorHandler,
  Button,
  Modal,
  notification,
} from "@gfw/corvus";
import { apiClient } from "@gfw/backend-connector";

function ActionRejectProposed({ profile }) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const showModal = () => setIsModalVisible(true);
  const handleCancel = () => setIsModalVisible(false);
  const handleSubmit = ({ reason }) => onDelete(reason);

  const { mutateAsync: onDelete } = useMutation(
    (reason) => {
      return apiClient.post(`/profiles/${profile.id}/delete`, {
        reason: reason.replace(/(?:\r\n|\r|\n)/g, "<br>"),
      });
    },
    {
      onSuccess: () => {
        notification.success({ message: `Profile rejected` });
        navigate("/profiles");
      },
      onError: __DEPRECATED__ErrorHandler,
    },
  );

  return (
    <Modal
      autoClose={false}
      hideFooterBorder
      hideHeaderBorder
      onCancel={handleCancel}
      onOk={form.submit}
      title="Reject reason"
      trigger={
        <Button block danger mb="md" onClick={showModal}>
          Reject
        </Button>
      }
      visible={isModalVisible}
    >
      <Form
        autoComplete="off"
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
      >
        <Form.Item
          label="Please explain why you want to reject this profile. It will be sent to the proposer"
          name="reason"
          required={false}
          rules={[
            {
              required: true,
              message: "Please type a reason",
            },
          ]}
        >
          <Input.TextArea rows="4" />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default ActionRejectProposed;
