import { Flex, Form } from "antd";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useState } from "react";

import {
  Button,
  Card,
  getColor,
  Icon,
  Logo,
  NavigateLink,
  notification,
  tracker,
} from "@gfw/corvus";
import { callbackManager, userLogin } from "@gfw/backend-connector";

import { Background, StyledFlex, StyledInput } from "./LoginPage.styles";

function LoginPage() {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const [{ username, password }, setState] = useState({
    username: "",
    password: "",
  });
  const location = useLocation();
  const navigate = useNavigate();

  const locationToRedirect =
    location.pathname && !location.pathname.includes("login")
      ? location.pathname
      : "/overview";

  const handleSubmit = () => {
    setIsLoading(true);

    const callbackId = callbackManager.add({
      onSuccess: (me) => {
        tracker.setUserId(me.userId);
        navigate(locationToRedirect, { replace: true });
        setIsLoading(false);
      },
      onError: ({ status }) => {
        if (status === 401) {
          notification.error({
            message: "Oops, something went wrong",
            description: "Email does not match the password",
          });
        } else if (status === 400) {
          notification.error({
            message: "Oops, something went wrong",
            description: "The format could be wrong, please check",
          });
        } else if ([429, 403, 500].includes(status)) {
          notification.error({
            message: "Oops, something went wrong",
          });
        }
        setIsLoading(false);
      },
    });

    dispatch(
      userLogin({
        username,
        password,
        isAdmin: true,
        callbackId,
      }),
    );
  };

  // show the login UI
  return (
    <Background>
      <Flex
        css={`
          width: 30vw;
          margin: 15vh auto;
        `}
        vertical
      >
        <Card
          styles={{
            header: {
              backgroundColor: getColor("gfw.600"),
              padding: "16px 8px",
            },
          }}
          title={
            <Flex align="center" justify="center">
              <Logo width="90%" />
            </Flex>
          }
        >
          <Form onFinish={handleSubmit}>
            <StyledFlex gap="middle" vertical>
              <StyledInput
                autoComplete="username"
                data-testid="email"
                id="username"
                onChange={(e) =>
                  setState((state) => {
                    return { ...state, username: e.target.value };
                  })
                }
                placeholder="admin username"
                prefix={<Icon fs="sm" mr="xs" type="user" />}
                type="email"
                value={username}
              />
              <StyledInput
                autoComplete="current-password"
                data-testid="password"
                id="password"
                onChange={(e) =>
                  setState((state) => {
                    return { ...state, password: e.target.value };
                  })
                }
                placeholder="admin password"
                prefix={<Icon fs="sm" mr="xs" type="password" />}
                type="password"
                value={password}
              />
              <NavigateLink fontSize="xs" noPadding to="/forgot">
                Forgot password?
              </NavigateLink>
              <Button
                block
                data-testid="submit"
                disabled={!!isLoading}
                htmlType="submit"
                onClick={form.submit}
                type="primary"
              >
                LOGIN
              </Button>
            </StyledFlex>
          </Form>
        </Card>
      </Flex>
    </Background>
  );
}

export default LoginPage;
