import { getSpace } from "../../space/space";

export function mapToRowGap(value) {
  const gap = getSpace(value);
  return {
    "grid-row-gap": gap,
    "row-gap": gap,
  };
}

export function mapToTemplateRows(value) {
  let cssValue = value;

  // e.g. grid-template-rows={3} => you want 3 rows all with the same size
  if (Number.isInteger(value)) {
    cssValue = `repeat(${value}, 1fr)`;
  }

  return {
    "grid-template-rows": cssValue,
  };
}
