// CSS Grid module
import { css } from "styled-components";
import { mixin } from "../../utils";
import { mapToTemplateColumns, mapToColumnGap } from "./columns";
import { mapToTemplateRows, mapToRowGap } from "./rows";
import { mapToGridTemplateAreas } from "./grid-template-areas";

// little utility that handles numbers and raw strings for grid stuff
function convertToCssValue(prop) {
  return function (value) {
    const number = Math.abs(value);

    if (isNaN(number)) {
      return { [prop]: value };
    } else {
      const isNegative = value < 0;
      return { [prop]: isNegative ? `-${number}` : `${number}` };
    }
  };
}

function spanFunction(prop) {
  return function (value) {
    const number = Math.abs(value);

    if (isNaN(number)) {
      return { [prop]: value };
    } else {
      return { [prop]: `span ${value}/span ${value}` };
    }
  };
}

const grid = css`
  ${mixin("grid-template-columns", mapToTemplateColumns)};
  ${mixin("grid-template-rows", mapToTemplateRows)};
  ${mixin("grid-template-areas", mapToGridTemplateAreas)};
  ${mixin("row-gap", mapToRowGap)};
  ${mixin("column-gap", mapToColumnGap)};
  ${mixin("grid-auto-flow")};
  ${mixin("grid-area")};
  ${mixin("grid-row")};
  ${mixin("grid-column", spanFunction("grid-column"))};
  ${mixin("grid-column-end", convertToCssValue("grid-column-end"))};
  ${mixin("grid-column-start", convertToCssValue("grid-column-start"))};
  ${mixin("grid-row", spanFunction("grid-row"))};
  ${mixin("grid-row-end", convertToCssValue("grid-row-end"))};
  ${mixin("grid-row-start", convertToCssValue("grid-row-start"))};
  ${mixin("justify-self")};
  ${mixin("justify-items")};
`;

export default grid;
