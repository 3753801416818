import React from "react";
import { Box } from "../Box";
import {
  useComboBox,
  useDescendant,
  ComboBoxDescendantsContext,
  event,
} from "./ComboBox";
import { composeEventHandlers, useComposedRef } from "../../core";
import { getColor } from "../../style";
import { toHashCode } from "@gfw/core";

const ComboBoxOption = React.forwardRef(function ComboBoxOption(
  { value, onClick, children, ...props },
  forwardedRef,
) {
  const { onSelect, selectedValue, dispatch } = useComboBox();
  const optionRef = React.useRef();
  const ref = useComposedRef(optionRef, forwardedRef);

  // this registers this option as a descendant
  useDescendant({
    context: ComboBoxDescendantsContext,
    element: optionRef.current,
    value,
  });

  const isActive = selectedValue === value;

  const onClickHandler = () => {
    onSelect(value);
    dispatch(event.SELECT);
  };

  return (
    <Box
      ref={ref}
      as="li"
      role="option"
      tabIndex={-1}
      aria-selected={isActive}
      onClick={composeEventHandlers(onClick, onClickHandler)}
      id={String(toHashCode(value))}
      css={`
        &[aria-selected="true"]:hover {
          background: ${(props) => getColor(props.pseudo.hover.bg)};
        }
      `}
      {...props}
    >
      {children}
    </Box>
  );
});

// default styling
ComboBoxOption.defaultProps = {
  p: "0.5em",
  cursor: "pointer",
  color: "text.secondary",
  pseudo: {
    selected: {
      bg: "primary.dark lighten-0.2",
      color: "primary.text",
    },
    hover: {
      bg: "primary.dark",
      color: "primary.text",
    },
  },
};

export { ComboBoxOption };
