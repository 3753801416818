import React from "react";

import { apiClient } from "@gfw/backend-connector";
import { Button, Modal, notification } from "@gfw/corvus";
import { Text } from "@gfw/orion";

import { useProfile } from "@src/resources/Profile/profile-context";

function RemoveUserFromProfileAction({ userOId, onActionCompleted }) {
  const { id } = useProfile();

  async function handleConfirm() {
    try {
      await apiClient.delete(`/profiles/${id}/users/${userOId}`);
      onActionCompleted && onActionCompleted(userOId);
    } catch (e) {
      notification.error({
        message: "Oops, something went wrong",
        description: "An error occurred while trying to remove the user",
      });
    }
  }

  return (
    <Modal
      hideFooterBorder
      hideHeaderBorder
      onOk={handleConfirm}
      title="Remove user from profile"
      trigger={
        <Button icon="unlink" tooltip="Remove a user from profile">
          Remove
        </Button>
      }
    >
      <Text>Please note that this will remove the user from this profile</Text>
    </Modal>
  );
}

export { RemoveUserFromProfileAction };
