import React from "react";
import { useComboBox } from "./ComboBox";
import { ComboBoxOption } from "./ComboBoxOption";
import { Box } from "../Box";
import { getComputedStyle } from "../../core";

const ComboBoxList = React.forwardRef(function ComboBoxList(
  { children, ...props },
  ref,
) {
  const { listboxId, inputRef } = useComboBox();
  const [styles, setStyles] = React.useState({});

  // we're going to "borrow" some styles from the input
  React.useEffect(() => {
    if (inputRef.current) {
      setStyles({
        font: getComputedStyle(inputRef.current, "font-size"),
        padding: getComputedStyle(inputRef.current, "padding-left"),
      });
    }
  }, [inputRef]);

  const items = React.Children.map(children, (child) => {
    if (child.type === ComboBoxOption) {
      // add the same padding as the input has so the options align better
      return React.cloneElement(child, {
        px: styles.padding, // TODO: if the option already has this set we should merge instead...
      });
    }
    return child;
  });

  return (
    <Box
      ref={ref}
      as="ul"
      role="listbox"
      id={listboxId}
      css={`
        list-style: none;
        user-select: none;
      `}
      font-size={parseFloat(styles.font)}
      {...props}
    >
      {items}
    </Box>
  );
});

export { ComboBoxList };
