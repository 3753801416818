import { Flex, Input } from "antd";
import { observer } from "mobx-react";
import { useQuery } from "react-query";
import gql from "graphql-tag";
import React from "react";

import { apiClient } from "@gfw/backend-connector";
import { Loading } from "@gfw/corvus";

import { StyledLabel, StyledSelect } from "./NewsArticleForm.styles";

const NEWS_PROVIDERS = gql`
  query NewsProviders {
    NewsProviders {
      id
      name
    }
  }
`;

const RssFeedForm = observer(function NewRssFeedForm({
  feed,
  isEditing = false,
}) {
  const { data: providers = [], isLoading } = useQuery(
    ["newsProviders"],
    async () => {
      const response = await apiClient.graphql(NEWS_PROVIDERS);

      if (response?.NewsProviders) {
        return response.NewsProviders;
      } else {
        return { error: "error" };
      }
    },
    { refetchOnWindowFocus: false },
  );

  if (isLoading || (isEditing && !feed.provider)) {
    return <Loading />;
  }

  const onSelectProvider = (providerId) =>
    feed.update(
      "provider",
      providers.find((p) => p.id === providerId),
    );

  return (
    <Flex gap="middle" vertical>
      <div>
        <StyledLabel>Url</StyledLabel>
        <Input
          onChange={(e) => {
            feed.update("url", e.target.value);
          }}
          required={true}
          value={feed.url}
        />
      </div>
      <div>
        <StyledLabel>Provider</StyledLabel>
        <StyledSelect
          defaultValue={feed.provider?.name}
          onSelect={onSelectProvider}
          options={providers.map((p) => ({
            label: p.name,
            value: p.id,
          }))}
          placeholder="Select a provider..."
        />
      </div>
    </Flex>
  );
});

export { RssFeedForm };
