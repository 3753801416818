function isSimpleString(value) {
  return !value.startsWith('"');
}
export function mapToGridTemplateAreas(value) {
  let cssValue = value;

  // e.g. grid-template-areas={["first", "second"]} => "first" "second"
  if (Array.isArray(value)) {
    cssValue = `${value.map((row) => `"${row}"`).join(" ")}`;
  } else if (isSimpleString(value)) {
    cssValue = `"${value.split(" ").join(" ")}"`;
  }

  return {
    "grid-template-areas": cssValue,
  };
}
