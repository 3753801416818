import { FlexBox } from "../../FlexBox";
import { Text } from "../../Text";
import PropTypes from "prop-types";
import React from "react";
import styled, { css } from "styled-components";

import { Box } from "../../Box";
import { declareVariant, getColor } from "../../../style";
import { Icon } from "../../Icon";

import { StyledFlex } from "../questionnaire.styles";
import { useQuestionnaireQuestion } from "./Questionnaire";
import BooleanQuestion from "./BooleanQuestion";
import ChoiceQuestion from "./ChoiceQuestion";
import TextQuestion from "./TextQuestion";

const QuestionGroupVariants = declareVariant({
  noBorder: css``,
  default: css`
    border-top: 1px ${getColor("border")} solid;
    padding-top: 32px;
  `,
});

const StyledQuestionGroup = styled(Box)`
  ${QuestionGroupVariants};
`;

// TEMP
function QuestionGroup({ label, helpText, questions, variant }) {
  // Maybe fieldset with id etc and legend??

  return (
    <StyledQuestionGroup mt={2} variant={variant}>
      {helpText ? (
        <>
          <Text fw="bold">{`${label}`}</Text>
          <Text color="gray.500" fs="xs" mb="xs" mt="4px">
            {helpText}
          </Text>
        </>
      ) : (
        <Text fs={2} fw="bold" mb={2}>{`${label}`}</Text>
      )}
      <StyledFlex gap="middle" vertical>
        {questions.map(({ key, ...question }) => (
          <Question id={key} key={key} {...question} />
        ))}
      </StyledFlex>
    </StyledQuestionGroup>
  );
}
QuestionGroup.propTypes = {
  label: PropTypes.string,
  helpText: PropTypes.string,
  questions: PropTypes.array,
  variant: PropTypes.string,
};

function CustomQuestion({ label, ...props }) {
  const question = useQuestionnaireQuestion(props);

  // Currently has no helpText, if we need more custom questions with a helpText, this will need to be added.
  if (question.id === "listUBO")
    return (
      <div>
        <Text my={2} typography="form-label font-bold">
          {label}
        </Text>
        {question.ProfilePeopleList && (
          <question.ProfilePeopleList
            isReadOnly={question.isReadOnly || !question.isEditable}
            people={question.answer}
          />
        )}
      </div>
    );
  else if (question.id === "boardMemberWarning") {
    return (
      !question.answer && (
        <FlexBox ml={"-18px"}>
          <Icon color="grey">info</Icon>
          <Text color="grey" fs="sm" ml="sm">
            It is recommended to add any board members to your list of UBOs
          </Text>
        </FlexBox>
      )
    );
  } else return null;
}
CustomQuestion.propTypes = {
  label: PropTypes.string,
};

function NotFoundQuestion({ id, component }) {
  // TODO: this should not show in PROD...we essentially want to catch this in DEV somehow??
  return (
    <Box border={{ style: "dashed", width: 2, color: "error" }} p={2}>
      <Text
        css={`
          white-space: pre-line;
        `}
        fs="sm:em"
        mb={2}
      >
        {`
        There is no support for "${component}" questions yet...

        Question identifier: "${id}."`}
      </Text>
    </Box>
  );
}

NotFoundQuestion.propTypes = {
  id: PropTypes.string,
  component: PropTypes.node,
};

const QUESTIONNAIRE_COMPONENTS = {
  text: TextQuestion,
  choice: ChoiceQuestion,
  boolean: BooleanQuestion,
  group: QuestionGroup,
};

function Question({ type, id, ProfilePeopleList, ...question }) {
  if (type === "custom") {
    return (
      <CustomQuestion
        id={id}
        ProfilePeopleList={ProfilePeopleList}
        {...question}
      />
    );
  }

  let Component = QUESTIONNAIRE_COMPONENTS[type] || (
    <NotFoundQuestion component={type} />
  );

  if (React.isValidElement(Component)) {
    return React.cloneElement(Component, {
      id,
      ...question,
    });
  } else {
    return <Component id={id} {...question} />;
  }
}
Question.propTypes = {
  type: PropTypes.string,
  id: PropTypes.string,
  question: PropTypes.object,
  ProfilePeopleList: PropTypes.any,
};

export { Question };
