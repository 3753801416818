import pick from "lodash/pick";
import { getIsMaybeRelativeSize } from "../utils";

/* Width Scale */
export const WIDTHS = {
  1: "1rem",
  2: "2rem",
  3: "4rem",
  4: "8rem",
  5: "16rem",

  // new scale
  full: "100%",
  halfscreen: "50vw",
  screen: "100vw",
};

function transformWidthValue(value) {
  let w = Math.abs(value);
  if (isNaN(w)) {
    w = String(value);
    return getIsMaybeRelativeSize(w);
  } else {
    if (Number.isInteger(w)) {
      return w === 100 ? "100%" : `${w}px`;
    } else {
      return `${w * 100}%`;
    }
  }
}

export function mapPropsToWidth(props) {
  const widthKeys = ["w", "width"];
  const maxWidthKeys = ["mw", "max-width"];
  const properties = pick(props, [...widthKeys, ...maxWidthKeys]);
  return Object.keys(properties).reduce((styles, key) => {
    if (maxWidthKeys.includes(key)) {
      return {
        ...styles,
        "max-width": getWidth(properties[key]),
      };
    }
    return {
      ...styles,
      width: getWidth(properties[key]),
    };
  }, {});
}

export const getWidth = (value) => WIDTHS[value] || transformWidthValue(value);
