import { get } from "lodash";
export const LETTER_SPACINGS = {
  tighter: "-0.05em",
  tight: "-0.025em",
  normal: "0",
  wide: "0.025em",
  wider: "0.05em",
  widest: "0.1em",
};

export function mapToLetterSpacing(value) {
  return {
    "letter-spacing": get(LETTER_SPACINGS, value, value),
  };
}
