import { pick } from "lodash";

export function mapPropsToOverflow(props) {
  const properties = pick(props, ["overflow", "overflow-y", "overflow-x"]);

  return Object.keys(properties).reduce((styles, prop) => {
    return {
      ...styles,
      [prop]: properties[prop],
    };
  }, {});
}
