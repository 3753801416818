import React from "react";
import { useListBox } from "./ListBox";
import { Overlay } from "../Overlay";
import { useComposedRef } from "../../core";

const ListBoxOverlay = React.forwardRef(function Component(
  props,
  forwardedRef,
) {
  const {
    refs: { overlay: overlayRef, button: buttonRef },
    isExpanded,
  } = useListBox();

  let ref = useComposedRef(overlayRef, forwardedRef);

  return (
    <Overlay
      ref={ref}
      targetRef={buttonRef}
      isVisible={isExpanded}
      {...props}
    />
  );
});

ListBoxOverlay.defaultProps = {
  border: { color: "gfw.700" },
  placement: "bottom-start",
  offset: [0, 2],
};

export { ListBoxOverlay };
